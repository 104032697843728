export type GapiConfig = Parameters<typeof gapi.client.init>[number];

export const loadGapiClient = (gapiConfig: GapiConfig) =>
  new Promise<typeof window.gapi>((resolve) => {
    const scriptSrcGapi = "https://apis.google.com/js/api.js" as const;
    const scriptGapi = document.createElement("script");
    scriptGapi.src = scriptSrcGapi;
    scriptGapi.async = true;
    scriptGapi.defer = true;
    document.body.appendChild(scriptGapi);
    scriptGapi.onload = (): void => {
      const gapi = window.gapi;
      if (!gapi) {
        console.error("Something went wrong");
        return;
      }
      gapi.load("client:auth2", async () => {
        console.log("client:auth2 loaded");
        console.log(gapiConfig);
        await gapi.client.init({
          ...gapiConfig,
        });
        resolve(gapi);
      });
    };
  });
