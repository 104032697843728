import { Minutes } from "@dulce/models/dist/tasks.models";
import { flow, FPArray, ID, O, pipe } from "@dulce/prelude";
import { MouseEventHandler, TouchEventHandler } from "react";
import { useDebounce } from "react-use";
import { useValue, UseValueResult } from "../../../utils/useValue";
import * as styles from "./TimeScrubberWriteTrack.css";

export type TimeScrubberWriteTrackProps = {
  startTime: Minutes;
  endTime: Minutes;
  current: UseValueResult<Minutes>;
};
export const TimeScrubberWriteTrack = (props: TimeScrubberWriteTrackProps) => {
  const isCapturing = useValue(false);
  const prevTouchLeft = useValue(0);

  const handleMouseDown = () => {
    isCapturing.setValue(true);
  };

  const handleMouseUp = () => {
    isCapturing.setValue(false);
  };

  const handleMouseMove: MouseEventHandler<HTMLDivElement> = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (isCapturing.value) {
      let x = e.movementX;
      props.current.setValue((v) => v + -1 * x);
    }
  };

  const handleTouchStart: TouchEventHandler<HTMLDivElement> = (ev) => {
    handleMouseDown();
    prevTouchLeft.setValue((v) =>
      pipe(
        ev.touches[0],
        O.of,
        O.map((touch) => touch.clientX),
        O.match(() => prevTouchLeft.value, ID.of)
      )
    );
  };

  const handleTouchMove: TouchEventHandler<HTMLDivElement> = (e) => {
    e.stopPropagation();
    if (isCapturing.value) {
      const left = pipe(
        e.touches[0],
        O.of,
        O.map((touch) => touch.clientX),
        O.match(() => prevTouchLeft.value, ID.of)
      );
      const leftDiff = left - prevTouchLeft.value;
      prevTouchLeft.setValue(left);
      props.current.setValue((v) => v + -1 * leftDiff);
    }
  };

  return (
    <>
      <div
        className={styles.root}
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
        onMouseLeave={handleMouseUp}
        onMouseMove={handleMouseMove}
        onTouchStart={handleTouchStart}
        onTouchEnd={handleMouseUp}
        onTouchMove={handleTouchMove}
      ></div>
      <input
        type="range"
        min={props.startTime}
        max={props.endTime}
        step={10}
        style={{
          position: "absolute",
          left: -99999,
        }}
        value={props.current.value}
        onChange={flow((e) => e.target.value, parseInt, props.current.setValue)}
        onKeyDown={(e) => {
          e.preventDefault();
          e.stopPropagation();
          const isKeyIncrement =
            e.key === "ArrowRight" || e.key === "ArrowDown";
          const isKeyDecrement = e.key === "ArrowLeft" || e.key === "ArrowUp";
          if (e.shiftKey && isKeyIncrement) {
            props.current.setValue((v) => v + 60);
            return;
          }
          if (e.shiftKey && isKeyDecrement) {
            props.current.setValue((v) => v - 60);
            return;
          }
          if (isKeyIncrement) {
            props.current.setValue((v) => v + 10);
            return;
          }
          if (isKeyDecrement) {
            props.current.setValue((v) => v - 10);
            return;
          }
        }}
      />
    </>
  );
};
