import * as styles from "./QuickAddForm.css";
import { FormikProvider, useFormik } from "formik";
import { useContext, useRef } from "react";
import { faMagicWandSparkles, faPen } from "@fortawesome/free-solid-svg-icons";
import { Input } from "../../../foundation/Input/Input";
import { AppContext } from "../../../AppContext";
import { Logo } from "../../Logo/Logo";
import { useDebounce } from "react-use";
import { ButtonWithIcon } from "../../../foundation/ButtonWithIcon/ButtonWithIcon";
import { pipe } from "@dulce/prelude";
import { sdk } from "../../../sdk";
import { Minutes } from "@dulce/models/dist/tasks.models";
import { HotkeyLabel } from "../../HotkeyLabel/HotkeyLabel";
import { Size, Text, Flex, theme } from "@dulce/design-system";

export type QuickAddFormProps = {
  onChange: (value: string) => void;
  onSubmit: (value: string) => void;
};
export const QuickAddForm = (props: QuickAddFormProps) => {
  const firstInputRef = useRef<HTMLInputElement>(null);
  const appContext = useContext(AppContext);
  const initialValues = {
    title: "",
  };
  const formik = useFormik({
    initialValues,
    onSubmit: async (values) => {
      const startTimeRegex = /@ \d{1,2}( pm| am|am|pm| PM| AM|PM|AM)/g;
      const startTimeMatches = values.title.match(startTimeRegex);

      const durationRegex = /for \d+( )?(min|Min|minutes|MIN|Minutes)/g;
      const durationMatches = values.title.match(durationRegex);

      const adjustedTitle = pipe(
        values.title,
        (v) => v.replace(startTimeRegex, ""),
        (v) => v.replace(durationRegex, ""),
        (v) => v.trim()
      );

      const firstStartTimeMatch = startTimeMatches?.at(0);

      if (firstStartTimeMatch) {
        const hourRegex = /\d{1,2}/g;
        const hour = firstStartTimeMatch.match(hourRegex)?.at(0) ?? "9";
        const parsedHour = parseInt(hour);
        const amMeridiemRegex = /(am|Am|AM|am)/g;
        const isAm = !!firstStartTimeMatch.match(amMeridiemRegex)?.at(0);
        let adjustedHour = parsedHour;
        if (adjustedHour === 12) {
          adjustedHour = 0;
        }
        if (!isAm) {
          adjustedHour = adjustedHour + 12;
        }
        const startTime = sdk.utils.time.hoursToMinutes(adjustedHour);
        appContext.presetStartTime.setValue(startTime);
      }

      const firstDurationMatch = durationMatches?.at(0);

      if (firstDurationMatch) {
        const durationRegex = /\d{1,3}/g;
        const duration = firstDurationMatch.match(durationRegex)?.at(0) ?? "10";
        const parsedDuration = parseInt(duration);
        const applyUpperBound = (bound: Minutes) => (v: Minutes) => {
          if (v > bound) return bound;
          return v;
        };
        const applyLowerBound = (bound: Minutes) => (v: Minutes) => {
          if (v < bound) return bound;
          return v;
        };

        const snapToStepSize = (stepSize: Minutes) => (v: Minutes) =>
          pipe(
            v,
            (v) => v / 10,
            Math.round,
            (v) => v * 10
          );
        const adjustedDuration = pipe(
          parsedDuration,
          snapToStepSize(10),
          applyLowerBound(10),
          applyUpperBound(110)
        );

        appContext.presetDuration.setValue(adjustedDuration);
      }

      props.onSubmit(adjustedTitle);
      formik.setFieldValue("title", "");
    },
  });
  const [] = useDebounce(
    () => {
      props.onChange(formik.values.title);
    },
    400,
    [formik.values.title]
  );
  return (
    <FormikProvider value={formik}>
      <form onSubmit={formik.handleSubmit} className={styles.root}>
        <Input
          name="title"
          placeholder="Find or create..."
          inputRef={firstInputRef}
          className={styles.fillWidth}
          inputClassName={styles.magicInputField}
        />
        <ButtonWithIcon
          icon={faMagicWandSparkles}
          color="primary"
          style={{
            whiteSpace: "nowrap",
          }}
          size={Size.lg}
          type="submit"
          className={styles.magicInputButton}
        />
      </form>
    </FormikProvider>
  );
};
