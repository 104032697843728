export type ResizeCaptureElementsOpts = {
  html: HTMLElement;
  canvas: HTMLCanvasElement;
  video: HTMLVideoElement;
};

export const resizeCaptureElements = (opts: ResizeCaptureElementsOpts) => {
  const { canvas, html, video } = opts;
  const { height, width } = html.getBoundingClientRect();
  const { devicePixelRatio } = window;
  const adjustedHeight = height * devicePixelRatio;
  const adjustedWidth = width * devicePixelRatio;
  const isHeightEquivalent =
    Math.floor(canvas.height) === Math.floor(adjustedHeight);
  const isWidthEquivalent =
    Math.floor(canvas.width) === Math.floor(adjustedWidth);
  if (!isHeightEquivalent || !isWidthEquivalent) {
    canvas.height = adjustedHeight;
    canvas.width = adjustedWidth;
    video.height = adjustedHeight;
    video.width = adjustedWidth;
  }
  return { height: adjustedHeight, width: adjustedWidth };
};
