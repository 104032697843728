import { Minutes } from "@dulce/models/dist/tasks.models";
import { getTimezoneOffset } from "date-fns-tz";

export const hoursToMinutes = (hours: number): Minutes => hours * 60;
export const minutesToHours = (minutes: Minutes) => minutes / 60;
export const getCurrentTimeInMinutes = (): Minutes => {
  const date = new Date();
  const minutes = hoursToMinutes(date.getHours()) + date.getMinutes();
  return minutes;
};
export const timePadNumber = (value: number) =>
  value.toString().padStart(2, "0");
export const getMeridianFromHour = (hour: number) => (hour > 11 ? "PM" : "AM");
export const getFormattedTimeFromMinutes = (value: Minutes) => {
  const hour = Math.floor(minutesToHours(value));
  const minutes = Math.floor(value % 60);
  const meridian = getMeridianFromHour(hour);
  const adjustedHour = hour > 12 ? hour - 12 : hour;
  return `${timePadNumber(adjustedHour)}:${timePadNumber(minutes)} ${meridian}`;
};

export const timeToMinutes = (date: Date): Minutes => {
  const minutes = hoursToMinutes(date.getHours()) + date.getMinutes();
  return minutes;
};

// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat
export const getCurrentTimezone = () =>
  new Intl.DateTimeFormat().resolvedOptions().timeZone;

export const getTimezoneOffsetInMinutes = (date: Date): Minutes => {
  const currentTimezone = getCurrentTimezone();
  // getTimezoneOffset returns milliseconds
  // milliseconds * 1000 === seconds
  // seconds * 60 === minutes
  return getTimezoneOffset(currentTimezone, date) * 1000 * 60;
};

