import {
  ThirdPartyCalendar,
  ThirdPartyCalendarEvent,
} from "@dulce/models/dist/third-party-calendars.models";

export type ThirdPartyCalendarWithoutIdOrOwnerId = Omit<
  ThirdPartyCalendar,
  "id" | "ownerId"
>;
export const fromGoogleCalendar = (
  item: gapi.client.calendar.CalendarList["items"][0]
) => {
  const draftThirdPartyCalendar: ThirdPartyCalendarWithoutIdOrOwnerId = {
    isEnabled: true,
    title: item.summary,
    calendarId: item.id,
    color: "gray",
    cachedEvents: [],
    raw: item,
    lastSynced: new Date().toISOString(),
  };
  return draftThirdPartyCalendar;
};

const updateCachedEvents =
  (calendar: ThirdPartyCalendarWithoutIdOrOwnerId) =>
  (
    events: Array<ThirdPartyCalendarEvent>
  ): ThirdPartyCalendarWithoutIdOrOwnerId => {
    return {
      ...calendar,
      cachedEvents: events,
    };
  };

export const ThirdPartyCalendarUtils = {
  // conversions
  fromGoogleCalendar,
  // utils
  updateCachedEvents,
};

// TODO for model utils (see https://gcanti.github.io/fp-ts/modules/Either.ts.html as an example)
// - constructors
// - conversions
// - refinements (predicates)
// - error handling???
// - utils
// - selectors
