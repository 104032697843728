import { FPArray, pipe } from "@dulce/prelude";
import { createContext, ReactChild, ReactChildren, useMemo } from "react";
import { useInterval } from "react-use";
import {useUser} from "../hooks/useUser";
import { useValue } from "../utils/useValue";


export type IntervalTimerContextValue = {
  playNotification: Function 
}
export const IntervalTimerContext = createContext<IntervalTimerContextValue>({
  playNotification: Function
})
type IntervalTimerContextProviderProps = {
  children: ReactChild | ReactChildren
}

export const IntervalTimerContextProvider = (props: IntervalTimerContextProviderProps) => {

  const { selfQuery: userQuery } = useUser()

  const isIntervalTimerEnabled = userQuery.data?.preferences?.intervalChimes?.isEnabled ?? false

  const pianoAudioQueue = useMemo(() => pipe(
    [...new Array(5)],
    FPArray.mapWithIndex(i => pipe(
      i + 1,
      i => i.toString().padStart(3, "0"),
      v => `/notification/piano_${v}.mp3`
    )),
    FPArray.map(v => new Audio(v))
  ), [])
  const pianoIndex = useValue<number>(0)

  const playNotification = () => {
    if (isIntervalTimerEnabled) {
      const audioIndex = pianoIndex.value % pianoAudioQueue.length
      const audio = pianoAudioQueue[audioIndex]
      audio.volume = 0.1
      audio.play()
      pianoIndex.setValue(v => v + 1)
    }
  }

  useInterval(() => {
    let now = new Date()
    if ((now.getMinutes() % 10 === 0) && now.getSeconds() === 0) {
      playNotification()
    }
  }, 1000)

  return (
    <IntervalTimerContext.Provider value={{ playNotification  }}>
      {props.children}
    </IntervalTimerContext.Provider>
  )
}
