import { Size, Text, theme } from "@dulce/design-system";
import { useField } from "formik";
import { RefObject } from "react";
import { useValue } from "../../utils/useValue";
import * as styles from "./Toggle.css";

type CustomHTMLInputProps = Omit<
  React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >,
  "type"
>;

export type ToggleProps = CustomHTMLInputProps & {
  label: string;
  inputRef?: RefObject<HTMLInputElement>;
};

export const Toggle = (props: ToggleProps) => {
  const { label, ...rest } = props;
  const [field, meta] = useField({
    ...(rest as any),
    type: "checkbox",
  });
  const isFocused = useValue<boolean>(false);
  return (
    <label
      className={styles.root({
        focused: isFocused.value,
      })}
      onFocus={() => isFocused.setValue(true)}
      onBlur={() => isFocused.setValue(false)}
    >
      <div
        className={styles.toggleTrack({
          isChecked: field.value,
          disabled: rest.disabled,
        })}
      >
        <div
          className={styles.toggleKnob({
            isChecked: field.value,
            disabled: rest.disabled,
          })}
          style={{
            transform: field.value ? "translateX(100%)" : "translateX(0%)",
          }}
        />
      </div>
      <Text size={Size.sm}>
        <span
          style={{ color: rest.disabled ? theme.colors.gray[5] : "inherit" }}
        >
          {label}
        </span>
      </Text>
      <input
        className={styles.hiddenInput}
        {...field}
        {...rest}
        type="checkbox"
      />
    </label>
  );
};
