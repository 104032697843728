import { Task, TaskId } from "@dulce/models/dist/tasks.models";
import { Heading } from "../../../foundation/Heading/Heading";
import * as styles from "./TaskDetailsView.css";
import { MouseEventHandler, useCallback } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faRedo } from "@fortawesome/free-solid-svg-icons";
import { faEdit, faTrashCan } from "@fortawesome/free-regular-svg-icons";
import { ModalContent } from "../../../foundation/Modal/Modal";
import { useTask, useTasksMutations } from "../../../hooks/useTasks";
import { DulceEditorPreview } from "../../../foundation/DulceEditor/DulceEditorPreview";
import {
  TaskProgressBar,
  TimeRemainingBar,
} from "./TaskProgressBar/TaskProgressBar";
import { FPArray, pipe } from "@dulce/prelude";
import { CustomElements } from "@dulce/models/dist/slate.primatives";
import { useHotkeys } from "react-hotkeys-hook";
import { HotkeyLabel } from "../../HotkeyLabel/HotkeyLabel";
import { ButtonWithIcon } from "../../../foundation/ButtonWithIcon/ButtonWithIcon";
import { faSpotify, faYoutube } from "@fortawesome/free-brands-svg-icons";
import { Size, Text, Flex, Button } from "@dulce/design-system";
import { sdk } from "../../../sdk";
import { inRange } from "lodash/fp";

export type TaskDetailsViewProps = {
  id: TaskId;
  onEditRequest?: MouseEventHandler<HTMLButtonElement>;
  onDeleteRequest?: MouseEventHandler<HTMLButtonElement>;
  onCompleteTask?: Function;
};
export const TaskDetailsView = (props: TaskDetailsViewProps) => {
  const taskQuery = useTask(props.id, {
    eagerFetch: true,
  });
  const { uncompleteTaskMutation, completeTaskMutation } = useTasksMutations();

  useHotkeys("e", (e) => {
    e.preventDefault();
    e.stopPropagation();
    props.onEditRequest?.(e as any);
  });

  const task = taskQuery?.data;
  const _getCompleteButton = useCallback(() => {
    if (task?.status === "COMPLETED") {
      return (
        <Button
          color="primary"
          className={styles.iconButton}
          onClick={() => uncompleteTaskMutation.mutateAsync({ id: task.id })}
        >
          <FontAwesomeIcon icon={faRedo} />
          <Text>Uncomplete</Text>
        </Button>
      );
    }
    return (
      <Button
        color="primary"
        className={styles.iconButton}
        onClick={() => {
          completeTaskMutation.mutateAsync({ id: task?.id as TaskId });
          props.onCompleteTask?.();
        }}
      >
        <FontAwesomeIcon icon={faCheck} />
        <Text>Complete</Text>
      </Button>
    );
  }, [task?.status, task?.id]);

  if (taskQuery.isLoading) {
    return (
      <div>
        <Heading>Loading...</Heading>
      </div>
    );
  }

  if (taskQuery.isError) {
    return (
      <div>
        <Heading>Error</Heading>
        <Text>{JSON.stringify(taskQuery.error)}</Text>
      </div>
    );
  }

  const details: Array<CustomElements> =
    (task?.details as Array<CustomElements>) ?? [];

  const minitasks: Array<CustomElements> = pipe(
    details,
    FPArray.filter((v: CustomElements) => v.type === "subtask")
  );

  const completed: Array<CustomElements> = pipe(
    minitasks,
    FPArray.filter((v) => {
      if ("checked" in v) {
        return v.checked;
      }
      return false;
    })
  );

  const openSpotify = () => {
    const windowFeatures = `rel="noreferrer"`;
    window.open(
      "https://open.spotify.com/search/study%20music",
      "_blank",
      windowFeatures
    );
  };

  const openLofiYoutube = () => {
    const windowFeatures = `rel="noreferrer"`;
    window.open(
      "https://www.youtube.com/results?search_query=lofi+beats+to+study+to",
      "_blank",
      windowFeatures
    );
  };

  const isTaskInProgress = (task?: Task) => {
    const currentTime = sdk.utils.time.getCurrentTimeInMinutes();
    const startTime = task?.startTime;
    const duration = task?.duration;
    console.log({ startTime, duration, currentTime });
    if (typeof startTime === "number" && typeof duration == "number") {
      return pipe(currentTime, inRange(startTime, startTime + duration));
    }
    return false;
  };

  return (
    <div className={styles.root}>
      <ModalContent
        content={
          <div className={styles.content}>
            {!!minitasks.length ? (
              <TaskProgressBar
                completed={completed.length}
                total={minitasks.length}
                color={(task?.color as any) ?? "gray"}
              />
            ) : null}
            {isTaskInProgress(task) ? (
              <TimeRemainingBar
                startTime={task?.startTime ?? 0}
                duration={task?.duration ?? 0}
                color={(task?.color as any) ?? "gray"}
              />
            ) : null}
            {task?.isDeepWork ? (
              <Flex>
                <ButtonWithIcon icon={faYoutube as any} onClick={openSpotify}>
                  Open Spotify
                </ButtonWithIcon>
                <ButtonWithIcon
                  icon={faSpotify as any}
                  onClick={openLofiYoutube}
                >
                  Open Lofi Beats
                </ButtonWithIcon>
              </Flex>
            ) : null}
            <Text>Duration: {task?.duration}min</Text>
            <Heading>
              {task?.title} {task?.emojiCode}
            </Heading>
            {task?.details && <DulceEditorPreview task={task} />}
          </div>
        }
        footer={
          <>
            <div className={styles.actionRow}>
              <ButtonWithIcon
                className={styles.iconButton}
                color="secondary"
                onClick={props.onEditRequest}
                icon={faEdit}
              >
                <Flex gap={Size.sm} alignItems="center">
                  <Text>Edit</Text>
                  <HotkeyLabel>e</HotkeyLabel>
                </Flex>
              </ButtonWithIcon>
              <ButtonWithIcon
                className={styles.iconButton}
                color="danger"
                onClick={props.onDeleteRequest}
                icon={faTrashCan}
              >
                <Flex gap={Size.sm} alignItems="center">
                  <Text>Delete</Text>
                </Flex>
              </ButtonWithIcon>
              {_getCompleteButton()}
            </div>
          </>
        }
      />
    </div>
  );
};
