import { useEffect } from "react";
import { useValue } from "./utils/useValue";

const isIOS = () => {
  const browserInfo = navigator.userAgent.toLowerCase();

  if (browserInfo.match("iphone") || browserInfo.match("ipad")) {
    return true;
  }
  if (
    [
      "iPad Simulator",
      "iPhone Simulator",
      "iPod Simulator",
      "iPad",
      "iPhone",
      "iPod",
    ].includes(navigator.platform)
  ) {
    return true;
  }
  return false;
};
export const useBrowserNotifications = () => {
  const permissionResult = useValue<NotificationPermission>("default");
  const storedNotifications = useValue<Array<Notification>>([]);

  useEffect(() => {
    // ios is missing Notification for some reason
    if (isIOS()) return;
    Notification.requestPermission().then(permissionResult.setValue);
  }, []);

  type SendOpts = {
    title: string;
    details: string;
  };
  const send = ({ title, details }: SendOpts) => {
    // ios is missing Notification for some reason
    if (!isIOS) return;
    if (permissionResult.value === "granted") {
      const notification = new Notification(title, {
        body: details,
        icon: "/logo192.png",
      });
      storedNotifications.setValue((values) => [...values, notification]);
    }
  };

  const closeAll = () => {
    if (permissionResult.value === "granted") {
      storedNotifications.value.forEach((notification) => {
        notification.close();
      });
      storedNotifications.setValue([]);
    }
  };

  return {
    send,
    closeAll,
  };
};
