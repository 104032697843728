import * as styles from "./ThirdPartyCalendarsLayer.css";
import { FPArray, N, Ord, pipe } from "@dulce/prelude";
import { useQuery } from "@tanstack/react-query";
import { sdk } from "../../../../sdk";
import { Task as TaskType } from "@dulce/models/dist/tasks.models";
import { Task } from "../../../Task/Task";
import { useContext } from "react";
import { TimeboxQueryContext } from "../../../../context/TimeboxContext";
import { calc } from "@vanilla-extract/css-utils";
import { theme } from "../../../../theme.css";
import { AnimatePresence, motion } from "framer-motion";
import { useTasks } from "../../../../hooks/useTasks";
import { useThirdPartyCalendars } from "../../../../hooks/useThirdPartyCalendars";
import {
  ThirdPartyCalendarEvent,
  ThirdPartyCalendar,
} from "@dulce/models/dist/third-party-calendars.models";
import { Flex, LinealBoxLite, Size, Text } from "@dulce/design-system";
import { consoleTap } from "../../../../utils/consoleTap";
import { faCalendar, faCalendarAlt } from "@fortawesome/free-regular-svg-icons";
import { faArrowUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGoogle } from "@fortawesome/free-brands-svg-icons";
import { openThirdPartyCalendarEventDetails } from "../../../ThirdPartyCalendars/ThirdPartyCalendars";

export const getSortedEventsFromCalendars = (
  calendars: Array<ThirdPartyCalendar>
) =>
  pipe(
    calendars,
    FPArray.filter((calendar) => calendar.isEnabled),
    FPArray.map((calendar) => calendar.cachedEvents),
    FPArray.flatten,
    FPArray.sort(
      pipe(
        N.Ord,
        Ord.contramap((event: ThirdPartyCalendarEvent) => event.startTime)
      )
    )
  );

export type ThirdPartyCalendarsLayerProps = {};
export const ThirdPartyCalendarsLayer = (
  props: ThirdPartyCalendarsLayerProps
) => {
  const timeboxQueryContext = useContext(TimeboxQueryContext);
  const trackInfo = timeboxQueryContext?.getTimeboxTrackInfo();
  const topOffset = theme.space[2];
  const { thirdPartyCalendars } = useThirdPartyCalendars();
  const events = pipe(
    thirdPartyCalendars.data ?? [],
    getSortedEventsFromCalendars,
    FPArray.map((event) => {
      return (
        <motion.div
          key={event.id}
          initial={{
            top: 0,
            y: pipe(
              trackInfo?.startTime ?? 0,
              (startTime) => (event.startTime ?? 0) - startTime,
              (delta) => delta / (trackInfo?.stepSize ?? 10),
              (steps) =>
                calc(theme.space[3])
                  .multiply(3)
                  .multiply(steps)
                  .add(topOffset)
                  .toString()
            ),
          }}
          animate={{
            top: 0,
            y: pipe(
              trackInfo?.startTime ?? 0,
              (startTime) => (event.startTime ?? 0) - startTime,
              (delta) => delta / (trackInfo?.stepSize ?? 10),
              (steps) =>
                calc(theme.space[3])
                  .multiply(3)
                  .multiply(steps)
                  .add(topOffset)
                  .toString()
            ),
          }}
          style={{
            position: "absolute",
            display: "flex",
            justifyContent: "flex-end",
            width: "100%",
            maxWidth: "80%",
            right: 0,
            height: pipe(
              event.duration,
              (v) => v / (trackInfo?.stepSize ?? 10),
              (steps) =>
                calc(theme.space[3])
                  .multiply(3)
                  .multiply(steps)
                  .add("1px")
                  .toString()
            ),
          }}
        >
          <LinealBoxLite
            radius={4}
            styleApi={{
              color: "gray",
              striped: true,
            }}
            innerProps={{
              style: {
                height: "100%",
                paddingLeft: theme.space[2],
                paddingRight: theme.space[2],
                paddingTop: theme.space[2],
                minWidth: theme.space[6],
              },
            }}
            outerProps={{
              style: {
                cursor: "pointer",
              },
              onDoubleClick: () => {
                openThirdPartyCalendarEventDetails(event.id);
              },
            }}
          >
            <Flex gap={Size.md}>
              <Text>{event.title}</Text>
              <Text
                size={Size.sm}
                style={{
                  paddingTop: theme.space[1],
                }}
              >
                <FontAwesomeIcon icon={faCalendar} />
              </Text>
              <Text
                size={Size.sm}
                style={{
                  paddingTop: theme.space[1],
                }}
              >
                <FontAwesomeIcon icon={faGoogle} />
              </Text>
              <Text
                size={Size.sm}
                style={{
                  paddingTop: theme.space[1],
                }}
              >
                {event.duration} min
              </Text>
              <a
                href={pipe(
                  event.raw,
                  (raw) => raw as any,
                  (raw) => raw.htmlLink
                )}
                target="_blank"
              >
                <Text
                  size={Size.sm}
                  style={{
                    paddingTop: theme.space[1],
                  }}
                >
                  <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
                </Text>
              </a>
            </Flex>
          </LinealBoxLite>
        </motion.div>
      );
    })
  );

  return <div className={styles.root}>{events}</div>;
};
