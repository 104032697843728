import { RefObject, useEffect } from "react";
import { useField } from "formik";
import * as styles from "./Input.css";
import classnames from "classnames";

export type InputProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> & {
  label?: string;
  inputRef?: RefObject<HTMLInputElement>;
  inputClassName?: string;
};
export const Input = (props: InputProps) => {
  const { className, inputClassName, label, inputRef, ...rest } = props;
  const [field, meta] = useField(rest as any);
  const baseClassnames = classnames(styles.root, className);
  const inputClassnames = classnames(styles.input, inputClassName);
  return (
    <label className={baseClassnames}>
      {label && <span className={styles.label}>{label}</span>}
      <input
        className={inputClassnames}
        {...field}
        {...rest}
        ref={props.inputRef}
      />
      {meta.touched && meta.error ? (
        <span className={styles.error}>{meta.error}</span>
      ) : null}
    </label>
  );
};

export type TextareaProps = any & {
  label: string;
};
export const Textarea = (props: TextareaProps) => {
  const { className, label, ...rest } = props;
  const [field, meta] = useField(rest as any);
  return (
    <label className={styles.root}>
      <span className={styles.label}>{label}</span>
      <textarea className={styles.textarea} {...field} {...rest} />
      {meta.touched && meta.error ? (
        <span className={styles.error}>{meta.error}</span>
      ) : null}
    </label>
  );
};

export type DurationDropdownProps = {
  name: string;
  defaultValue?: number;
};
export const DurationDropdown = (props: DurationDropdownProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [field, meta, helpers] = useField(props.name);
  const { value } = meta;
  const { setValue } = helpers;

  useEffect(() => {
    setValue(props.defaultValue ?? 10);
  }, [props.defaultValue, setValue]);

  return (
    <label>
      <span>Estimated time ({value})</span>
      <select onChange={(e) => setValue(e.target.value)}>
        {new Array(12).fill("").map((v, i) => {
          const minutes = (i + 1) * 10;
          const isFirstOption = i === 0;
          if (isFirstOption) {
            return (
              <option key={minutes} selected>
                {minutes}
              </option>
            );
          }
          return <option key={minutes}>{minutes}</option>;
        })}
      </select>
    </label>
  );
};
