import { Flex, Size, Text } from "@dulce/design-system";
import { FPArray, ID, N, Ord, pipe } from "@dulce/prelude";
import { ReactNode } from "react";
import { ContextMenuOption } from "../../../foundation/ContextMenu/useContextMenu";
import { useTasks, useTasksMutations } from "../../../hooks/useTasks";
import { useStore } from "zustand";
import { sdk } from "../../../sdk";
import {
  faCheck,
  faLeftLong,
  faLock,
  faRightLeft,
  faUndo,
  faUnlock,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { selectTask } from "../../../sdk/selection/Selection.commands";
import { Minutes, Task } from "@dulce/models/dist/tasks.models";
import { faTrashAlt } from "@fortawesome/free-regular-svg-icons";

export const useMultiTaskContextMenuOptions = () => {
  const {
    completeTaskMutation,
    uncompleteTaskMutation,
    unscheduleTaskMutation,
    deleteTaskMutation,
    lockTaskMutation,
    unlockTaskMutation,
    scheduleTaskMutation,
    enableRecurringTaskMutation,
    disableRecurringTaskMutation,
    duplicateTaskMutation,
  } = useTasksMutations();
  const tasksQuery = useTasks();
  const selectionStore = useStore(sdk.selection.store);

  const completeSelectedTasksOption: ContextMenuOption = {
    content: (
      <Flex gap={Size.md} alignItems="center">
        <FontAwesomeIcon icon={faCheck} />
        <Text>Complete</Text>
      </Flex>
    ) as ReactNode,
    name: "complete-selected-tasks",
    onClick: () => {
      const taskIds = sdk.selection.queries.listTasks();
      taskIds.forEach((id) => completeTaskMutation.mutate({ id }));
      sdk.selection.commands.unselectAllTasks();
    },
  };

  const uncompleteSelectedTasksOption: ContextMenuOption = {
    name: "uncomplete-selected-tasks",
    onClick: () => {
      const taskIds = sdk.selection.queries.listTasks();
      taskIds.forEach((id) => uncompleteTaskMutation.mutate({ id }));
      sdk.selection.commands.unselectAllTasks();
    },
    content: (
      <Flex gap={Size.md} alignItems="center">
        <FontAwesomeIcon icon={faUndo} />
        <Text>Uncomplete</Text>
      </Flex>
    ),
  };

  const unscheduleSelectedTasksOption: ContextMenuOption = {
    name: "unschedule-selected-tasks",
    onClick: () => {
      const taskIds = sdk.selection.queries.listTasks();
      taskIds.forEach((id) => unscheduleTaskMutation.mutate({ id }));
      sdk.selection.commands.unselectAllTasks();
    },
    content: (
      <Flex gap={Size.md} alignItems="center">
        <FontAwesomeIcon icon={faLeftLong} />
        <Text>Unschedule</Text>
      </Flex>
    ),
  };

  const lockSelectedTasksOption: ContextMenuOption = {
    name: "lock-selected-tasks",
    onClick: () => {
      const taskIds = sdk.selection.queries.listTasks();
      taskIds.forEach((id) => lockTaskMutation.mutate({ id }));
      sdk.selection.commands.unselectAllTasks();
    },
    content: (
      <Flex gap={Size.md} alignItems="center">
        <FontAwesomeIcon icon={faLock} />
        <Text>Lock</Text>
      </Flex>
    ),
  };

  const unlockSelectedTasksOption: ContextMenuOption = {
    name: "unlock-selected-tasks",
    onClick: () => {
      const taskIds = sdk.selection.queries.listTasks();
      taskIds.forEach((id) => unlockTaskMutation.mutate({ id }));
      sdk.selection.commands.unselectAllTasks();
    },
    content: (
      <Flex gap={Size.md} alignItems="center">
        <FontAwesomeIcon icon={faUnlock} />
        <Text>Unlock</Text>
      </Flex>
    ),
  };

  const deleteSelectedTasksOption: ContextMenuOption = {
    name: "delete-selected-tasks",
    onClick: () => {
      const taskIds = sdk.selection.queries.listTasks();
      taskIds.forEach((id) => deleteTaskMutation.mutate({ id }));
      sdk.selection.commands.unselectAllTasks();
    },
    content: (
      <Flex gap={Size.md} alignItems="center">
        <FontAwesomeIcon icon={faTrashAlt} />
        <Text>Delete</Text>
      </Flex>
    ),
  };

  type SwappingStrategy = (taskA: Task, taskB: Task) => void;
  type SwappingStrategyName = "single" | "both";
  const isScheduled = (task: Task): task is Task & { startTime: Minutes } =>
    !!task.startTime;

  const swappingStrategies: Record<SwappingStrategyName, SwappingStrategy> = {
    single: (taskA, taskB) => {
      if (isScheduled(taskA)) {
        unscheduleTaskMutation.mutate({
          id: taskA.id,
        });
        const { id, duration } = taskB;
        scheduleTaskMutation.mutate({
          id,
          duration,
          startTime: taskA.startTime,
        });
        return;
      }
      if (isScheduled(taskB)) {
        unscheduleTaskMutation.mutate({
          id: taskB.id,
        });
        const { id, duration } = taskA;
        scheduleTaskMutation.mutate({
          id,
          duration,
          startTime: taskB.startTime,
        });
        return;
      }
    },
    both: (taskA, taskB) => {
      if (!isScheduled(taskA)) return;
      if (!isScheduled(taskB)) return;
      const byStartTime = pipe(
        N.Ord,
        Ord.contramap((task: Task & { startTime: Minutes }) => task.startTime)
      );
      const [firstTask, secondTask] = pipe(
        [taskA, taskB],
        FPArray.sortBy([byStartTime])
      );
      const firstDiff = secondTask.duration - firstTask.duration;
      scheduleTaskMutation.mutate({
        id: firstTask.id,
        startTime: secondTask.startTime + firstDiff,
        duration: firstTask.duration,
      });
      scheduleTaskMutation.mutate({
        id: secondTask.id,
        startTime: firstTask.startTime,
        duration: secondTask.duration,
      });
    },
  };

  const selectTaskById = sdk.tasks.queries.selectTaskById(
    tasksQuery.data ?? []
  );
  const selectedTasks = pipe(
    selectionStore.selectedTasks,
    FPArray.map(selectTaskById),
    FPArray.filter((task) => !!task)
  ) as Array<Task>;

  const isTwoTasksSelected = selectedTasks.length === 2;
  const isAtLeastOneTaskScheduled = pipe(
    selectedTasks,
    FPArray.some(isScheduled)
  );
  const swapSelectedTasksOption: ContextMenuOption = {
    name: "swap-selected-tasks",
    isDisabled: !isTwoTasksSelected || !isAtLeastOneTaskScheduled,
    onClick: () => {
      const partitionedTasks = pipe(
        selectedTasks,
        FPArray.partition((task) => !!task?.startTime)
      );
      let strategyToUse: SwappingStrategyName | undefined = undefined;
      if (partitionedTasks.right.length === 2) {
        strategyToUse = "both";
      }
      if (partitionedTasks.right.length === 1) {
        strategyToUse = "single";
      }
      if (!strategyToUse) return;
      const [taskA, taskB] = selectedTasks;
      const swap = swappingStrategies[strategyToUse];
      swap(taskA, taskB);
    },
    content: (
      <Flex gap={Size.md} alignItems="center">
        <FontAwesomeIcon icon={faRightLeft} />
        <Text>Swap</Text>
      </Flex>
    ),
  };

  const options: Array<ContextMenuOption> = pipe(
    [] as Array<ContextMenuOption>,
    FPArray.append(swapSelectedTasksOption),
    FPArray.append(completeSelectedTasksOption),
    FPArray.append(uncompleteSelectedTasksOption),
    FPArray.append(unscheduleSelectedTasksOption),
    FPArray.append(lockSelectedTasksOption),
    FPArray.append(unlockSelectedTasksOption),
    FPArray.append(deleteSelectedTasksOption)
  );

  return options;
};
