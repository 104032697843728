import * as styles from "./AppBarMenu.css";
import { useUser } from "../../../hooks/useUser";
import React, { useContext, useState } from "react";
import ReactModal from "react-modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faMicrophone,
  faTimes,
  faUpRightFromSquare,
} from "@fortawesome/free-solid-svg-icons";
import {
  LinealBox,
  LinealBoxProps,
} from "../../../foundation/LinealBox/LinealBox";
import { LinealLine } from "../../../foundation/LinealBox/LinealLine/LinealLine";
import { motion } from "framer-motion";
import { ButtonWithIcon } from "../../../foundation/ButtonWithIcon/ButtonWithIcon";
import { FocusBoxContext } from "../../FocusBox/FocusBoxContext";
import { Size, Text, theme, Button } from "@dulce/design-system";
import { openCreateTaskModal } from "../../../foundation/Modal/modals/openCreateTaskModal";
import { openUserPreferencesFormModal } from "../../UserPreferencesForm/UserPreferencesForm";
import { Preferences } from "@dulce/models/dist/users.models";
import { Boolean, pipe } from "@dulce/prelude";
import { openVoiceInputModal } from "./openVoiceInputModal";
import { useSpeechRecognition } from "react-speech-recognition";
import { faClipboard } from "@fortawesome/free-regular-svg-icons";
import { openEditTaskDetailsTemplateFormModal } from "../../EditTaskDetailsTemplateForm/EditTaskDetailsTemplateForm";

const MotionLinealBox = motion(
  React.forwardRef((props: any, ref: any) => {
    return <LinealBox {...props} outerRef={ref} />;
  })
);

type FancyEmailProps = { email: string };
const FancyEmail = ({ email }: FancyEmailProps) => {
  const splitEmail = email.split("@");
  const isSplit = splitEmail.length > 1;
  if (!isSplit) return <div className={styles.fancyEmail}>{email}</div>;
  return (
    <div className={styles.fancyEmail}>
      <Text>{splitEmail[0]}</Text>
      <Text size={Size.sm} style={{ marginTop: "-0.5rem" }}>
        @{splitEmail[1]}
      </Text>
    </div>
  );
};

export type AppBarMenuProps = {};
export const AppBarMenu = ({}: AppBarMenuProps) => {
  const { selectEmail, handleSignOut } = useUser();
  const userQuery = useUser();
  const email = selectEmail();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const toggleMenu = () => setIsMenuOpen((value) => !value);

  const linealBoxProps: Partial<LinealBoxProps> = {
    radius: 4,
    leftLineProps: {
      variant: "lineal-dash",
      position: 25,
      gap: theme.space[1],
    },
    bottomLineProps: {
      variant: "lineal",
      position: 75,
      gap: theme.space[3],
    },
  };

  const focusBoxContext = useContext(FocusBoxContext);

  const userPreferences: Preferences = userQuery.selfQuery.data
    ?.preferences ?? {
    focusBox: {
      isEnabled: false,
    },
    intervalChimes: {
      isEnabled: false,
    },
    speechToTask: {
      isEnabled: false,
    },
    spokenReminders: {
      isEnabled: false,
    },
  };

  const { isMicrophoneAvailable, browserSupportsSpeechRecognition } =
    useSpeechRecognition();

  return (
    <div className={styles.root}>
      <Button
        onClick={toggleMenu}
        className={styles.appMenuButton}
        size={Size.sm}
        rounded
      >
        <FontAwesomeIcon icon={faBars} />
      </Button>
      <ReactModal
        contentLabel="App Menu"
        className={styles.appMenu}
        overlayClassName={styles.appMenuOverlay}
        isOpen={isMenuOpen}
        onRequestClose={toggleMenu}
      >
        <MotionLinealBox
          {...linealBoxProps}
          className={styles.appMenuContent}
          initial={{
            x: "10%",
            opacity: 0,
          }}
          animate={{
            x: "0%",
            opacity: 1,
          }}
        >
          <>
            <header className={styles.appMenu__header}>
              <FancyEmail email={email} />
              <Button onClick={toggleMenu} size={Size.lg} rounded>
                <FontAwesomeIcon icon={faTimes} />
              </Button>
              <LinealLine
                className={styles.appMenuHeaderLine}
                variant="lineal-dot"
                gap={theme.space[1]}
                orientation="horizontal"
                position={75}
                alignment="bottom"
              />
            </header>
            <nav className={styles.appMenu__nav}>
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: userPreferences?.speechToTask?.isEnabled
                    ? "3fr 1fr"
                    : "1fr",
                  gap: theme.space[2],
                }}
              >
                <Button
                  color="primary"
                  onClick={() => {
                    openCreateTaskModal();
                    toggleMenu();
                  }}
                  size={Size.lg}
                >
                  Create a Task
                </Button>
                {pipe(
                  !!userPreferences?.speechToTask?.isEnabled &&
                    isMicrophoneAvailable &&
                    browserSupportsSpeechRecognition,
                  Boolean.match(
                    () => null,
                    () => (
                      <ButtonWithIcon
                        color="info"
                        onClick={() => {
                          openVoiceInputModal();
                          toggleMenu();
                        }}
                        size={Size.lg}
                        icon={faMicrophone}
                      ></ButtonWithIcon>
                    )
                  )
                )}
              </div>
              {pipe(
                !!userPreferences?.focusBox?.isEnabled,
                Boolean.match(
                  () => null,
                  () => (
                    <>
                      <ButtonWithIcon
                        icon={faUpRightFromSquare}
                        onClick={() => {
                          if (focusBoxContext.isEnabled.value) {
                            focusBoxContext.stopFocusBox();
                          } else {
                            focusBoxContext.startFocusBox();
                          }
                        }}
                        color="secondary"
                      >
                        {focusBoxContext.isEnabled.value
                          ? "Stop Focus Box"
                          : "Start Focus Box"}
                      </ButtonWithIcon>
                    </>
                  )
                )
              )}
              {pipe(
                !!userPreferences?.taskDetailsTemplate?.isEnabled,
                Boolean.match(
                  () => null,
                  () => (
                    <>
                      <ButtonWithIcon
                        icon={faClipboard}
                        onClick={openEditTaskDetailsTemplateFormModal}
                        color="secondary"
                      >
                        Template for Task Details
                      </ButtonWithIcon>
                    </>
                  )
                )
              )}
              <Button color="secondary" onClick={openUserPreferencesFormModal}>
                Preferences
              </Button>
            </nav>
            <footer className={styles.appMenu__footer}>
              <LinealLine
                className={styles.appMenuFooterLine}
                variant="lineal-dot"
                gap={theme.space[1]}
                orientation="horizontal"
                position={25}
                alignment="bottom"
              />
              <Button onClick={handleSignOut}>Sign Out</Button>
            </footer>
          </>
        </MotionLinealBox>
      </ReactModal>
    </div>
  );
};
