import { useField } from "formik";
import { lazy, RefObject, Suspense, useMemo, useRef } from "react";
import { Descendant } from "slate";
import { LazyLoadingIndicator } from "../../../foundation/LazyLoadingIndicator/LazyLoadingIndicator";
import { LinealBoxProps } from "../../../foundation/LinealBox/LinealBox";
import * as styles from "./TitleDetailsInput.css";
import { Size, Text } from "@dulce/design-system";
import { ID, O, pipe } from "@dulce/prelude";
import { useEmojiMenu } from "../../../foundation/EmojiMenu/useEmojiMenu";

const LazyDulceEditor = lazy(async () => {
  const { DulceEditor } = await import(
    "../../../foundation/DulceEditor/DulceEditor"
  );
  return { default: DulceEditor };
});

export type TitleDetailsInputProps = {
  detailsName: string;
  detailsInitialValue?: Descendant[];
  titleName: string;
  titleRef?: RefObject<HTMLInputElement>;
  titleRequired?: boolean;
  emojiName: string;
  styleApi: LinealBoxProps["styleApi"];
};
export const TitleDetailsInput = (props: TitleDetailsInputProps) => {
  const [titleField, titleMeta, titleHelpers] = useField(props.titleName);
  const [detailsField, detailsMeta, detailsHelpers] = useField(
    props.detailsName
  );
  const [emojiField, emojiMeta, emojiHelpers] = useField(props.emojiName);
  const emojiPreviewRef = useRef<HTMLButtonElement>(null);

  const { handleClick, close } = useEmojiMenu();

  return (
    <>
      <div className={styles.root}>
        <div className={styles.header}>
          <input
            {...titleField}
            ref={props?.titleRef}
            className={styles.input}
            required={props.titleRequired}
            placeholder="Title"
          />
          <button
            className={styles.emoji}
            ref={emojiPreviewRef}
            onClick={(e) => {
              e.preventDefault();
              handleClick({
                buttonRef: emojiPreviewRef,
                onEmojiSelect: (value: any) => {
                  emojiHelpers.setValue(value?.native);
                  close();
                },
              })();
            }}
          >
            <Text size={Size.xl}>{emojiField.value}</Text>
          </button>
        </div>
        <div className={styles.editor}>
          <Suspense fallback={<LazyLoadingIndicator />}>
            <LazyDulceEditor
              onChange={detailsHelpers.setValue}
              initalValue={props.detailsInitialValue}
              styleApi={props.styleApi}
            />
          </Suspense>
        </div>
      </div>
    </>
  );
};
