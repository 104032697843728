import { Text } from "@dulce/design-system";
import { Metadata } from "@dulce/models/dist/oembed.models";
import { LinkElement } from "@dulce/models/dist/slate.primatives";
import { faHourglass } from "@fortawesome/free-regular-svg-icons";
import { faLink } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { RenderElementProps, useReadOnly, useSlate } from "slate-react";
import * as styles from "./LinkOembed.css";

export type LinkOembedProps = {
  favicon: string;
  url: string;
  children: any;
  contentEditable?: boolean;
  attributes?: any;
  isLoading?: boolean;
};
export const LinkOembed = (props: LinkOembedProps) => {
  const { favicon, url, children, contentEditable, isLoading } = props;
  if (isLoading) {
    return (
      <a className={styles.root} href={url} target="_blank">
        <div className={styles.favicon}>
          <FontAwesomeIcon icon={faHourglass} />
        </div>
        <Text className={styles.text}>Loading...</Text>
      </a>
    );
  }
  return (
    <a className={styles.root} href={url} target="_blank">
      {!!favicon ? (
        <img src={favicon} className={styles.favicon} contentEditable={false} />
      ) : (
        <div className={styles.favicon}>
          <FontAwesomeIcon icon={faLink} />
        </div>
      )}
      <span
        className={styles.text}
        contentEditable={contentEditable}
        suppressContentEditableWarning
      >
        {children}
      </span>
    </a>
  );
};

export const LinkOembedElement = (props: RenderElementProps) => {
  const { attributes, children, element } = props;
  const el = element as LinkElement;
  const readOnly = useReadOnly();

  return (
    <LinkOembed
      favicon={el.favicon}
      url={el.url}
      children={children}
      contentEditable={!readOnly}
      attributes={attributes}
      isLoading={el.isLoading}
    />
  );
};
