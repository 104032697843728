import { Color, Minutes, Title } from "@dulce/models/dist/tasks.models";
import { createContext, ReactChild, ReactChildren } from "react";
import { useValue, UseValueResult } from "../../utils/useValue";

export enum TaskListSortBy {
  NONE = "NONE",
  DURATION = "DURATION",
  COLOR = "COLOR",
  PREFIX = "PREFIX",
  LOCALITY = "LOCALITY",
}

export type TaskListFilterContextValue = {
  color: UseValueResult<Color>;
  title: UseValueResult<Title>;
  estimate: UseValueResult<Minutes>;
  sortBy: UseValueResult<TaskListSortBy>;
  isFilterEnabled: () => boolean;
};

const noop = () => { };

const initValue: TaskListFilterContextValue = {
  color: {
    value: "",
    setValue: noop,
  },
  title: {
    value: "",
    setValue: noop,
  },
  estimate: {
    value: 0,
    setValue: noop,
  },
  sortBy: {
    value: TaskListSortBy.NONE,
    setValue: noop,
  },
  isFilterEnabled: () => false,
};

export const TaskListFilterContext =
  createContext<TaskListFilterContextValue>(initValue);

export type TaskListFilterProviderProps = {
  children: ReactChild | ReactChildren;
};

export const TaskListFilterProvider = ({
  children,
}: TaskListFilterProviderProps) => {
  const title = useValue<Title>(initValue.title.value);
  const color = useValue<Color>(initValue.color.value);
  const estimate = useValue<Minutes>(initValue.estimate.value);
  const sortBy = useValue<TaskListSortBy>(initValue.sortBy.value);
  const isFilterEnabled = () =>
    !!(
      title.value !== "" ||
      color.value !== "" ||
      estimate.value !== 0 ||
      sortBy.value !== TaskListSortBy.NONE
    );

  return (
    <TaskListFilterContext.Provider
      value={{
        isFilterEnabled,
        title,
        color,
        estimate,
        sortBy,
      }}
    >
      {children}
    </TaskListFilterContext.Provider>
  );
};
