import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from "@tanstack/react-query";
import { handleOnError } from "./utils";
import { sdk } from "../sdk";
import { Task } from "@dulce/models/dist/tasks.models";
import { FPArray, pipe } from "@dulce/prelude";
import { getRandomInt } from "../utils/getRandom";
import { useContext } from "react";
import { AppContext } from "../AppContext";
import { ConfettiContext } from "../confetti/ConfettiContext";
import { toast } from "react-hot-toast";

let timeout: any = undefined;

// queries
//

type UseTasksOptions<TData> = Partial<
  Pick<UseQueryOptions<Array<Task>, unknown, TData>, "select" | "enabled">
>;
export function useTasks<TData = Array<Task>>(
  opts: UseTasksOptions<TData> = {}
) {
  return useQuery(["tasks"], {
    queryFn: async () => {
      return await sdk.tasks.queries.findMyTasks();
    },
    select: opts.select,
    enabled: opts.enabled ?? true,
    onError: (error) => {
      if (error instanceof Error) {
        console.error("Error: ", error.name, error.message);
      } else {
        console.error("Error: ", error);
      }
    },
  });
}

// partial subscriptions - https://tkdodo.eu/blog/react-query-data-transformations
// great for computed values

export type UseTaskOptions = {
  eagerFetch?: boolean;
};
export const useTask = (id: string, opts: UseTaskOptions = {}) =>
  useTasks<Task | undefined>({
    select: (tasks) => tasks.find((task) => task.id === id),
    enabled: opts.eagerFetch ?? false,
  });

// mutations

export const useTasksMutations = () => {
  const queryClient = useQueryClient();
  const appContext = useContext(AppContext);
  const confettiContext = useContext(ConfettiContext);

  const queryKey = ["tasks"];

  // mutations

  const addTaskMutation = useMutation(sdk.tasks.commands.addTask, {
    onSuccess: () => {
      queryClient.invalidateQueries(["tasks"]);
      queryClient.invalidateQueries(["suggested-tasks"]);
      toast.success("Task created!");
    },
    onError: handleOnError,
  });

  const clearTimeboxMutation = useMutation(
    () => sdk.tasks.commands.clearTimebox({}),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["tasks"]);
        queryClient.invalidateQueries(["suggested-tasks"]);
        toast.success("Timebox cleared!");
      },
      onError: handleOnError,
    }
  );

  const unscheduleTaskMutation = useMutation(
    sdk.tasks.commands.unscheduleTask,
    {
      onSuccess: (task) => {
        queryClient.invalidateQueries(["tasks"]);
        queryClient.invalidateQueries(["suggested-tasks"]);
        toast.success("Task unscheduled!");
      },
      onError: handleOnError,
    }
  );

  const _upsertTaskIntoQueryData = async (
    task: Partial<Task> & Pick<Task, "id">
  ) => {
    const queryKey = ["tasks"];
    // note: make sure to cancel the query so that optimistic update doesn't get
    // overwritten
    await queryClient.cancelQueries({ queryKey });
    queryClient.setQueryData<Array<Task>>(queryKey, (prev) => {
      const foundIndex = prev?.findIndex((prevTask) => prevTask.id === task.id);
      if (foundIndex !== undefined && Array.isArray(prev)) {
        let updatedTasks = [...prev];
        updatedTasks[foundIndex] = {
          ...prev[foundIndex],
          ...task,
        };
        return updatedTasks;
      }
      return prev ?? [];
    });
  };

  const scheduleTaskMutation = useMutation(sdk.tasks.commands.scheduleTask, {
    onSuccess: (task) => {
      _upsertTaskIntoQueryData(task);
      queryClient.invalidateQueries(["tasks"]);
      queryClient.invalidateQueries(["suggested-tasks"]);
    },
    onError: handleOnError,
  });

  const deleteTaskMutation = useMutation(sdk.tasks.commands.deleteTask, {
    onSuccess: (task) => {
      queryClient.setQueryData<Array<Task>>(["tasks"], (prev) =>
        pipe(
          prev as Array<Task>,
          FPArray.reduce([] as Array<Task>, (prev, prevTask) => {
            if (prevTask.id === task.id) {
              return prev;
            }
            return [...prev, prevTask];
          })
        )
      );
      queryClient.invalidateQueries(["tasks"]);
      queryClient.invalidateQueries(["suggested-tasks"]);
      toast.success("Task deleted!");
    },
    onError: handleOnError,
  });

  const completeTaskMutation = useMutation(sdk.tasks.commands.completeTask, {
    onSuccess: (task) => {
      _upsertTaskIntoQueryData(task);
      if (!timeout) {
        const timeoutFn = () => {
          queryClient.invalidateQueries(["tasks"]);
          queryClient.invalidateQueries(["suggested-tasks"]);
          timeout = undefined;
        };
        timeout = setTimeout(timeoutFn, 2500);
      }
      const origin = pipe(
        document.querySelector<HTMLDivElement>(`[data-task-id="${task.id}"`),
        (element) => element?.getBoundingClientRect(),
        (rect) => ({
          x: (rect?.x ?? 0) + 16,
          y: rect?.y ?? 0,
        })
      );
      confettiContext?.startCompleteAnimation?.({
        color: task.color as any,
        origin,
      });
      const audioKeyMap: Record<number, string> = {
        0: "low",
        1: "normal",
        2: "high",
        3: "higher",
      };
      const audio = pipe(
        getRandomInt(0, 4),
        (key) => audioKeyMap[key],
        (key) => appContext.audioMap[key]
      );
      audio.play();
    },
    onError: handleOnError,
  });

  const uncompleteTaskMutation = useMutation(
    sdk.tasks.commands.uncompleteTask,
    {
      onSuccess: (task) => {
        _upsertTaskIntoQueryData(task);
        queryClient.invalidateQueries(["tasks"]);
        queryClient.invalidateQueries(["suggested-tasks"]);
      },
      onError: handleOnError,
    }
  );
  const duplicateTaskMutation = useMutation(sdk.tasks.commands.duplicateTask, {
    onSuccess: (task) => {
      _upsertTaskIntoQueryData(task);
      queryClient.invalidateQueries(["tasks"]);
      queryClient.invalidateQueries(["suggested-tasks"]);
    },
    onError: handleOnError,
  });

  const updateTaskMutation = useMutation(sdk.tasks.commands.updateTask, {
    onMutate: async (task) => {
      await _upsertTaskIntoQueryData(task);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(queryKey);
      queryClient.invalidateQueries(["tasks"]);
      queryClient.invalidateQueries(["suggested-tasks"]);
    },
    onError: handleOnError,
  });

  const lockTaskMutation = useMutation(sdk.tasks.commands.lockTask, {
    onSuccess: (task) => {
      _upsertTaskIntoQueryData(task);
      queryClient.invalidateQueries(["tasks"]);
      queryClient.invalidateQueries(["suggested-tasks"]);
    },
    onError: handleOnError,
  });

  const superLockTaskMutation = useMutation(sdk.tasks.commands.superLockTask, {
    onSuccess: (task) => {
      _upsertTaskIntoQueryData(task);
      queryClient.invalidateQueries(["tasks"]);
      queryClient.invalidateQueries(["suggested-tasks"]);
    },
    onError: handleOnError,
  });

  const unlockTaskMutation = useMutation(sdk.tasks.commands.unlockTask, {
    onSuccess: (task) => {
      _upsertTaskIntoQueryData(task);
      queryClient.invalidateQueries(["tasks"]);
      queryClient.invalidateQueries(["suggested-tasks"]);
    },
    onError: handleOnError,
  });

  const enableRecurringTaskMutation = useMutation(
    sdk.tasks.commands.enableRecurringTask,
    {
      onSuccess: (task) => {
        _upsertTaskIntoQueryData(task);
        queryClient.invalidateQueries(["tasks"]);
        queryClient.invalidateQueries(["suggested-tasks"]);
      },
      onError: handleOnError,
    }
  );
  const disableRecurringTaskMutation = useMutation(
    sdk.tasks.commands.disableRecurringTask,
    {
      onSuccess: (task) => {
        _upsertTaskIntoQueryData(task);
        queryClient.invalidateQueries(["tasks"]);
        queryClient.invalidateQueries(["suggested-tasks"]);
      },
      onError: handleOnError,
    }
  );
  return {
    addTaskMutation,
    clearTimeboxMutation,
    completeTaskMutation,
    deleteTaskMutation,
    disableRecurringTaskMutation,
    duplicateTaskMutation,
    enableRecurringTaskMutation,
    lockTaskMutation,
    scheduleTaskMutation,
    superLockTaskMutation,
    uncompleteTaskMutation,
    unlockTaskMutation,
    unscheduleTaskMutation,
    updateTaskMutation,
    _upsertTaskIntoQueryData,
  };
};
