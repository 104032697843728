import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { useCallback } from "react";
import { handleOnError } from "./utils";
import { toast } from "react-hot-toast";
import { sdk } from "../sdk";
import { signOut } from "supertokens-auth-react/recipe/thirdpartypasswordless";

const userQueryKey = ["user:self"];

export const useUser = () => {
  const selfQuery = useQuery(userQueryKey, sdk.users.queries.getSelf, {
    onError: handleOnError,
  });
  const selectEmail = useCallback(() => {
    if (selfQuery.isLoading) {
      return "Loading...";
    }
    if (selfQuery.isError) {
      return "N/A";
    }
    return selfQuery.data?.email ?? "";
  }, [selfQuery]);
  const handleSignOut = async () => {
    await signOut();
    window.location.href = "/auth";
  };
  return {
    selfQuery,
    handleSignOut,
    selectEmail,
  };
};

export const useUserMutation = () => {
  const queryClient = useQueryClient();
  const updateMyPreferences = useMutation({
    mutationFn: sdk.users.commands.updateMyPreferences,
    onError: handleOnError,
    onSuccess: () => {
      queryClient.invalidateQueries(userQueryKey);
      toast.success("User preferences updated!");
    },
  });
  return {
    updateMyPreferences,
  };
};
