import { Color, Task } from "@dulce/models/dist/tasks.models";
import pluralize from "pluralize";
import { FPArray, pipe } from "@dulce/prelude";
import { Size, theme } from "../../theme.css";
import * as styles from "./TimeboxStats.css";
import { useTasks } from "../../hooks/useTasks";
import { Text } from "@dulce/design-system";

export type TimeboxStatsProps = {};
export const TimeboxStats = (props: TimeboxStatsProps) => {
  const tasksQuery = useTasks();
  const colorStats = pipe(
    tasksQuery.data ?? [],
    FPArray.filter((task) => !!task.startTime),
    FPArray.reduce<Task, Record<Color, Task[]>>({}, (prev, task) => {
      const prevColor = prev[task.color] ?? [];
      return { ...prev, [task.color]: [...prevColor, task] };
    }),
    (record) => Object.entries<Task[]>(record),
    FPArray.map(([key, tasks]) => {
      const colors = theme.colors as any;
      const totalMinutes = pipe(
        tasks,
        FPArray.reduce(0, (prev, task) => prev + task.duration)
      );
      const totalBlocks = pipe(totalMinutes, (value) => value / 10);

      const totalHours = pipe(
        totalMinutes,
        (value) => value / 60,
        (value) => value.toFixed(2),
        parseFloat
      );
      return (
        <div
          key={key}
          style={{
            background: colors[key][3],
            border: `${colors.black} thin solid`,
            borderRadius: theme.space[1],
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: theme.space[1],
          }}
        >
          <Text size={Size.sm}>{pluralize("hr", totalHours, true)}</Text>
          <Text size={Size.sm}>/</Text>
          <Text size={Size.sm}>{pluralize("tasks", tasks.length, true)}</Text>
        </div>
      );
    })
  );

  return <div className={styles.root}>{colorStats}</div>;
};
