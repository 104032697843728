import { TaskId } from "@dulce/models/dist/tasks.models"
import { FPArray, O, pipe } from "@dulce/prelude"
import { store } from "./Selection.state"

const { getState } = store

export const listTasks = () => {
  const { selectedTasks } = getState()
  return selectedTasks
}

export const isTaskSelected = (taskId: TaskId) => pipe(
  getState().selectedTasks,
  FPArray.findFirst(id => id === taskId),
  O.fold(
    () => false,
    () => true
  )
)

export const isAnyTaskSelected = () => {
  const { selectedTasks } = getState()
  return !!selectedTasks.length
}