import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useField } from "formik";
import { ReactNode, RefObject } from "react";
import * as styles from "./Checkbox.css";

type CustomHTMLInputProps = Omit<
  React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >,
  "type"
>;

export type CheckboxProps = CustomHTMLInputProps & {
  label: ReactNode;
  inputRef?: RefObject<HTMLInputElement>;
};

export const Checkbox = (props: CheckboxProps) => {
  const { label, ...rest } = props;
  const [field] = useField({
    ...(rest as any),
    type: "checkbox",
  });
  return (
    <label className={styles.root}>
      <span className={styles.checkbox}>
        <FontAwesomeIcon
          icon={faCheck}
          className={styles.checkmark}
          style={{
            opacity: field.value ? 1 : 0,
          }}
        />
      </span>
      <span className={styles.label}>{label}</span>
      <input
        className={styles.hiddenInput}
        {...field}
        {...rest}
        type="checkbox"
      />
    </label>
  );
};
