import { CustomText } from "@dulce/models/dist/slate.primatives";
import { Details, Task } from "@dulce/models/dist/tasks.models";
import { pipe, FPArray } from "@dulce/prelude";
import { Node } from "slate";
import { Paragraph } from "../../components/EditTaskForm/EditTaskForm";
import { useTask, useTasksMutations } from "../../hooks/useTasks";
import { Heading } from "../Heading/Heading";
import { LinkOembed } from "./elements/LinkOembed/LinkOembed";
import { SubTask } from "./elements/SubTask/SubTask";
import * as styles from "./DulceEditor.css";
import { BulletedList } from "./elements/BulletedList/BulletedList";
import { ListItem } from "./elements/ListItem/ListItem";
import { Size, Text } from "@dulce/design-system";

export type _DulceEditorPreviewProps = {
  details: Details;
};

export const _DulceEditorPreview = (props: _DulceEditorPreviewProps) => {
  const renderLeaf = (data: CustomText) => {
    return (
      <Text bold={data.bold} italic={data.italic}>
        {data.text}
      </Text>
    );
  };
  const renderElement = (data: Node) => {
    if ("type" in data && data.type === "paragraph") {
      return (
        <Text as="p" children={pipe(data.children, FPArray.map(renderLeaf))} />
      );
    }
    if ("type" in data && data.type === "heading1") {
      return (
        <Heading
          as="h2"
          children={pipe(data.children, FPArray.map(renderLeaf))}
          size={Size.md}
        />
      );
    }
    if ("type" in data && data.type === "heading2") {
      return (
        <Heading
          as="h3"
          children={pipe(data.children, FPArray.map(renderLeaf))}
          size={Size.sm}
        />
      );
    }
    if ("type" in data && data.type === "link") {
      return (
        <LinkOembed
          favicon={data.favicon}
          url={data.url}
          children={pipe(data.children, FPArray.map(renderLeaf))}
        />
      );
    }
    if ("type" in data && data.type === "bulleted-list") {
      return (
        <BulletedList
          children={pipe(data.children, FPArray.map(renderElement))}
        />
      );
    }
    if ("type" in data && data.type === "list-item") {
      return (
        <ListItem children={pipe(data.children, FPArray.map(renderLeaf))} />
      );
    }
  };
  const elements = pipe(
    props.details as any,
    Paragraph.fromStringOrCustomElements,
    FPArray.map(renderElement)
  );

  return <div className={styles.editable}>{elements}</div>;
};

export type DulceEditorPreviewProps = {
  task: Task;
};

export const DulceEditorPreview = (props: DulceEditorPreviewProps) => {
  const task = useTask(props.task.id);
  const { updateTaskMutation } = useTasksMutations();

  const renderLeaf = (data: CustomText) => {
    return (
      <Text bold={data.bold} italic={data.italic}>
        {data.text}
      </Text>
    );
  };
  const renderElement = (data: Node) => {
    if ("type" in data && data.type === "paragraph") {
      return (
        <Text as="p" children={pipe(data.children, FPArray.map(renderLeaf))} />
      );
    }
    if ("type" in data && data.type === "heading1") {
      return (
        <Heading
          as="h2"
          children={pipe(data.children, FPArray.map(renderLeaf))}
          size={Size.md}
        />
      );
    }
    if ("type" in data && data.type === "heading2") {
      return (
        <Heading
          as="h3"
          children={pipe(data.children, FPArray.map(renderLeaf))}
          size={Size.sm}
        />
      );
    }
    if ("type" in data && data.type === "link") {
      return (
        <LinkOembed
          favicon={data.favicon}
          url={data.url}
          children={pipe(data.children, FPArray.map(renderLeaf))}
        />
      );
    }
    if ("type" in data && data.type === "bulleted-list") {
      return (
        <BulletedList
          children={pipe(data.children, FPArray.map(renderElement))}
        />
      );
    }
    if ("type" in data && data.type === "list-item") {
      return (
        <ListItem children={pipe(data.children, FPArray.map(renderLeaf))} />
      );
    }
    if ("type" in data && data.type === "subtask") {
      return (
        <SubTask
          checked={data.checked}
          children={pipe(data.children, FPArray.map(renderLeaf))}
          styleApi={{ color: props.task.color as any }}
          onChange={async (event) => {
            const { checked } = event.target;
            const updatedDetails = pipe(
              task.data?.details as any,
              Paragraph.fromStringOrCustomElements,
              FPArray.map((node) => {
                if ("type" in node && node.type === "subtask") {
                  if (node.subtaskId === data.subtaskId) {
                    return {
                      ...node,
                      checked,
                    };
                  }
                }
                return node;
              })
            );
            await updateTaskMutation.mutateAsync({
              ...props.task,
              startTime: props.task.startTime ?? undefined,
              id: props.task.id,
              details: updatedDetails,
            });
          }}
        />
      );
    }

    return <Text children={"Unable to handle this element..."} />;
  };

  const elements = pipe(
    props.task?.details as any,
    Paragraph.fromStringOrCustomElements,
    FPArray.map(renderElement)
  );

  return <div className={styles.editable}>{elements}</div>;
};
