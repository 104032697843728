import { FormikProvider, useFormik } from "formik";
import * as styles from "./SearchAndFilter.css";
import { Input } from "../../../foundation/Input/Input";
import {
  SearchAndFilterStoreValues,
  searchAndFilterStore,
} from "./SearchAndFilter.state";
import { useStore } from "zustand";
import { useEffect } from "react";
import { Chip } from "../../../foundation/Chip/Chip";
import { Flex, theme, Size, Button } from "@dulce/design-system";

export type SearchAndFilterProps = {};

export const SearchAndFilter = (props: SearchAndFilterProps) => {
  const { searchQuery, colorFilter } = useStore(searchAndFilterStore);
  const initialValues: SearchAndFilterStoreValues = {
    searchQuery,
    colorFilter,
  };
  const formik = useFormik({
    initialValues,
    onSubmit: async (values) => {
      console.log(values);
    },
  });

  const handleReset = () => {
    formik.setValues({
      colorFilter: {
        blue: false,
        gray: false,
        green: false,
        purple: false,
        red: false,
        yellow: false,
      },
      searchQuery: "",
    });
  };

  useEffect(() => {
    searchAndFilterStore.setState(formik.values);
  }, [formik.values]);

  return (
    <FormikProvider value={formik}>
      <form className={styles.root} onSubmit={formik.handleSubmit}>
        <Flex column gap={Size.md}>
          <Input name="searchQuery" placeholder="Search..." />
          <div className={styles.chipGrid}>
            <Chip name="colorFilter.gray" color="gray" />
            <Chip name="colorFilter.purple" color="purple" />
            <Chip name="colorFilter.blue" color="blue" />
            <Chip name="colorFilter.green" color="green" />
            <Chip name="colorFilter.red" color="red" />
            <Chip name="colorFilter.yellow" color="yellow" />
            <Button size={Size.sm} onClick={handleReset}>
              Reset
            </Button>
          </div>
        </Flex>
      </form>
    </FormikProvider>
  );
};
