import { useSelect } from "downshift"
import { useField } from "formik"
import * as styles from "./Dropdown.css"
import {useEffect} from "react"
import {theme, Button} from "@dulce/design-system"

export type DropdownItem<T> = {
  label: string
  value: T
}

export type DropdownProps<T> = {
  name: string
  label: string
  items: Array<DropdownItem<T>>,
  defaultItem?: DropdownItem<T>
}
export function Dropdown<T>(props: DropdownProps<T>) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [field, meta, helpers] = useField(props.name)
  const {
    isOpen,
    selectedItem,
    getToggleButtonProps,
    getLabelProps,
    getMenuProps,
    highlightedIndex,
    getItemProps,
    selectItem
  } = useSelect({
    items: props.items,
    onSelectedItemChange: item => {
      helpers.setTouched(true)
      helpers.setValue(item.selectedItem?.value)
    },
    defaultSelectedItem: props.defaultItem
  })

  useEffect(() => {
    const foundItem = props.items.find(item => item.value === field.value)
    if (foundItem) {
      selectItem(foundItem)
    }
  }, [field.value])

  return (
    <div className={styles.root}>
      <div className={styles.info}>
        <label className={styles.label} {...getLabelProps()}>{props.label}</label>
        <Button
          type="button"
          outline
          color="ghost"
          {...getToggleButtonProps()}
          aria-label="toggle menu"
        >
          {
            selectedItem ? (
              `${selectedItem.label}`
            ) : (
              "Choose item"
            )
          }
        </Button>
      </div>
      <ul {...getMenuProps()} className={styles.menu} style={{ opacity: isOpen ? 1 : 0 }}>
        {isOpen &&
          props.items.map((item, index) => (
            <li
              className={styles.menuItem}
              style={
                highlightedIndex === index
                  ? { backgroundColor: theme.colors.gray[2] }
                  : {}
              }
              key={`${item.value}${index}`}
              {...getItemProps({ item, index })}
            >
              {item.label}
            </li>
          ))}
      </ul>
    </div>
  )
}
