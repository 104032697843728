
import { BulletedListElement } from "@dulce/models/dist/slate.primatives"
import { FPArray, pipe } from "@dulce/prelude"
import { RenderElementProps } from "slate-react"
import * as styles from "./BulletedList.css"

export type BulletedListProps = {
  children: any
  attributes?: any
}

export const BulletedList = (props: BulletedListProps) => {
  return (
    <ul className={styles.root}>
      {props.children}
    </ul>
  )
}

export const BulletListElement = (props: RenderElementProps) => {
  const { attributes, children, element } = props
  return <BulletedList
    children={children}
    attributes={attributes}
  />
}
