import { Button, Flex, Size, Text, theme } from "@dulce/design-system";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faSave } from "@fortawesome/free-regular-svg-icons";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import * as modal from "../../../foundation/Modal/useModal";
import {
  faEraser,
  faMicrophone,
  faMicrophoneAlt,
} from "@fortawesome/free-solid-svg-icons";
import { ButtonWithIcon } from "../../../foundation/ButtonWithIcon/ButtonWithIcon";
import { Boolean, pipe } from "@dulce/prelude";
import { useTasksMutations } from "../../../hooks/useTasks";
import { AddTaskDto } from "@dulce/models/dist/tasks.models";
import { useContext } from "react";
import { AppContext } from "../../../AppContext";
import { openCreateTaskModal } from "../../../foundation/Modal/modals/openCreateTaskModal";

const VoiceInputModal = () => {
  const {
    finalTranscript,
    listening: isListening,
    interimTranscript,
    resetTranscript,
  } = useSpeechRecognition();

  const { addTaskMutation } = useTasksMutations();
  const appContext = useContext(AppContext);

  const startListening = () => {
    SpeechRecognition.startListening({ continuous: true });
  };

  const stopListening = () => {
    SpeechRecognition.stopListening();
  };

  const handleSave = async () => {
    const draft: AddTaskDto = {
      color: "gray",
      title: finalTranscript,
      duration: 10,
    };
    await addTaskMutation.mutateAsync(draft);

    resetTranscript();
    modal.close();
  };

  const handleOpenDetails = () => {
    appContext.initTaskTitle.setValue(finalTranscript);
    resetTranscript();
    openCreateTaskModal();
  };

  return (
    <Flex
      column
      justifyContent="space-between"
      alignItems="center"
      style={{ height: "100%" }}
    >
      <Flex
        justifyContent="center"
        alignItems="center"
        style={{ height: "100%" }}
      >
        {pipe(
          !!interimTranscript.length || !!finalTranscript.length,
          Boolean.match(
            () => (
              <Text size={Size.xl}>
                <span style={{ color: theme.colors.gray[6] }}>
                  Hold and try saying something...
                </span>
              </Text>
            ),
            () => (
              <Text size={Size.xl} style={{ textAlign: "center" }}>
                <span>{finalTranscript}</span>{" "}
                <span style={{ color: theme.colors.gray[6] }}>
                  {interimTranscript}
                </span>
              </Text>
            )
          )
        )}
      </Flex>
      <Flex column gap={Size.md} style={{ width: "100%" }}>
        <Button
          color={isListening ? "danger" : "secondary"}
          style={{
            width: "100%",
          }}
          size={Size.lg}
          onMouseUp={stopListening}
          onMouseDown={startListening}
          onTouchEnd={stopListening}
          onTouchStart={startListening}
        >
          <Flex gap={Size.sm} column justifyContent="center">
            <FontAwesomeIcon
              icon={isListening ? faMicrophoneAlt : faMicrophone}
            />
            <Text>{isListening ? "Listening..." : "Press and hold"}</Text>
          </Flex>
        </Button>
        <Flex
          style={{ width: "100%" }}
          gap={Size.sm}
          justifyContent="space-between"
        >
          <ButtonWithIcon
            color="ghost"
            icon={faEraser}
            onClick={resetTranscript}
          >
            Reset
          </ButtonWithIcon>
          <Flex gap={Size.md}>
            <ButtonWithIcon
              color="secondary"
              icon={faEdit}
              disabled={finalTranscript.length === 0}
              onClick={handleOpenDetails}
            >
              Add details
            </ButtonWithIcon>
            <ButtonWithIcon
              color="primary"
              icon={faSave}
              disabled={finalTranscript.length === 0}
              onClick={handleSave}
            >
              Save
            </ButtonWithIcon>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

export const openVoiceInputModal = () => {
  if (SpeechRecognition.browserSupportsSpeechRecognition()) {
    modal.open({
      title: "Speech-to-Task",
      maxHeight: Size.md,
      onRequestClose: () => {
        modal.close();
      },
      children: (
        <>
          <VoiceInputModal />
        </>
      ),
    });
  }
};
