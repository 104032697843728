import { FPArray, pipe, TE } from "@dulce/prelude";
import { getLogoAsHtmlString, LogoProps } from "../components/Logo/Logo";
import { nanoid } from "nanoid";
import { Predicate } from "fp-ts/lib/function";

export const generateConfettiImage = (color: LogoProps["color"]) => {
  const logoSvgData = getLogoAsHtmlString({ color });
  const svg = new Blob([logoSvgData], { type: "image/svg+xml" });
  const url = window.URL.createObjectURL(svg);
  const img = new Image();
  img.src = url;
  return img;
};

export const allConfettiImages: Record<LogoProps["color"], HTMLImageElement> = {
  blue: generateConfettiImage("blue"),
  purple: generateConfettiImage("purple"),
  gray: generateConfettiImage("gray"),
  green: generateConfettiImage("green"),
  red: generateConfettiImage("red"),
  yellow: generateConfettiImage("yellow"),
};

const PositionComponent = ({ initX = 0, initY = 0 } = {}) => ({
  x: initX ?? 0,
  y: initY ?? 0,
});
type PositionComponent = ReturnType<typeof PositionComponent>;
type PositionComponentOpts = Parameters<typeof PositionComponent>[0];

const VelocityComponent = ({ initX = 0, initY = 0 } = {}) => ({
  x: initX ?? 0,
  y: initY ?? 0,
});
type VelocityComponent = ReturnType<typeof VelocityComponent>;
type VelocityComponentOpts = Parameters<typeof VelocityComponent>[0];

export enum RotationDirection {
  LEFT = "LEFT",
  RIGHT = "RIGHT",
}
const RotationComponent = ({
  direction = RotationDirection.LEFT,
  position = 0,
  speed = 0,
}: {
  direction: RotationDirection;
  position: number;
  speed: number;
}) => ({
  direction,
  speed,
  position,
});
type RotationComponent = ReturnType<typeof RotationComponent>;
type RotationComponentOpts = Parameters<typeof RotationComponent>[0];

const ImageComponent = (color: LogoProps["color"]) => ({
  imageData: allConfettiImages[color],
});
type ImageComponent = ReturnType<typeof ImageComponent>;
type ImageComponentOpts = Parameters<typeof ImageComponent>[0];

type ConfettiEntity = {
  id: string;
  position: PositionComponent;
  velocity: VelocityComponent;
  rotation: RotationComponent;
  image: ImageComponent;
};
export type CreateConfettiEntityOpts = {
  position: PositionComponentOpts;
  velocity: VelocityComponentOpts;
  rotation: RotationComponentOpts;
  image: ImageComponentOpts;
};
const createConfettiEntity = (
  opts: CreateConfettiEntityOpts
): ConfettiEntity => ({
  id: nanoid(),
  image: ImageComponent(opts.image),
  position: PositionComponent(opts.position ?? {}),
  velocity: VelocityComponent(opts.velocity ?? {}),
  rotation: RotationComponent(opts.rotation ?? {}),
});

type MovableEntity = {
  position: ReturnType<typeof PositionComponent>;
  velocity: ReturnType<typeof VelocityComponent>;
  [key: string]: any;
};

// delta is the accumlated delta, not the delta chunk
const getFpsScalarFromDelta = (delta: number) => {
  const normalFps = 1000 / 60; // ie if 30 fps, then interval === 33ms
  const diff = delta / normalFps; // ie 35ms / 33ms === 1.06 ish
  return diff;
};

const applyMovementSystem =
  (delta: number) => (entities: Array<MovableEntity>) =>
    pipe(
      entities,
      FPArray.map((entity) => {
        return {
          ...entity,
          position: {
            x:
              entity.position.x +
              entity.velocity.x * getFpsScalarFromDelta(delta),
            y:
              entity.position.y +
              entity.velocity.y * getFpsScalarFromDelta(delta),
          },
        };
      })
    );

const applyGravitySystem =
  (delta: number) => (entities: Array<MovableEntity>) =>
    pipe(
      entities,
      FPArray.map((entity) => {
        const gravity = 0.5;
        return {
          ...entity,
          velocity: {
            x: entity.velocity.x + 0,
            y: entity.velocity.y + gravity * getFpsScalarFromDelta(delta),
          },
        };
      })
    );

export type RotatableEntity = {
  rotation: ReturnType<typeof RotationComponent>;
  [key: string]: any;
};

const applyRotationSystem =
  (delta: number) =>
  (entities: Array<RotatableEntity>): Array<RotatableEntity> =>
    pipe(
      entities,
      FPArray.map((entity) => ({
        ...entity,
        rotation: {
          direction: RotationDirection.LEFT,
          position:
            entity.rotation.position +
            entity.rotation.speed * getFpsScalarFromDelta(delta),
          speed: entity.rotation.speed,
        },
      }))
    );

const applyDespawnSystem =
  (canvasHeight: number) =>
  (entities: Array<MovableEntity>): Array<MovableEntity> =>
    pipe(
      entities,
      FPArray.filter((entity) => entity.position.y < canvasHeight + 40)
    );

export type WorldOpts = {
  width?: number;
  height?: number;
  context?: CanvasRenderingContext2D;
};
export const World = (opts: WorldOpts) => {
  // state
  let entities: Array<ConfettiEntity> = [];
  let height = opts.height ?? 10;
  let width = opts.width ?? 10;
  let context = opts.context;

  const queryApplyMerge =
    (
      predicate: Predicate<ConfettiEntity>,
      systemFn: (v: Array<any>) => Array<any>
    ) =>
    (entities: Array<ConfettiEntity>) =>
      pipe(
        entities,
        FPArray.partition(predicate),
        ({ left, right }) => ({
          left,
          right: systemFn(right),
        }),
        ({ left, right }) => [...left, ...right] as Array<ConfettiEntity>
      );

  const drawWorld = (entities: Array<ConfettiEntity>) => {
    if (context) {
      context.clearRect(0, 0, width, height);
      entities.forEach((entity) => {
        if (context) {
          context.save();
          context.translate(entity.position.x, entity.position.y);
          context.rotate((entity.rotation.position * Math.PI) / 180);
          context.drawImage(
            entity.image.imageData,
            -entity.image.imageData.width / 2,
            -entity.image.imageData.height / 2
          );
          context.restore();
        }
      });
    }
  };

  return {
    run: (delta: number) => {
      let updatedEntities = pipe(
        entities,
        queryApplyMerge(
          (entity) => !!entity.position,
          applyMovementSystem(delta)
        ),
        queryApplyMerge(
          (entity) => !!entity.velocity,
          applyGravitySystem(delta)
        ),
        queryApplyMerge(
          (entity) => !!entity.rotation,
          applyRotationSystem(delta)
        ),
        queryApplyMerge(
          (entity) => !!entity.position,
          applyDespawnSystem(height)
        )
      );
      entities = updatedEntities;
      drawWorld(entities);
    },
    setWidth: (v: number) => {
      width = v;
    },
    setHeight: (v: number) => {
      height = v;
    },
    setContext: (v: CanvasRenderingContext2D) => {
      context = v;
    },
    addEntity: (opts: CreateConfettiEntityOpts) => {
      let entity = createConfettiEntity(opts);
      entities.push(entity);
    },
  };
};

