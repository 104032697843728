import classNames from "classnames"
import React, { RefCallback, RefObject } from "react"
import * as styles from "./LinealBox.css"
import { LinealLine, LinealLineProps } from "./LinealLine/LinealLine"

type PartialLinealLineProps = Partial<Omit<LinealLineProps, "orientation">>

export type LinealBoxProps = {
  as?: keyof React.ReactHTML
  children: React.ReactNode
  radius?: number
  topLineProps?: PartialLinealLineProps
  rightLineProps?: PartialLinealLineProps
  bottomLineProps?: PartialLinealLineProps
  leftLineProps?: PartialLinealLineProps,
  className?: string // styles the inner content container
  ref?: RefObject<HTMLDivElement> | RefCallback<HTMLDivElement> // ref for innter content container
  outerClassName?: string // styles the outer div, you should rarely use this
  outerRef?: RefObject<HTMLDivElement> | RefCallback<HTMLDivElement> // ref for outer div, handy for drag and drop 
  styleApi?: Parameters<typeof styles.root>[0]
  outerProps?: any
  innerProps?: any
}

type GetDefaultLinePropsOpts = Pick<LinealLineProps, "orientation" | "alignment">

const getDefaultLineProps = (opts: GetDefaultLinePropsOpts): LinealLineProps => ({
  orientation: opts.orientation,
  alignment: opts.alignment,
  gap: 0,
  variant: "normal",
  position: 50,
})

const mergeLinealLineProps = (defaultProps: LinealLineProps, partialProps: PartialLinealLineProps) => ({
  ...defaultProps,
  ...partialProps
})

const _LinealBox = React.forwardRef((props: LinealBoxProps, ref) => {

  const { radius = 0 } = props

  const tlcStyle: React.CSSProperties = {
    borderTopLeftRadius: radius
  }

  const trcStyle: React.CSSProperties = {
    borderTopRightRadius: radius
  }

  const brcStyle: React.CSSProperties = {
    borderBottomRightRadius: radius
  }

  const blcStyle: React.CSSProperties = {
    borderBottomLeftRadius: radius
  }

  const leftLinealLineProps = mergeLinealLineProps(
    getDefaultLineProps({ orientation: "vertical", alignment: "left" }),
    props.leftLineProps ?? {}
  )
  const rightLinealLineProps = mergeLinealLineProps(
    getDefaultLineProps({ orientation: "vertical", alignment: "right" }),
    props.rightLineProps ?? {}
  )
  const topLinealLineProps = mergeLinealLineProps(
    getDefaultLineProps({ orientation: "horizontal", alignment: "top" }),
    props.topLineProps ?? {}
  )
  const bottomLinealLineProps = mergeLinealLineProps(
    getDefaultLineProps({ orientation: "horizontal", alignment: "bottom" }),
    props.bottomLineProps ?? {}
  )

  const outerClassname = classNames(
    styles.root({
      color: props.styleApi?.color
    }),
    props.outerClassName
  )

  const contentClassname = classNames(
    styles.content,
    props.className
  )

  return React.createElement(props.as ?? "div", {
    ...props.outerProps,
    className: outerClassname,
    ref: props.outerRef,
  },
    <>
      <div style={{ borderRadius: radius }} className={styles.backdropPrimary({
        color: props.styleApi?.color
      })}></div>
      <div style={tlcStyle} className={styles.tlc} aria-hidden={true}></div>
      <LinealLine {...topLinealLineProps} className={styles.top} />
      <div style={trcStyle} className={styles.trc} aria-hidden={true}></div>
      <LinealLine {...rightLinealLineProps} className={styles.right} />
      <div style={brcStyle} className={styles.brc} aria-hidden={true}></div>
      <LinealLine {...bottomLinealLineProps} className={styles.bottom} />
      <div style={blcStyle} className={styles.blc} aria-hidden={true}></div>
      <LinealLine {...leftLinealLineProps} className={styles.left} />
      <div {...props.innerProps} className={contentClassname} ref={ref}>
        {props.children}
      </div>
    </>
  )
})

export const LinealBox = React.memo(_LinealBox)
