import * as styles from "./SingleViewLayout.css"

type Children = React.ReactChild | React.ReactChildren | undefined
export type SingleViewLayoutProps = { children: Children }
export const SingleViewLayout = (props: SingleViewLayoutProps) => {
  return (
    <div className={styles.root}>
      {props.children}
    </div>
  )
}