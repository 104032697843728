import * as styles from "./ColorRadioSquare.css"
import { useField } from "formik"
import { theme } from "../../../../theme.css"

export type ColorRadioSquareProps = {
  name: string,
  borderColor: string,
  backgroundColor: string,
  tabColor: string,
  colorName: string
}

export const ColorRadioSquare = (props: ColorRadioSquareProps) => {
  const [field, meta, helpers] = useField(props.name)
  const isChecked = meta.value === props.colorName
  return (
    <label className={styles.container} style={{
      backgroundColor: isChecked ? theme.colors.gray[2] : theme.colors.transparent,
      borderColor: isChecked ? theme.colors.gray[4] : theme.colors.transparent,
    }}>
      <div className={styles.root} style={{
        background: props.backgroundColor,
        borderColor: props.borderColor,
      }}>
        <div className={styles.tab} style={{
          background: props.tabColor
        }} />
        <input type="radio" {...field} value={props.colorName} className={styles.input} />
      </div>
    </label>
  )
}