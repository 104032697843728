import * as styles from "./ButtonWithIcon.css";
import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { Children, forwardRef } from "react";
import { Button, ButtonProps, Size } from "@dulce/design-system";

export type ButtonWithIconProps = ButtonProps & {
  icon: FontAwesomeIconProps["icon"];
} & styles.RootStyleParams;

// TODO: make the icon framework agnostic by accepting jsx
// Copy mantine's implementation (maybe with the original button in design system instead)
export const ButtonWithIcon = forwardRef(
  (props: ButtonWithIconProps, ref: any) => {
    const { icon, children, className, gap, ...buttonProps } = props;
    const classes = classNames(className, styles.root({ gap }));
    return (
      <Button {...buttonProps} className={classes} ref={ref}>
        <FontAwesomeIcon
          className={styles.icon({ size: buttonProps.size as Size })}
          icon={icon}
        />
        {!!Children.count(children) ? <span>{children}</span> : null}
      </Button>
    );
  }
);
