import { resizeCaptureElements } from "./resizeCaptureElements";

export type CaptureStreamFromCanvasOpts = {
  html: HTMLElement;
  canvas: HTMLCanvasElement;
  video: HTMLVideoElement;
  onLoadedMetadata: (event: Event) => void;
};
export const captureStreamFromCanvas = async (
  opts: CaptureStreamFromCanvasOpts
) => {
  const { video, canvas, onLoadedMetadata, html } = opts;
  resizeCaptureElements({ html, canvas, video });
  const stream = await canvas.captureStream(60);
  video.addEventListener("loadedmetadata", onLoadedMetadata);
  video.srcObject = stream;
};
