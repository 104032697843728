import { O, pipe } from "@dulce/prelude";
import { clamp } from "lodash/fp";

// https://developer.mozilla.org/en-US/docs/Web/API/Web_Speech_API/Using_the_Web_Speech_API#browser_support_2

export type SpeakOpts = Partial<
  Pick<SpeechSynthesisUtterance, "lang" | "pitch" | "rate" | "volume">
> & {
  text: string;
  synth?: SpeechSynthesis;
};

export const speak = (opts: SpeakOpts) => {
  const synth = opts.synth ?? window?.speechSynthesis;
  const pitch = pipe(
    opts.pitch,
    O.fromNullable,
    O.map(clamp(0, 2)),
    O.getOrElse(() => 1)
  );
  const rate = pipe(
    opts.rate,
    O.fromNullable,
    O.map(clamp(0.1, 10)),
    O.getOrElse(() => 1)
  );
  const volume = pipe(
    opts.volume,
    O.fromNullable,
    O.map(clamp(0, 1)),
    O.getOrElse(() => 1)
  );
  const lang = opts.lang ?? "en";
  const voices = synth.getVoices();
  const voice = voices.find((v) => v.lang.includes(lang));
  if (voice) {
    const utterance = new SpeechSynthesisUtterance(opts.text);
    utterance.voice = voice;
    utterance.volume = volume;
    utterance.rate = rate;
    utterance.pitch = pitch;
    utterance.lang = lang;
    synth.speak(utterance);
  }
};

(window as any).speak = speak;
