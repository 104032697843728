import { ThirdPartyCalendarEvent } from "@dulce/models/dist/third-party-calendars.models";
import { FPArray, pipe, TE, R, flow } from "@dulce/prelude";
import { TEtryCatchK } from "../../TEtryCatchK";
import { getGoogleApiClient } from "../helpers/getGoogleApiClient";
import { setHours, setMinutes, formatRFC3339 } from "date-fns/fp";
import {
  ThirdPartyCalendarUtils,
  ThirdPartyCalendarWithoutIdOrOwnerId,
} from "../helpers/ThirdPartyCalendarUtils";
import {
  GoogleCalendarEventUtils,
  ThirdPartyCalendarEventUtils,
} from "../helpers/ThirdPartyCalendarEventUtils";

const getCalendars = () => (gapi: typeof window.gapi) => {
  return new Promise<gapi.client.calendar.CalendarList>((resolve, reject) => {
    gapi.client.calendar.calendarList
      .list()
      .then((data) => resolve(data.result))
      .catch(reject);
  });
};

const getCalendarEventsList =
  (calendarId: string, date = new Date()) =>
  (gapi: typeof window.gapi) => {
    return new Promise<gapi.client.calendar.Events>((resolve, reject) => {
      gapi.client.calendar.events
        .list({
          calendarId: calendarId,
          timeMin: pipe(date, setHours(0), setMinutes(0), formatRFC3339),
          timeMax: pipe(date, setHours(23), setMinutes(59), formatRFC3339),
        })
        .then((data) => resolve(data.result))
        .catch(reject);
    });
  };

const ThirdPartyCalendarList = {
  fromCalendarList: (data: gapi.client.calendar.CalendarList) =>
    pipe(data.items, FPArray.map(ThirdPartyCalendarUtils.fromGoogleCalendar)),
};

const ThirdPartyCalendarEventList = {
  fromEventList: (events: gapi.client.calendar.Events) =>
    pipe(
      events.items,
      FPArray.filter(
        R.fromOptionK(GoogleCalendarEventUtils.hasStartAndEndDateTimes)
      ),
      FPArray.map(ThirdPartyCalendarEventUtils.fromGoogleCalendarEvent)
    ),
};

const fetchAndAddEventsToThirdPartyCalendar = (
  calendar: ThirdPartyCalendarWithoutIdOrOwnerId,
  date = new Date()
) =>
  pipe(
    undefined,
    TEtryCatchK(getGoogleApiClient),
    TE.chain(TEtryCatchK(getCalendarEventsList(calendar.calendarId, date))),
    TE.map(ThirdPartyCalendarEventList.fromEventList),
    TE.map(ThirdPartyCalendarUtils.updateCachedEvents(calendar))
  );

export const getGoogleCalendarsByDate = (date = new Date()) => {
  console.log("date", date);
  let workflow = pipe(
    undefined,
    TEtryCatchK(() => getGoogleApiClient()),
    TE.chain(TEtryCatchK(getCalendars())),
    TE.map(ThirdPartyCalendarList.fromCalendarList),
    TE.map(
      flow(
        FPArray.map((calendar) =>
          fetchAndAddEventsToThirdPartyCalendar(calendar, date)
        ),
        TE.sequenceArray
      )
    ),
    TE.flatten
  );
  return workflow();
};
