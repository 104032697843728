import { flow, FPArray, pipe } from "@dulce/prelude";
import { calc } from "@vanilla-extract/css-utils";
import classNames from "classnames";
import { useContext, useEffect, useRef } from "react";
import { TimeboxQueryContext } from "../../../../context/TimeboxContext";
import { theme } from "../../../../theme.css";
import * as styles from "./TimeMarkersLayer.css";
import { sdk } from "../../../../sdk";
import { useInterval } from "react-use";
import { useValue } from "../../../../utils/useValue";
import {
  faBolt,
  faBoltLightning,
  faMoon,
  faPlay,
  faSun,
} from "@fortawesome/free-solid-svg-icons";
import { TimeboxCursor } from "./TimeboxCursor/TimeboxCursor";
import { SunCalcContext } from "../../../../context/SunCalcContext";

type GetDeltaFromTopOpts = {
  startTime: number;
  stepSize: number;
  topOffset: typeof theme.space["0"];
};
const getDeltaFromTop =
  ({ startTime, stepSize, topOffset }: GetDeltaFromTopOpts) =>
  (time: number) => {
    return pipe(
      time - startTime,
      (delta) => delta / stepSize,
      (steps) =>
        calc(theme.space[3])
          .multiply(3)
          .multiply(steps)
          .add(topOffset)
          .toString()
    );
  };

export type TimeMarkersLayerProps = {};
export const TimeMarkersLayer = (props: TimeMarkersLayerProps) => {
  const layerRef = useRef<HTMLDivElement>(null);
  const activeTop = useValue<string>("0px");
  const timeboxContext = useContext(TimeboxQueryContext);
  const sunCalcContext = useContext(SunCalcContext);
  const trackInfo = timeboxContext?.getTimeboxTrackInfo();
  const topOffset = theme.space[2];
  const stepSize = trackInfo?.stepSize ?? 10;
  const totalNumberOfMarkers = pipe(
    trackInfo?.duration ?? 0,
    (duration) => duration / stepSize
  );

  const sunriseTop = useValue<string>("0px");
  const sunsetTop = useValue<string>("0px");

  useEffect(() => {
    if (timeboxContext) {
      const { startTime, stepSize } = timeboxContext.getTimeboxTrackInfo();
      const getFromTop = flow(
        sdk.utils.time.timeToMinutes,
        getDeltaFromTop({ startTime, stepSize, topOffset })
      );
      sunriseTop.setValue(getFromTop(sunCalcContext.sunrise));
      sunsetTop.setValue(getFromTop(sunCalcContext.sunset));
    }
  }, [timeboxContext, sunCalcContext]);

  useInterval(() => {
    if (timeboxContext) {
      const { startTime, currentTime, stepSize } =
        timeboxContext.getTimeboxTrackInfo();
      const getFromTop = getDeltaFromTop({ startTime, stepSize, topOffset });
      const value = getFromTop(currentTime);
      activeTop.setValue(value);
    }
  }, 100);

  // subtracting 45 minutes to denote when a task should start instead
  const energyMarkers = pipe(
    [
      sdk.utils.time.hoursToMinutes(8) + 15 - 45, // morning peak
      sdk.utils.time.hoursToMinutes(11) + 30 - 45, // noon peak
      sdk.utils.time.hoursToMinutes(14) + 30 - 45, // afternoon peak
      sdk.utils.time.hoursToMinutes(17) + 45 - 45, // dinner time peak
      sdk.utils.time.hoursToMinutes(19) + 45 - 45, // intamcy peak
    ],
    FPArray.map((minutes) => {
      const startTime = trackInfo?.startTime ?? 0;
      const getTop = getDeltaFromTop({
        startTime,
        stepSize,
        topOffset,
      });
      return (
        <TimeboxCursor
          key={getTop(minutes)}
          icon={faBolt}
          color="green"
          top={getTop(minutes)}
        />
      );
    })
  );

  // TODO: can be memoized
  const markers = pipe(
    new Array(totalNumberOfMarkers).fill(""),
    FPArray.mapWithIndex((index) => {
      const startTime = pipe(
        trackInfo?.startTime ?? 0,
        (time) => time + stepSize * index
      );
      const label = pipe(startTime, sdk.utils.time.getFormattedTimeFromMinutes);
      const isTopOfHour = pipe(
        startTime,
        (v) => v % 60,
        (v) => !v
      );
      const isMiddleOfHour = pipe(
        startTime,
        (v) => v % 60,
        (v) => v === 30
      );
      return (
        <div
          key={startTime}
          className={styles.markerContainer}
          style={{
            top: calc(theme.space[3])
              .multiply(index * 3)
              .add(topOffset)
              .toString(),
          }}
        >
          <span
            className={classNames(styles.timeOfDay, {
              [styles.topOfHour]: isTopOfHour,
              [styles.middleOfHour]: isMiddleOfHour,
            })}
          >
            {label}
          </span>
          <div className={styles.marker} />
        </div>
      );
    })
  );

  const isActiveCursorShowing = pipe(
    trackInfo?.isPastTimeboxEnd || trackInfo?.isBeforeTimeboxStart,
    (b) => !b
  );

  return (
    <div className={styles.root} ref={layerRef}>
      {markers}
      {isActiveCursorShowing && (
        <TimeboxCursor icon={faPlay} color="red" top={activeTop.value} />
      )}
      {!sunCalcContext.isLoading && (
        <>
          <TimeboxCursor icon={faSun} color="sunrise" top={sunriseTop.value} />
          <TimeboxCursor icon={faMoon} color="sunset" top={sunsetTop.value} />
        </>
      )}
      {energyMarkers}
    </div>
  );
};

