import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { toast } from "react-hot-toast"

export const handleOnError = (error: Error) => {
  toast.error(error?.message, {
    icon: <FontAwesomeIcon icon={faTriangleExclamation} />,
    duration: 2000
  })
}
