import { sdk } from "../sdk";
import { handleOnError } from "./utils";
import { useQuery, useQueryClient, useMutation } from "@tanstack/react-query";
import { toast } from "react-hot-toast";

const thirdPartyCalendarsQueryKey = ["third-party-calendars"];

export const useThirdPartyCalendars = () => {
  const thirdPartyCalendars = useQuery(
    thirdPartyCalendarsQueryKey,
    sdk.thirdPartyCalendars.queries.findMyCalendars,
    {
      onError: handleOnError,
    }
  );
  return {
    thirdPartyCalendars,
  };
};

export const useThirdPartyCalendarMutations = () => {
  const queryClient = useQueryClient();
  return {
    upsertCalendar: useMutation({
      mutationFn: sdk.thirdPartyCalendars.commands.upsertCalendar,
      onError: handleOnError,
      onSuccess: () => {
        queryClient.invalidateQueries(thirdPartyCalendarsQueryKey);
        toast.success("Calendar added");
      },
    }),
    removeCalendar: useMutation({
      mutationFn: sdk.thirdPartyCalendars.commands.removeCalendar,
      onError: handleOnError,
      onSuccess: () => {
        queryClient.invalidateQueries(thirdPartyCalendarsQueryKey);
        toast.success("Removed calendar");
      },
    }),
  };
};
