import { config } from "../../config";
import { authAxios } from "../../utils/authAxios";
import { ThirdPartyCalendar } from "@dulce/models/dist/third-party-calendars.models";

const baseUrl = config.api.url;

export const findMyCalendars = async () =>
  authAxios
    .request<Array<ThirdPartyCalendar>>({
      method: "GET",
      url: `${baseUrl}/third-party-calendars/find-my-calendars`,
    })
    .then((res) => res.data);

