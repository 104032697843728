import { faPlus, faSync } from "@fortawesome/free-solid-svg-icons";
import { ButtonWithIcon } from "../../foundation/ButtonWithIcon/ButtonWithIcon";
import * as styles from "./ThirdPartyCalendars.css";
import { close, open } from "../../foundation/Modal/useModal";
import { Flex, Size, Text, theme } from "@dulce/design-system";
import { ModalContent } from "../../foundation/Modal/Modal";
import {
  useThirdPartyCalendarMutations,
  useThirdPartyCalendars,
} from "../../hooks/useThirdPartyCalendars";
import { useMutation } from "@tanstack/react-query";
import { handleOnError } from "../../hooks/utils";
import { sdk } from "../../sdk";
import { Boolean, E, FPArray, O, pipe, TE } from "@dulce/prelude";
import { useFormik, FormikProvider } from "formik";
import { Toggle } from "../../foundation/Toggle/Toggle";
import { handleErrorReason } from "../../sdk/integrations/handleErrorReason";
import { useValue } from "../../utils/useValue";
import { MouseEventHandler } from "react";
import { faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import { formatDistance, parseISO } from "date-fns/fp";
import { ThirdPartyCalendarEventId } from "@dulce/models/dist/third-party-calendars.models";
import { ThirdPartyCalendarEventDetails } from "./ThirdPartyCalendarEventDetails/ThirdPartyCalendarEventDetails";
import { format, parse } from "date-fns";

export type ThirdPartyCalendarsProps = {};

export const ThirdPartyCalendars = (props: ThirdPartyCalendarsProps) => {
  const { thirdPartyCalendars } = useThirdPartyCalendars();
  const { upsertCalendar, removeCalendar } = useThirdPartyCalendarMutations();

  const handleLinkMutation = useMutation({
    mutationFn: async (dateString: string) => {
      const date = dateString
        ? parse(dateString, "yyyy-MM-dd", new Date())
        : new Date();
      await sdk.integrations.google.commands.authenticateToGoogle();
      let calendars =
        await sdk.integrations.google.queries.getGoogleCalendarsByDate(date);
      pipe(
        calendars,
        E.getOrElseW(() => [])
      ).forEach((calendar) => {
        upsertCalendar.mutate(calendar);
      });
    },
    onError: handleOnError,
  });

  const isSubmitting = useValue(false);
  const selectedDate = useValue(format(new Date(), "yyyy-MM-dd"));

  const formik = useFormik<Record<string, boolean>>({
    initialValues: pipe(
      thirdPartyCalendars.data ?? [],
      FPArray.reduce({}, (prev, calendar) => {
        return {
          ...prev,
          [calendar.id]: calendar.isEnabled,
        };
      })
    ),
    onSubmit: async (values) => {
      const pipeline = pipe(
        values,
        Object.keys,
        FPArray.map((key) =>
          pipe(
            thirdPartyCalendars.data ?? [],
            FPArray.findFirst((calendar) => calendar.id === key),
            O.map((calendar) => ({
              ...calendar,
              isEnabled: values[key],
            }))
          )
        ),
        FPArray.map((updated) =>
          pipe(
            updated,
            TE.fromOption(() => new Error("Not found")),
            TE.chain(
              TE.tryCatchK(upsertCalendar.mutateAsync, handleErrorReason)
            )
          )
        ),
        TE.sequenceArray
      );
      isSubmitting.setValue(true);
      await pipeline();
      isSubmitting.setValue(false);
    },
  });

  const handleSyncCalendar: MouseEventHandler<HTMLButtonElement> = (ev) => {
    ev.preventDefault();
    handleLinkMutation.mutate(selectedDate.value);
  };
  const handleRemoveCalendar =
    (id: string): MouseEventHandler<HTMLButtonElement> =>
    (ev) => {
      ev.preventDefault();
      removeCalendar.mutate({ id });
    };

  return (
    <FormikProvider value={formik}>
      <form onSubmit={formik.handleSubmit}>
        <ModalContent
          content={
            <div className={styles.root}>
              <Flex column alignItems="flex-start" gap={Size.md}>
                <Text italic>
                  Synced calendars only show events on the day they were synced
                  on. Resync to refresh the events for the day.
                </Text>
                <input
                  type="date"
                  value={selectedDate.value}
                  onChange={(e) => selectedDate.setValue(e.target.value)}
                />
                <Flex column gap={Size.sm}>
                  {pipe(
                    thirdPartyCalendars.data ?? [],
                    FPArray.matchW(
                      () => <Text>Nothing synced yet...</Text>,
                      FPArray.map((value) => {
                        const label = `${value.title} - (last synced ${pipe(
                          value.lastSynced,
                          parseISO,
                          formatDistance(new Date())
                        )} ago)`;
                        return (
                          <Flex
                            alignItems="center"
                            gap={Size.sm}
                            key={value.calendarId}
                          >
                            <Toggle label={label} name={value.id} />
                            <ButtonWithIcon
                              icon={faSync}
                              onClick={handleSyncCalendar}
                              size={Size.sm}
                            />
                            <ButtonWithIcon
                              icon={faTrashAlt}
                              onClick={handleRemoveCalendar(value.id)}
                              size={Size.sm}
                              style={{ color: theme.colors.red[6] }}
                            />
                          </Flex>
                        );
                      })
                    )
                  )}
                </Flex>
                <ButtonWithIcon
                  color="secondary"
                  icon={faPlus}
                  size={Size.md}
                  onClick={() => handleLinkMutation.mutate(selectedDate.value)}
                >
                  Sync calendar
                </ButtonWithIcon>
              </Flex>
            </div>
          }
          footer={
            <Flex justifyContent="flex-end">
              <ButtonWithIcon type="submit" icon={faSync} color="primary">
                {pipe(
                  isSubmitting.value,
                  Boolean.match(
                    () => "Save",
                    () => "Saving..."
                  )
                )}
              </ButtonWithIcon>
            </Flex>
          }
        />
      </form>
    </FormikProvider>
  );
};

export const openThirdPartyCalendarsModal = () => {
  open({
    title: "Synced Calendars",
    maxHeight: Size.md,
    onRequestClose: () => {
      close();
    },
    children: (
      <>
        <ThirdPartyCalendars />
      </>
    ),
  });
};

export const openThirdPartyCalendarEventDetails = (
  id: ThirdPartyCalendarEventId
) => {
  open({
    title: "Event Details",
    maxHeight: Size.md,
    onRequestClose: () => {
      close();
    },
    children: (
      <>
        <ThirdPartyCalendarEventDetails id={id} />
      </>
    ),
  });
};
