import "@dulce/design-system/dist/index.esm.css";

import reportWebVitals from "./reportWebVitals";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import ReactModal from "react-modal";
import { BrowserRouter as Router } from "react-router-dom";
import SuperTokens, { SuperTokensWrapper } from "supertokens-auth-react";
import ThirdPartyPasswordless, {
  Github,
  Google,
} from "supertokens-auth-react/recipe/thirdpartypasswordless";
import Session from "supertokens-auth-react/recipe/session";
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { TimeboxProvider } from "./context/TimeboxContext";
import { config } from "./config";
import { Helmet } from "react-helmet";
import { persistQueryClient } from "@tanstack/react-query-persist-client";
import { createSyncStoragePersister } from "@tanstack/query-sync-storage-persister";
import { TaskListFilterProvider } from "./components/TaskList/FilterContext";
import { AppContextProvider } from "./AppContext";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import { ConfettiContextProvider } from "./confetti/ConfettiContext";
import { SuggestedTasksContextProvider } from "./context/SuggestedTasksContext";
import { IntervalTimerContextProvider } from "./context/IntervalTimerContext";
import { FocusBoxProvider } from "./components/FocusBox/FocusBoxContext";
import { SunCalcContextProvider } from "./context/SunCalcContext";
import { Toaster, ToastBar, ToastType } from "react-hot-toast";
import { LinealBoxProps } from "./foundation/LinealBox/LinealBox";
import { ContextMenu } from "./foundation/ContextMenu/ContextMenu";
import { Modal } from "./foundation/Modal/Modal";
import { Size, theme, Flex, LinealBoxLite } from "@dulce/design-system";
import { EmojiMenu } from "./foundation/EmojiMenu/EmojiMenu";

const getMillisecondsFromHours = (value: number) => 1000 * 60 * 60 * value;

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: getMillisecondsFromHours(24),
    },
  },
});

const persister = createSyncStoragePersister({ storage: window.localStorage });
persistQueryClient({ queryClient, persister });

SuperTokens.init({
  appInfo: {
    ...config.supertokens,
  },
  recipeList: [
    ThirdPartyPasswordless.init({
      contactMethod: "EMAIL",
      useShadowDom: false,
      getRedirectionURL: async (context) => {
        if (context.action === "SUCCESS") {
          if (context.redirectToPath !== undefined) {
            // we are navigating back to where the user was before they authenticated
            return context.redirectToPath;
          }
          return "/";
        }
        return "/auth";
      },
      signInUpFeature: {
        disableDefaultUI: false,
        providers: [Github.init(), Google.init()],
      },
    }),
    Session.init(),
  ],
});

const Entrypoint = () => {
  return (
    <React.StrictMode>
      <SuperTokensWrapper>
        <QueryClientProvider client={queryClient}>
          <DndProvider backend={HTML5Backend}>
            <SuggestedTasksContextProvider>
              <TimeboxProvider>
                <AppContextProvider>
                  <SunCalcContextProvider>
                    <IntervalTimerContextProvider>
                      <ConfettiContextProvider>
                        <TaskListFilterProvider>
                          <FocusBoxProvider>
                            <Router>
                              <React.Fragment>
                                <Helmet>
                                  <title>dulce.gg</title>
                                </Helmet>
                                <App />
                                <ContextMenu />
                                <EmojiMenu />
                                <Modal />
                                <Toaster
                                  gutter={0}
                                  toastOptions={{
                                    className: "",
                                    duration: 2000,
                                    position: "top-center",
                                    style: {
                                      background: theme.colors.transparent,
                                    },
                                  }}
                                >
                                  {(t) => (
                                    <ToastBar
                                      toast={t}
                                      style={{
                                        padding: theme.space[1],
                                        background: theme.colors.transparent,
                                        borderColor: theme.colors.transparent,
                                        boxShadow: "none",
                                      }}
                                    >
                                      {(props) => {
                                        const styleMap: Record<
                                          ToastType,
                                          LinealBoxProps["styleApi"]
                                        > = {
                                          success: { color: "green" },
                                          blank: { color: "gray" },
                                          error: { color: "red" },
                                          custom: { color: "gray" },
                                          loading: { color: "gray" },
                                        };
                                        return (
                                          <LinealBoxLite
                                            styleApi={styleMap[t.type]}
                                          >
                                            <Flex
                                              gap={Size.sm}
                                              style={{
                                                padding: `${theme.space[1]} ${theme.space[2]}`,
                                              }}
                                              alignItems="center"
                                            >
                                              {props.icon}
                                              {props.message}
                                            </Flex>
                                          </LinealBoxLite>
                                        );
                                      }}
                                    </ToastBar>
                                  )}
                                </Toaster>
                              </React.Fragment>
                            </Router>
                          </FocusBoxProvider>
                        </TaskListFilterProvider>
                      </ConfettiContextProvider>
                    </IntervalTimerContextProvider>
                  </SunCalcContextProvider>
                </AppContextProvider>
              </TimeboxProvider>
            </SuggestedTasksContextProvider>
          </DndProvider>
        </QueryClientProvider>
      </SuperTokensWrapper>
    </React.StrictMode>
  );
};

ReactModal.setAppElement("#root");
ReactDOM.render(<Entrypoint />, document.getElementById("root"));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
serviceWorkerRegistration.register();
