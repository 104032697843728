import { ButtonHTMLAttributes, forwardRef } from "react";
import * as styles from "./Button.css";
import classNames from "classnames";

export type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> &
  Parameters<typeof styles.root>[0];
export const Button = forwardRef((props: ButtonProps, ref: any) => {
  const { children, className, color, rounded, size, outline, ...rest } = props;
  const classnames = classNames(
    styles.root({
      color,
      rounded,
      size,
      outline,
    }),
    className
  );

  return (
    <button ref={ref} className={classnames} {...rest}>
      {children}
    </button>
  );
});
