import {
  RemoveThirdPartyCalendarDto,
  ThirdPartyCalendar,
  UpsertThirdPartyCalendarDto,
} from "@dulce/models/dist/third-party-calendars.models";
import { config } from "../../config";
import { authAxios } from "../../utils/authAxios";

const baseUrl = config.api.url;

export const upsertCalendar = async (dto: UpsertThirdPartyCalendarDto) =>
  authAxios
    .request<ThirdPartyCalendar>({
      method: "POST",
      url: `${baseUrl}/third-party-calendars/upsert-calendar`,
      data: dto,
    })
    .then((res) => res.data);

export const removeCalendar = async (dto: RemoveThirdPartyCalendarDto) =>
  authAxios
    .request<ThirdPartyCalendar>({
      method: "POST",
      url: `${baseUrl}/third-party-calendars/remove-calendar`,
      data: dto,
    })
    .then((res) => res.data);
