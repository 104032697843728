import { Size } from "@dulce/design-system"
import { CreateTaskForm } from "../../../components/CreateTaskForm/CreateTaskForm"
import * as modal from "../useModal"

export const openCreateTaskModal = () => {
  modal.open({
    title: "CreateTask",
    maxHeight: Size.xl,
    onRequestClose: () => {
      modal.close()
    },
    children: <>
      <CreateTaskForm
        onClose={() =>
          modal.close()
        }
      />
    </>
  })
}