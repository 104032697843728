import { createContext, ReactChild, ReactChildren } from "react";
import { useGeolocation } from "react-use";
import SunCalc from "suncalc";

export type SunCalcContextValue = {
  isLoading: boolean;
  latitude: number;
  longitude: number;
  sunrise: Date;
  sunset: Date;
};
export const initValue: SunCalcContextValue = {
  isLoading: true,
  latitude: 0,
  longitude: 0,
  sunrise: new Date(),
  sunset: new Date(),
};
export const SunCalcContext = createContext<SunCalcContextValue>(initValue);

type SunCalcContextProviderProps = {
  children: ReactChild | ReactChildren;
};

export const SunCalcContextProvider = ({
  children,
}: SunCalcContextProviderProps) => {
  const { longitude, loading: isLoading, latitude } = useGeolocation();
  let value: SunCalcContextValue = {
    ...initValue,
    isLoading,
  };
  if (latitude && longitude && !isLoading) {
    const { sunriseEnd, sunsetStart } = SunCalc.getTimes(
      new Date(),
      latitude,
      longitude
    );
    value = {
      ...value,
      latitude,
      longitude,
      sunrise: sunriseEnd,
      sunset: sunsetStart,
    };
  }
  return (
    <SunCalcContext.Provider value={value}>{children}</SunCalcContext.Provider>
  );
};

