import { pipe } from "@dulce/prelude"
import { useState } from "react"
import { useInterval } from "react-use"
import { sdk } from "../../sdk"
import * as styles from "./CurrentTime.css"

export type CurrentTimeProps = {
  preventBlink?: boolean
}
export const CurrentTime = (props: CurrentTimeProps) => {

  const { preventBlink = false } = props

  const [minutes, setMinutes] = useState<string>("00")
  const [hour, setHour] = useState<string>("00")
  const [seconds, setSeconds] = useState<number>(0)
  const [meridian, setMeridian] = useState<"AM" | "PM">("AM")

  useInterval(() => {
    const date = new Date()
    setHour(() => pipe(
      date.getHours(),
      hour => hour > 12 ? hour - 12 : hour,
      hour => hour === 0 ? 12 : hour,
      sdk.utils.time.timePadNumber
    ))
    setMinutes(() => pipe(
      date.getMinutes(),
      sdk.utils.time.timePadNumber
    ))
    setMeridian(() => pipe(
      date.getHours(),
      sdk.utils.time.getMeridianFromHour
    ))
    setSeconds(() => pipe(
      date.getSeconds(),
    ))
  }, 1000)

  return (
    <div className={styles.root}>
      <span className={styles.time}>
        <span>{hour}</span>
        {
          preventBlink ? (
            <span style={{ marginTop: "-1px" }}>:</span>
          ) : (
            <span style={{ opacity: seconds % 2 === 0 ? 1 : 0, marginTop: "-1px" }}>:</span>
          )
        }
        <span>{minutes}</span>
      </span>
      <span className={styles.meridian}>
        {meridian}
      </span>
    </div>
  )
}