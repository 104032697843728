import * as FunctionUtils from "fp-ts/lib/function";
import * as Either from "fp-ts/Either";
import * as TaskEither from "fp-ts/TaskEither";
import { Decoder } from "io-ts";
import { failure } from "io-ts/PathReporter";

export * as A from "fp-ts/Apply";
export * as dt from "io-ts-types";
export * as FPArray from "fp-ts/Array";
export * as ID from "fp-ts/Identity";
export * as N from "fp-ts/number";
export * as O from "fp-ts/Option";
export * as Ord from "fp-ts/Ord";
export * as t from "io-ts";
export * as TO from "fp-ts/TaskOption";
export * as T from "fp-ts/Task";
export * as Boolean from "fp-ts/boolean";
export * as ct from "./customTypes";
export * as TE from "fp-ts/TaskEither";
export * as E from "fp-ts/Either";
export * as R from "fp-ts/Refinement";
export { pipe, flow } from "fp-ts/lib/function";

export const decodeWith =
  <A>(decoder: Decoder<unknown, A>) =>
  (response: unknown) => {
    return FunctionUtils.pipe(
      response,
      decoder.decode,
      Either.mapLeft((errors) => {
        let message = failure(errors).join("\n");
        return new Error(message);
      })
    );
  };

export const decodeFromTaskWith = <A>(decoder: Decoder<unknown, A>) =>
  FunctionUtils.flow(
    TaskEither.fromTask,
    TaskEither.chain(
      FunctionUtils.flow(decodeWith(decoder), TaskEither.fromEither)
    )
  );
