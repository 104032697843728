import { Size } from "@dulce/design-system"
import { close, open } from "../../../foundation/Modal/useModal"
import { InboxFilterForm } from "./InboxFilterForm"

export const openFilterModal = () => {
  open({
    title: "Inbox Filter Form",
    onRequestClose: close,
    maxHeight: Size.md,
    children: <InboxFilterForm />
  })
}