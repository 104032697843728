import * as styles from "./ActionLayer.css";
import { Boolean, FPArray, pipe } from "@dulce/prelude";
import create from "zustand";
import { sdk } from "../../../../sdk";
import { Minutes, Task } from "@dulce/models/dist/tasks.models";
import { Fragment, useContext, useMemo } from "react";
import { TimeboxQueryContext } from "../../../../context/TimeboxContext";
import { calc } from "@vanilla-extract/css-utils";
import { SuggestedTasksQueryContext } from "../../../../context/SuggestedTasksContext";
import { ModalContent } from "../../../../foundation/Modal/Modal";
import { useTasks, useTasksMutations } from "../../../../hooks/useTasks";
import { AppContext } from "../../../../AppContext";
import { QuickAddForm } from "../../../TaskList/QuickAddForm/QuickAddForm";
import { fuzzySearch } from "../../../TaskList/TaskList";
import { Paragraph } from "../../../EditTaskForm/EditTaskForm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHandPeace,
  faHeadphones,
  faLock,
  faMagicWandSparkles,
  faRecycle,
} from "@fortawesome/free-solid-svg-icons";
import {
  Text,
  Flex,
  theme,
  Size,
  Button,
  LinealBoxLite,
} from "@dulce/design-system";
import { close, open } from "../../../../foundation/Modal/useModal";
import { openCreateTaskModal } from "../../../../foundation/Modal/modals/openCreateTaskModal";
import { ButtonWithIcon } from "../../../../foundation/ButtonWithIcon/ButtonWithIcon";
import {
  faSquareFull,
  faSquarePlus,
} from "@fortawesome/free-regular-svg-icons";

export const useActionLayerStore = create((set: any) => {
  return {
    // state
    searchText: "",
    // mutations
    setSearchText: (value: string) =>
      set((state: any) => ({ searchText: value })),
  };
});

export type ActionLayerProps = {};
export const ActionLayer = (props: ActionLayerProps) => {
  const suggestedTasksContext = useContext(SuggestedTasksQueryContext);
  const timeboxQueryContext = useContext(TimeboxQueryContext);
  const trackInfo = timeboxQueryContext?.getTimeboxTrackInfo();
  const topOffset = theme.space[2];
  const stepSize = trackInfo?.stepSize ?? 10;
  const appContext = useContext(AppContext);
  const totalNumberOfMarkers = pipe(
    trackInfo?.duration ?? 0,
    (duration) => duration / stepSize
  );
  const { addTaskMutation } = useTasksMutations();
  const actions = pipe(
    new Array(totalNumberOfMarkers).fill(""),
    FPArray.mapWithIndex((index) => {
      const startTime = pipe(
        trackInfo?.startTime ?? 0,
        (time) => time + stepSize * index
      );
      const isTimeSlotFilled =
        suggestedTasksContext?.isTimeSlotFilled(startTime);
      const buttons = !isTimeSlotFilled ? (
        <>
          {
            // <Button
            //   size={Size.sm}
            //   onClick={() => {
            //     addTaskMutation.mutate({
            //       color: "yellow",
            //       duration: 20,
            //       title: "Break (Ultradium Recovery)",
            //       details: Paragraph.fromStringOrCustomElements(""),
            //       startTime: startTime,
            //     });
            //   }}
            //   className={styles.suggestButton}
            //   key={`${startTime}_${index}__break-button`}
            // >
            //   Take a Break
            // </Button>
            //
            //
          }
          <ButtonWithIcon
            size={Size.sm}
            onClick={() => {
              openSmartSelectModal({
                selectedStartTime: startTime,
              });
            }}
            className={styles.suggestButton}
            key={`${startTime}_${index}__suggest-button`}
            icon={faMagicWandSparkles}
          >
            Magic Command
          </ButtonWithIcon>
        </>
      ) : (
        <Fragment key={`${startTime}_${index}__button`}></Fragment>
      );
      return (
        <div
          onDoubleClick={() => {
            appContext?.presetStartTime.setValue(startTime);
            openCreateTaskModal();
          }}
          key={`${startTime}_${index}`}
          style={{
            position: "absolute",
            top: pipe(
              trackInfo?.startTime ?? 0,
              (v) => (startTime ?? 0) - v,
              (delta) => delta / (trackInfo?.stepSize ?? 10),
              (steps) =>
                calc(theme.space[3])
                  .multiply(3)
                  .multiply(steps)
                  .add(topOffset)
                  .toString()
            ),
            height: pipe(
              10,
              (v) => v / (trackInfo?.stepSize ?? 10),
              (steps) =>
                calc(theme.space[3]).multiply(3).multiply(steps).toString()
            ),
            display: "flex",
            paddingTop: theme.space[1],
            paddingBottom: theme.space[1],
            gap: theme.space[1],
            width: "100%",
            justifyContent: "flex-end",
            userSelect: "none",
          }}
        >
          {buttons}
        </div>
      );
    })
  );

  const handleQuickAddFormSubmit = (value: string) => {
    appContext.initTaskTitle.setValue(value);
    openCreateTaskModal();
  };

  type OpenSmartSelectModalOpts = {
    selectedStartTime: Minutes;
  };
  const openSmartSelectModal = (opts: OpenSmartSelectModalOpts) => {
    appContext.presetStartTime.setValue(opts.selectedStartTime);
    open({
      title: "Magic Command",
      onRequestClose: close,
      maxHeight: Size.lg,
      children: (
        <SmartSelectModalContent
          selectedStartTime={opts.selectedStartTime}
          handleQuickAddFormSubmit={handleQuickAddFormSubmit}
        />
      ),
    });
  };

  return <div className={styles.root}>{actions}</div>;
};

export type SmartSelectModalContentProps = {
  handleQuickAddFormSubmit: (value: string) => void;
  selectedStartTime: Minutes;
};
export const SmartSelectModalContent = (
  props: SmartSelectModalContentProps
) => {
  const { handleQuickAddFormSubmit, selectedStartTime } = props;
  const { searchText, setSearchText } = useActionLayerStore();
  const { scheduleTaskMutation } = useTasksMutations();
  const tasksQuery = useTasks();
  const suggestedTasksContext = useContext(SuggestedTasksQueryContext);
  const unscheduledTasks = pipe(
    tasksQuery.data ?? [],
    sdk.tasks.queries.getUnscheduledTasks
  );
  const searchTasks = useMemo(
    () => fuzzySearch(unscheduledTasks),
    [unscheduledTasks]
  );
  const mapTaskToBoxItem = (task: Task) => {
    return (
      <LinealBoxLite
        className={styles.recommendedTask}
        radius={4}
        key={task.id}
        as="button"
        outerClassName={styles.buttonReset}
        outerProps={{
          onClick: () => {
            scheduleTaskMutation.mutateAsync({
              id: task.id,
              startTime: selectedStartTime,
            });
            close();
          },
        }}
        styleApi={{
          color: task.color as any,
        }}
      >
        <Flex justifyContent="space-between">
          <Flex gap={Size.sm}>
            <Text>{task.title}</Text>
          </Flex>
          <Flex gap={Size.sm}>
            {task.isDeepWork && (
              <Text size={Size.sm}>
                <FontAwesomeIcon icon={faHeadphones} />
              </Text>
            )}
            {task.isRecurring && (
              <Text size={Size.sm}>
                <FontAwesomeIcon icon={faRecycle} />
              </Text>
            )}
            {task.isLocked && (
              <Text size={Size.sm}>
                <FontAwesomeIcon icon={faLock} />
              </Text>
            )}
            <Text size={Size.sm}>{task.duration} min</Text>
          </Flex>
        </Flex>
      </LinealBoxLite>
    );
  };
  return (
    <>
      <ModalContent
        content={
          <>
            <Flex column gap={Size.lg}>
              <Text size={Size.md}>
                Time Slot:{" "}
                {sdk.utils.time.getFormattedTimeFromMinutes(selectedStartTime)}
              </Text>
              <QuickAddForm
                onChange={setSearchText}
                onSubmit={handleQuickAddFormSubmit}
              />
              <div
                style={{ borderBottom: `thin solid black`, width: "100%" }}
              />
              {searchText.length >= 2 ? (
                <>
                  <Text size={Size.md}>Found tasks</Text>
                  <div className={styles.recommendedTasks}>
                    {pipe(
                      unscheduledTasks,
                      (tasks) => {
                        const startSearchThreshold = 3;
                        return pipe(
                          searchText.length >= startSearchThreshold,
                          Boolean.match(
                            () => tasks,
                            () =>
                              pipe(
                                searchTasks(searchText),
                                FPArray.map((v) => v.item)
                              )
                          )
                        );
                      },
                      FPArray.map(mapTaskToBoxItem)
                    )}
                  </div>
                </>
              ) : (
                <>
                  <Text size={Size.md}>Suggested tasks</Text>
                  <div className={styles.recommendedTasks}>
                    {pipe(
                      props.selectedStartTime,
                      (v) =>
                        suggestedTasksContext?.getSuggestedTasksByStartTime(
                          v
                        ) ?? [],
                      FPArray.map(mapTaskToBoxItem)
                    )}
                  </div>
                </>
              )}
            </Flex>
          </>
        }
      />
    </>
  );
};
