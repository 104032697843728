import { pipe, TE } from "@dulce/prelude";
import { handleErrorReason } from "../../handleErrorReason";
import { getGoogleApiClient } from "../helpers/getGoogleApiClient";

export const authenticateToGoogle = () => {
  const workflow = pipe(
    TE.tryCatch(getGoogleApiClient, (reason) => new Error(String(reason))),
    TE.chain(
      TE.tryCatchK(
        (gapi) => gapi.auth2.getAuthInstance().signIn(),
        handleErrorReason
      )
    )
  );
  return workflow();
};
