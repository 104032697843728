import { Minutes, Task, TaskId } from "@dulce/models/dist/tasks.models";
import { N, Ord, pipe } from "@dulce/prelude";

export const getPrefixFromTaskTitle = (task?: Task): string | undefined =>
  task?.title?.includes(":") ? task?.title?.split?.(":")?.[0] : undefined;

export const tasksSortingStrategy = {
  byLocality: Ord.fromCompare<Task>((taskA, taskB) => {
    const localityA = taskA.locality?.toLowerCase();
    const localityB = taskB.locality?.toLowerCase();
    if (localityA && localityB) {
      return localityA < localityB ? -1 : 1;
    }
    if (localityA && !localityB) {
      return 1;
    }
    return -1;
  }),
  byStartTime: pipe(
    N.Ord,
    Ord.contramap(
      (task: Task | (Task & { startTime: Minutes })) =>
        task.startTime as Minutes
    )
  ),
  byPrefix: Ord.fromCompare<Task>((taskA, taskB) => {
    const prefixA = getPrefixFromTaskTitle(taskA);
    const prefixB = getPrefixFromTaskTitle(taskB);
    if (prefixA && prefixB) {
      return prefixA < prefixB ? -1 : 1;
    }
    if (prefixA && !prefixB) {
      return 1;
    }
    return -1;
  }),
  byColor: Ord.fromCompare<Task>((taskA, taskB) => {
    return taskA.color < taskB.color ? -1 : 1;
  }),
  byDuration: Ord.fromCompare<Task>((taskA, taskB) => {
    return taskA.duration < taskB.duration ? -1 : 1;
  }),
  byNone: Ord.fromCompare(() => -1),
};

export const isTask = (task: Task | undefined): task is Task => {
  return !!task;
};

export const hasStartTime = (
  task: Task
): task is Task & { startTime: Minutes } => {
  return !!task.startTime;
};

export const hasNoStartTime = (
  task: Task
): task is Task & { startTime: undefined } => {
  return !task.startTime;
};

