import { Size, Text } from "@dulce/design-system";
import { TaskId } from "@dulce/models/dist/tasks.models";
import { Boolean, pipe } from "@dulce/prelude";
import {
  faMagnifyingGlass,
  faPenToSquare,
  faSocks,
  faLock,
  faUnlock,
  faRecycle,
  faRadiation,
  faHeadphones,
  faTrashCan,
  IconDefinition,
  faAnchorLock,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ContextMenuOption } from "../../../foundation/ContextMenu/useContextMenu";
import { isCustomElement } from "../../../foundation/DulceEditor/helpers/isCustomElement";
import { useTask, useTasksMutations } from "../../../hooks/useTasks";
import { useTaskModals } from "../TaskModals";
import { getSchedulingOptions } from "./options/getSchedulingOptions";

export type UseContextMenuOptionsOpts = {
  primaryButtonIcon: () => IconDefinition;
  taskId: TaskId;
};

export const useContextMenuOptions = (
  opts: UseContextMenuOptionsOpts
): Array<ContextMenuOption> => {
  const { primaryButtonIcon, taskId } = opts;
  const {
    completeTaskMutation,
    uncompleteTaskMutation,
    duplicateTaskMutation,
    unscheduleTaskMutation,
    lockTaskMutation,
    superLockTaskMutation,
    unlockTaskMutation,
    enableRecurringTaskMutation,
    disableRecurringTaskMutation,
    updateTaskMutation,
  } = useTasksMutations();
  const taskModals = useTaskModals(taskId);
  const taskQuery = useTask(taskId);

  if (!taskQuery.data) {
    return [] as Array<ContextMenuOption>;
  }

  const task = taskQuery.data;

  const schedulingOptions = getSchedulingOptions({
    task,
    unscheduleTaskMutation,
    taskModals,
  });

  return pipe(
    [] as Array<ContextMenuOption>,
    // TODO: split options into logical groups in different files under the ./options folder
    (options) => [
      ...options,
      {
        content: (
          <Text size={Size.sm}>
            <FontAwesomeIcon icon={faMagnifyingGlass} /> View
          </Text>
        ),
        name: "view",
        onClick: taskModals.openTaskDetailsModal,
      },
      {
        content: (
          <Text size={Size.sm}>
            <FontAwesomeIcon icon={faPenToSquare} /> Edit
          </Text>
        ),
        name: "edit",
        onClick: taskModals.openEditTaskModal,
      },
    ],
    (options) => [
      ...options,
      task.status === "NOT_COMPLETED"
        ? {
            content: (
              <Text size={Size.sm}>
                <FontAwesomeIcon icon={primaryButtonIcon()} /> Complete
              </Text>
            ),
            name: "complete",
            onClick: () => completeTaskMutation.mutateAsync({ id: task.id }),
          }
        : {
            content: (
              <Text size={Size.sm}>
                <FontAwesomeIcon icon={primaryButtonIcon()} /> Uncomplete
              </Text>
            ),
            name: "uncomplete",
            onClick: () => uncompleteTaskMutation.mutateAsync({ id: task.id }),
          },
    ],
    (options) => [
      ...options,
      {
        content: (
          <Text size={Size.sm}>
            <FontAwesomeIcon icon={faSocks} /> Duplicate
          </Text>
        ),
        name: "duplicate",
        onClick: () => {
          const hasSubtask = !!task.details?.find((element) => {
            if (isCustomElement(element)) {
              if (element.type === "subtask") {
                return true;
              }
            }
            return false;
          });
          if (hasSubtask) {
            taskModals.openDuplcateTaskModal();
          } else {
            duplicateTaskMutation.mutateAsync({
              id: task.id,
              copyMinitaskState: false,
            });
          }
        },
      },
    ],
    (options) => [...options, ...schedulingOptions],
    (options) => [
      ...options,
      ...pipe(
        !!taskQuery.data?.isLocked || !!taskQuery.data?.isSuperLocked,
        Boolean.match(
          () => {
            return [
              {
                content: (
                  <Text size={Size.sm}>
                    <FontAwesomeIcon icon={faLock} /> Lock
                  </Text>
                ),
                name: "lock",
                onClick: () => lockTaskMutation.mutateAsync({ id: taskId }),
              },
              {
                content: (
                  <Text size={Size.sm}>
                    <FontAwesomeIcon icon={faAnchorLock} /> Super Lock
                  </Text>
                ),
                name: "super-lock",
                onClick: () =>
                  superLockTaskMutation.mutateAsync({ id: taskId }),
              },
            ];
          },
          () => {
            return [
              {
                content: (
                  <Text size={Size.sm}>
                    <FontAwesomeIcon icon={faUnlock} /> Unlock
                  </Text>
                ),
                name: "unlock",
                onClick: () => unlockTaskMutation.mutateAsync({ id: taskId }),
              },
            ];
          }
        )
      ),
    ],
    (options) => [
      ...options,
      !taskQuery.data?.isRecurring
        ? {
            content: (
              <Text size={Size.sm}>
                <FontAwesomeIcon icon={faRecycle} /> Enable Recurring
              </Text>
            ),
            name: "unlock",
            onClick: () =>
              enableRecurringTaskMutation.mutateAsync({ id: taskId }),
          }
        : {
            content: (
              <Text size={Size.sm}>
                <FontAwesomeIcon icon={faRadiation} /> Disable Recurring
              </Text>
            ),
            name: "unlock",
            onClick: () =>
              disableRecurringTaskMutation.mutateAsync({ id: taskId }),
          },
    ],
    (options) => [
      ...options,
      !taskQuery.data?.isDeepWork
        ? {
            content: (
              <Text size={Size.sm}>
                <FontAwesomeIcon icon={faHeadphones} /> Set focus to Deep
              </Text>
            ),
            name: "deep-work",
            onClick: () =>
              updateTaskMutation.mutateAsync({
                ...taskQuery.data,
                id: taskId,
                isDeepWork: true,
                startTime: taskQuery?.data?.startTime ?? undefined,
              }),
          }
        : {
            content: (
              <Text size={Size.sm}>
                <FontAwesomeIcon icon={faHeadphones} /> Set focus to Simple
              </Text>
            ),
            name: "deep-work",
            onClick: () =>
              updateTaskMutation.mutateAsync({
                ...taskQuery.data,
                id: taskId,
                isDeepWork: false,
                startTime: taskQuery?.data?.startTime ?? undefined,
              }),
          },
    ],
    (options) => [
      ...options,
      {
        content: (
          <Text size={Size.sm}>
            <FontAwesomeIcon icon={faTrashCan} /> Delete
          </Text>
        ),
        name: "delete",
        onClick: taskModals.openDeleteTaskModal,
        isDisabled: task.isRecurring,
      },
    ]
  );
};
