import { TaskId } from "@dulce/models/dist/tasks.models";
import {
  createContext,
  ReactChild,
  ReactChildren,
  useEffect,
  useMemo,
} from "react";
import { useMedia } from "react-use";
import { useValue, UseValueResult } from "./utils/useValue";

export enum ViewMode {
  splitInbox = "splitInbox",
  splitFocus = "splitFocus",
  timebox = "timebox",
  inbox = "inbox",
}

export type AppContextValue = {
  isCreateTaskFormOpen: UseValueResult<boolean>;
  presetStartTime: UseValueResult<number | undefined>;
  presetDuration: UseValueResult<number | undefined>;
  viewMode: UseValueResult<ViewMode>;
  isPushDownEnabled: UseValueResult<boolean>;
  isWide: boolean;
  tasksCoordCache: UseValueResult<Record<string, [number, number]>>;
  initTaskTitle: UseValueResult<string>;
  audioMap: Record<string, HTMLAudioElement>;
};

const noop = () => { };

export const initValue: AppContextValue = {
  isCreateTaskFormOpen: {
    value: false,
    setValue: noop,
  },
  presetStartTime: {
    value: undefined,
    setValue: noop,
  },
  presetDuration: {
    value: undefined,
    setValue: noop
  },
  viewMode: {
    value: ViewMode.splitInbox,
    setValue: noop,
  },
  isPushDownEnabled: {
    value: false,
    setValue: noop,
  },
  isWide: true,
  tasksCoordCache: {
    value: {},
    setValue: noop,
  },
  initTaskTitle: {
    value: "",
    setValue: noop,
  },
  audioMap: {},
};

export const AppContext = createContext<AppContextValue>(initValue);

type AppContextProvider = {
  children: ReactChild | ReactChildren;
};
export const AppContextProvider = ({ children }: AppContextProvider) => {
  const isCreateTaskFormOpen = useValue<boolean>(
    initValue.isCreateTaskFormOpen.value
  );
  const presetStartTime = useValue<number | undefined>(
    initValue.presetStartTime.value
  );
  const presetDuration = useValue<number | undefined>(
    initValue.presetDuration.value
  );
  const initTaskTitle = useValue<string>(initValue.initTaskTitle.value);
  const viewMode = useValue<ViewMode>(initValue.viewMode.value);
  const isPushDownEnabled = useValue<boolean>(
    initValue.isPushDownEnabled.value
  );
  const tasksCoordCache = useValue<Record<TaskId, [number, number]>>({});
  const audioMap = useMemo(
    () => ({
      low: new Audio("/blop-low.mp3"),
      high: new Audio("/blop-high.mp3"),
      higher: new Audio("/blop-higher.mp3"),
      normal: new Audio("/blop.mp3"),
    }),
    []
  );

  const isWide = useMedia("(min-width: 56rem)", initValue.isWide);
  useEffect(() => {
    if (isWide === false) {
      viewMode.setValue(ViewMode.timebox);
    } else {
      viewMode.setValue(ViewMode.splitInbox);
    }
  }, [isWide]);
  return (
    <AppContext.Provider
      value={{
        isCreateTaskFormOpen,
        presetStartTime,
        presetDuration,
        viewMode,
        isWide,
        isPushDownEnabled,
        tasksCoordCache,
        initTaskTitle,
        audioMap,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
