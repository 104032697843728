import { createStore } from "zustand/vanilla";
import { useStore } from "zustand";
import { RefObject } from "react";
import { noop } from "../../utils/noop";

export type UseEmojiMenuStoreValues = {
  top: number;
  left: number;
  isOpen: boolean;
  onEmojiSelect: (value: string) => void;
};

export const emojiMenuStore = createStore<UseEmojiMenuStoreValues>(() => {
  return {
    top: 0,
    left: 0,
    isOpen: false,
    onEmojiSelect: noop,
  };
});

const remToPx = (value: number) => value * 16;

export const useEmojiMenu = () => {
  const { top, left, isOpen } = useStore(emojiMenuStore);

  const open = (
    opts: Pick<UseEmojiMenuStoreValues, "left" | "top" | "onEmojiSelect">
  ) => {
    emojiMenuStore.setState({
      ...opts,
      isOpen: true,
    });
  };

  const close = () => {
    emojiMenuStore.setState({
      top: 0,
      left: 0,
      isOpen: false,
      onEmojiSelect: noop,
    });
  };

  type HandleClickOpts = {
    buttonRef: RefObject<HTMLButtonElement>;
    onEmojiSelect: UseEmojiMenuStoreValues["onEmojiSelect"];
  };

  const handleClick = (opts: HandleClickOpts) => () => {
    if (isOpen) {
      close();
    }
    const rect = opts.buttonRef.current?.getBoundingClientRect();
    const rectTop = rect?.top ?? 0;
    const rectHeight = rect?.height ?? 1;
    const rectLeft = rect?.left ?? 0;
    const rectWidth = rect?.width ?? 0;

    open({
      top: rectTop + rectHeight,
      left: rectLeft - remToPx(22) + rectWidth,
      onEmojiSelect: opts.onEmojiSelect,
    });
  };

  return {
    // queries
    top,
    left,
    isOpen,
    // commands
    open,
    close,
    handleClick,
  };
};
