
import { Text } from "@dulce/design-system"
import { Logo } from "../../components/Logo/Logo"
import * as styles from "./LazyLoadingIndicator.css"

export type LazyLoadingIndicatorProps = {}

export const LazyLoadingIndicator = (props: LazyLoadingIndicatorProps) => {
  return (
    <div className={styles.root}>
      <div className={styles.spinner}>
        <Logo color="purple" excludeRoot />
      </div>
      <Text>Loading...</Text>
    </div>
  )
}
