import { FPArray, pipe, TE } from "@dulce/prelude";
import { calc } from "@vanilla-extract/css-utils";
import { CSSProperties, useContext } from "react";
import { useDrop } from "react-dnd";
import { AppContext } from "../../../../AppContext";
import { TimeboxQueryContext } from "../../../../context/TimeboxContext";
import { useTasks } from "../../../../hooks/useTasks";
import { sdk } from "../../../../sdk";
import { theme } from "../../../../theme.css";
import { useValue } from "../../../../utils/useValue";
import * as styles from "./TaskDropzoneLayer.css";
import { TaskTimeslot } from "./TaskTimeslot/TaskTimeslot";

export type TaskDropzoneLayerProps = {};
export const TaskDropzoneLayer = (props: TaskDropzoneLayerProps) => {
  const timeboxContext = useContext(TimeboxQueryContext);
  const trackInfo = timeboxContext?.getTimeboxTrackInfo();
  const stepSize = trackInfo?.stepSize ?? 10;
  const totalNumberOfMarkers = pipe(
    trackInfo?.duration ?? 0,
    (duration) => duration / stepSize
  );
  const topOffset = theme.space[2];
  const timeslots = pipe(
    new Array(totalNumberOfMarkers).fill(""),
    FPArray.mapWithIndex((index) => {
      const startTime = pipe(
        trackInfo?.startTime ?? 0,
        (time) => time + stepSize * index
      );
      const style: CSSProperties = {
        position: "absolute",
        top: calc(theme.space[3])
          .multiply(index * 3)
          .add(topOffset)
          .toString(),
        height: pipe(
          10,
          (v) => v / (trackInfo?.stepSize ?? 10),
          (steps) => calc(theme.space[3]).multiply(3).multiply(steps).toString()
        ),
        width: "100%",
      };
      return (
        <TaskTimeslot startTime={startTime} style={style} key={startTime} />
      );
    })
  );
  return <div className={styles.root}>{timeslots}</div>;
};

