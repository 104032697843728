import { ComponentProps, ReactNode, RefObject } from "react";
import * as styles from "./Chip.css";
import { useField } from "formik";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LinealBoxLite, LinealBoxLiteProps } from "@dulce/design-system";

type CustomHTMLInputProps = Omit<
  React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >,
  "type"
>;

type StyleApi = LinealBoxLiteProps["styleApi"];
const styleApi: StyleApi = {};
type Color = typeof styleApi.color;

export type ChipProps = CustomHTMLInputProps & {
  label?: ReactNode;
  inputRef?: RefObject<HTMLInputElement>;
  color: Color;
};

export const Chip = (props: ChipProps) => {
  const { label, ...rest } = props;
  const [field] = useField({
    ...(rest as any),
    type: "checkbox",
  });
  return (
    <LinealBoxLite
      as="label"
      radius={4}
      className={styles.root}
      styleApi={{
        color: props.color,
      }}
    >
      {field.value && (
        <FontAwesomeIcon icon={faCheck} className={styles.checkmark} />
      )}
      {label && <span className={styles.label}>{label}</span>}
      <input
        className={styles.hiddenInput}
        {...field}
        {...rest}
        type="checkbox"
      />
    </LinealBoxLite>
  );
};
