import { Color, Minutes } from "@dulce/models/dist/tasks.models";
import { FormikProvider, useFormik } from "formik";
import { useContext } from "react";
import { ColorPicker } from "../../CreateTaskForm/ColorPicker/ColorPicker";
import { ColorRadioSquare } from "../../CreateTaskForm/ColorPicker/ColorRadioSquare/ColorRadioSquare";
import { Dropdown, DropdownItem } from "../../../foundation/Dropdown/Dropdown";
import { Heading } from "../../../foundation/Heading/Heading";
import { Input } from "../../../foundation/Input/Input";
import { TaskListFilterContext, TaskListSortBy } from "../FilterContext";
import * as styles from "./InboxFilterForm.css";
import { DurationDropdown } from "../../../foundation/DurationDropdown/DurationDropdown";
import { ModalContent } from "../../../foundation/Modal/Modal";
import { close } from "../../../foundation/Modal/useModal";
import { Button, Size } from "@dulce/design-system"

export type InboxFilterFormProps = {};
export const InboxFilterForm = ({ }: InboxFilterFormProps) => {
  const { color, title, estimate, sortBy } = useContext(
    TaskListFilterContext
  );

  const formik = useFormik({
    initialValues: {
      title: title.value,
      color: color.value,
      estimate: estimate.value,
      sortBy: sortBy.value,
    },
    onSubmit: async (values) => {
      color.setValue(values.color);
      title.setValue(values.title);
      estimate.setValue(values.estimate);
      close()
      sortBy.setValue(values.sortBy);
    },
  });

  const sortByDropdownItems: Array<DropdownItem<TaskListSortBy>> = [
    {
      label: "None",
      value: TaskListSortBy.NONE,
    },
    {
      label: "Duration",
      value: TaskListSortBy.DURATION,
    },
    {
      label: "Color",
      value: TaskListSortBy.COLOR,
    },
    {
      label: "Prefix",
      value: TaskListSortBy.PREFIX,
    },
    {
      label: "Locality",
      value: TaskListSortBy.LOCALITY,
    },
  ];

  return (
    <FormikProvider value={formik}>
      <form onSubmit={formik.handleSubmit} className={styles.root}>
        <ModalContent
          content={
            <div className={styles.content}>
              <Input name="title" label="Title" />
              <Dropdown
                items={sortByDropdownItems}
                label="Sort by"
                name="sortBy"
                defaultItem={sortByDropdownItems.find(
                  (item) => item.value === sortBy.value
                )}
              />
              <DurationDropdown
                min={10}
                max={120}
                step={10}
                name="estimate"
                allowZero={true}
              />
              <div>
                <ColorPicker name="color" includeBlank={true} />
              </div>
            </div>
          }
          footer={
            <div className={styles.footer}>
              <Button color="info">Filter</Button>
            </div>
          }
        />
      </form>
    </FormikProvider>
  );
};
