import { faEllipsis } from "@fortawesome/free-solid-svg-icons";
import { ReactChild, ReactChildren, useRef } from "react";
import { ButtonWithIcon } from "../../foundation/ButtonWithIcon/ButtonWithIcon";
import {
  ContextMenuOption,
  useContextMenu,
} from "../../foundation/ContextMenu/useContextMenu";
import { Heading } from "../../foundation/Heading/Heading";
import { LinealBox } from "../../foundation/LinealBox/LinealBox";
import { LinealLine } from "../../foundation/LinealBox/LinealLine/LinealLine";
import { theme } from "../../theme.css";
import * as styles from "./ContextBox.css";
import { Flex, Size } from "@dulce/design-system";

export type ContextBoxProps = {
  title: string;
  section?: ReactChildren | ReactChild;
  footer?: ReactChildren | ReactChild;
  options?: Array<ContextMenuOption>;
  headerRight?: ReactChildren | ReactChild;
};

const remToPx = (value: number) => value * 16;

export const ContextBox = (props: ContextBoxProps) => {
  const { open, close, isOpen, handleClick } = useContextMenu();
  const buttonRef = useRef<HTMLButtonElement>(null);
  return (
    <LinealBox
      radius={8}
      topLineProps={{
        variant: "lineal",
        gap: theme.space[3],
        position: 75,
      }}
      bottomLineProps={{
        variant: "lineal-dash",
        gap: theme.space[3],
        position: 25,
      }}
      className={styles.root}
    >
      <header className={styles.header}>
        <Heading>{props.title}</Heading>
        <Flex gap={Size.md}>
          <div>{props.headerRight}</div>
          <ButtonWithIcon
            icon={faEllipsis}
            ref={buttonRef}
            onClick={handleClick({
              buttonRef,
              options: props.options ?? [],
            })}
          />
        </Flex>
        <LinealLine
          gap={theme.space[2]}
          orientation="horizontal"
          position={25}
          variant="lineal"
          className={styles.headerLine}
          alignment="bottom"
        />
      </header>
      <section className={styles.section}>{props.section}</section>
      <footer className={styles.footer}>
        <LinealLine
          alignment="bottom"
          gap={theme.space[2]}
          orientation="horizontal"
          position={75}
          variant="lineal"
          className={styles.footerLine}
        />
        {props.footer}
      </footer>
    </LinealBox>
  );
};
