import { Dispatch, SetStateAction, useState } from "react"

export type UseValueResult<T> = {
  value: T,
  setValue: Dispatch<SetStateAction<T>>
}
export function useValue<T>(initValue: T): UseValueResult<T> {
  const [value, setValue] = useState<T>(initValue)
  return {
    value,
    setValue
  }
}