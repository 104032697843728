import React from "react"
import { LinealLine } from "../../foundation/LinealBox/LinealLine/LinealLine"
import { theme } from "../../theme.css"
import * as styles from "./AppLayout.css"

type Children = React.ReactChild | React.ReactChildren | undefined

export type AppLayoutProps = {
  header: Children
  main: Children
}
export const AppLayout = React.forwardRef<HTMLDivElement, AppLayoutProps>((props, ref) => {
  return (
    <div className={styles.root} ref={ref}>
      <header className={styles.header}>
        <div className={styles.headerContent}>{props.header}</div>
        <LinealLine
          gap={theme.space[3]}
          orientation="horizontal"
          position={25}
          variant="lineal-dash"
          className={styles.headerLine}
          alignment="top"
        />
      </header>
      <main className={styles.main}>
        <div className={styles.mainContent}>{props.main}</div>
      </main>
    </div>
  )

})
