import html2canvas from "html2canvas";
import { resizeCaptureElements } from "./resizeCaptureElements";

export type CaptureCanvasFromHtmlOpts = {
  html: HTMLElement;
  canvas: HTMLCanvasElement;
  video: HTMLVideoElement;
};

export const captureCanvasFromHtml = async (
  opts: CaptureCanvasFromHtmlOpts
): Promise<HTMLCanvasElement> => {
  const { html, canvas, video } = opts;
  const { height, width } = resizeCaptureElements({ html, canvas, video });
  return html2canvas(html, {
    height: height,
    width: width,
    ignoreElements: (element) => {
      if (element.className.includes("ReactModalPortal")) {
        return true;
      }
      if (element.id.includes("a11y-status-message")) {
        return true;
      }
      return false;
    },
  });
};
