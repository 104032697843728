import {
  Preferences,
  UpdateUserPreferencesDto,
} from "@dulce/models/dist/users.models";
import * as styles from "./UserPreferencesForm.css";
import { useFormik, FormikProvider } from "formik";
import { Boolean, pipe, TE } from "@dulce/prelude";
import { getErrorReason } from "../../utils/getErrorReason";
import { useUser, useUserMutation } from "../../hooks/useUser";
import { Flex, Size, Text, theme } from "@dulce/design-system";
import { faSave } from "@fortawesome/free-regular-svg-icons";
import { ButtonWithIcon } from "../../foundation/ButtonWithIcon/ButtonWithIcon";
import { close, open } from "../../foundation/Modal/useModal";
import { ModalContent } from "../../foundation/Modal/Modal";
import { Toggle } from "../../foundation/Toggle/Toggle";
import { Heading } from "../../foundation/Heading/Heading";
import { faRoadBarrier } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSpeechRecognition } from "react-speech-recognition";

type FormValues = {
  isIntervalChimesEnabled: boolean;
  isFocusBoxEnabled: boolean;
  isSpeechToTaskEnabled: boolean;
  isSpokenRemindersEnabled: boolean;
  isTaskDetailsTemplateEnabled: boolean;
};

const mapFormValuesToUpdateUserPreferencesDto =
  (prevUserPreferences?: Preferences) =>
  (values: FormValues): UpdateUserPreferencesDto => {
    return {
      focusBox: {
        isEnabled: values.isFocusBoxEnabled,
      },
      intervalChimes: {
        isEnabled: values.isIntervalChimesEnabled,
      },
      speechToTask: {
        isEnabled: values.isSpeechToTaskEnabled,
      },
      spokenReminders: {
        isEnabled: values.isSpokenRemindersEnabled,
      },
      taskDetailsTemplate: {
        isEnabled: values.isTaskDetailsTemplateEnabled,
        details: prevUserPreferences?.taskDetailsTemplate?.details ?? [],
      },
    };
  };

export type UserPreferencesFormProps = {};

export const UserPreferencesForm = (props: UserPreferencesFormProps) => {
  const userMutations = useUserMutation();
  const { selfQuery: userQuery } = useUser();
  const { browserSupportsSpeechRecognition } = useSpeechRecognition();

  const formik = useFormik<FormValues>({
    initialValues: {
      isFocusBoxEnabled:
        userQuery.data?.preferences?.focusBox?.isEnabled ?? false,
      isIntervalChimesEnabled:
        userQuery.data?.preferences?.intervalChimes?.isEnabled ?? false,
      isSpeechToTaskEnabled:
        userQuery.data?.preferences?.speechToTask?.isEnabled ?? false,
      isSpokenRemindersEnabled:
        userQuery.data?.preferences?.spokenReminders?.isEnabled ?? false,
      isTaskDetailsTemplateEnabled:
        userQuery.data?.preferences?.taskDetailsTemplate?.isEnabled ?? false,
    },
    onSubmit: async (values) =>
      pipe(
        values,
        TE.of,
        TE.map(
          mapFormValuesToUpdateUserPreferencesDto(userQuery.data?.preferences)
        ),
        TE.chain(
          TE.tryCatchK(
            userMutations.updateMyPreferences.mutateAsync,
            getErrorReason
          )
        )
      )(),
  });

  return (
    <FormikProvider value={formik}>
      <form onSubmit={formik.handleSubmit} className={styles.root}>
        <ModalContent
          content={
            <>
              <Flex column gap={Size.lg}>
                <Heading as="h3" size={Size.sm}>
                  <Flex gap={Size.md} alignItems="center">
                    <FontAwesomeIcon
                      icon={faRoadBarrier}
                      style={{ color: theme.colors.orange[6] }}
                    />
                    <span>Experimental Features</span>
                    <FontAwesomeIcon
                      icon={faRoadBarrier}
                      style={{ color: theme.colors.orange[6] }}
                    />
                  </Flex>
                </Heading>
                <Text italic>
                  The following features are a work in progress and subject to
                  change frequently.
                </Text>
                <Toggle
                  label="Enable Interval Chimes"
                  name="isIntervalChimesEnabled"
                />
                <Toggle label="Enable Focus Box" name="isFocusBoxEnabled" />
                <Flex column>
                  <Toggle
                    label="Speech to Task"
                    name="isSpeechToTaskEnabled"
                    disabled={!browserSupportsSpeechRecognition}
                  />
                  {!browserSupportsSpeechRecognition && (
                    <Text size={Size.sm} italic>
                      Your browser doesn't support speech recognition. This
                      feature is unavailable.
                    </Text>
                  )}
                </Flex>
                <Toggle
                  label="Spoken Reminders"
                  name="isSpokenRemindersEnabled"
                />
                <Toggle
                  label="Template for Task Details"
                  name="isTaskDetailsTemplateEnabled"
                />
              </Flex>
            </>
          }
          footer={
            <>
              <Flex justifyContent="flex-end">
                <ButtonWithIcon
                  color="primary"
                  icon={faSave}
                  disabled={userMutations.updateMyPreferences.isLoading}
                >
                  {pipe(
                    userMutations.updateMyPreferences.isLoading,
                    Boolean.fold(
                      () => "Save",
                      () => "Submitting"
                    )
                  )}
                </ButtonWithIcon>
              </Flex>
            </>
          }
        />
      </form>
    </FormikProvider>
  );
};

export const openUserPreferencesFormModal = () => {
  open({
    title: "User Preferences",
    onRequestClose: close,
    maxHeight: Size.md,
    children: (
      <>
        <UserPreferencesForm />
      </>
    ),
  });
};
