import * as styles from "./EditTaskForm.css";
import { FPArray, pipe, TE } from "@dulce/prelude";
import {
  Minutes,
  OwnerId,
  Task,
  TaskId,
} from "@dulce/models/dist/tasks.models";
import { FormikProvider, useFormik } from "formik";
import { DurationDropdown } from "../../foundation/DurationDropdown/DurationDropdown";
import { useContext, useEffect, useRef } from "react";
import { ColorPicker } from "../CreateTaskForm/ColorPicker/ColorPicker";
import {
  faHourglass,
  faPenToSquare,
} from "@fortawesome/free-regular-svg-icons";
import { sdk } from "../../sdk";
import { TimeboxQueryContext } from "../../context/TimeboxContext";
import { ToggleWithIcon } from "../../foundation/ToggleWithIcon/ToggleWithIcon";
import {
  faAnchor,
  faAnchorLock,
  faHeadphones,
  faLock,
  faRecycle,
  faUnlock,
} from "@fortawesome/free-solid-svg-icons";
import { ButtonWithIcon } from "../../foundation/ButtonWithIcon/ButtonWithIcon";
import { ModalContent } from "../../foundation/Modal/Modal";
import { TitleDetailsInput } from "../CreateTaskForm/TitleDetailsInput/TitleDetailsInput";
import { useHotkeys } from "react-hotkeys-hook";
import { useTasks, useTasksMutations } from "../../hooks/useTasks";
import { TimeScrubber } from "../../foundation/TimeScrubber/TimeScrubber";
import { CustomElements } from "@dulce/models/dist/slate.primatives";
import { HotkeyLabel } from "../HotkeyLabel/HotkeyLabel";
import { Size, Text, theme, Flex, Button } from "@dulce/design-system";

export const Paragraph = {
  fromStringOrCustomElements: (
    value?: string | CustomElements[] | undefined
  ): CustomElements[] => {
    if (!value) {
      return [
        { type: "paragraph", children: [{ text: "" }] } as CustomElements,
      ];
    }
    if (typeof value === "string") {
      return pipe(
        value.split("\n"),
        FPArray.map(
          (value) =>
            ({
              type: "paragraph",
              children: [
                {
                  text: value,
                },
              ],
            } as CustomElements)
        )
      );
    }
    return value;
  },
};

type FormValues = Omit<Task, "id" | "ownerId" | "mode"> & {
  isLocked: boolean;
  isDeepWork: boolean;
};
const draftUpdateTaskDtoFromValues =
  (id: TaskId, ownerId: OwnerId) =>
  (values: FormValues): Task => ({
    ...values,
    id,
    ownerId,
  });

export type EditTaskFormProps = {
  initTask: Task;
  onClose?: Function;
};
export const EditTaskForm = (props: EditTaskFormProps) => {
  const { updateTaskMutation } = useTasksMutations();
  const timebox = useContext(TimeboxQueryContext);
  const timeboxStartTime = timebox?.timeboxStartTime ?? 0;
  const timeboxDuration = timebox?.timeboxDuration ?? 0;
  const timeboxEndTime = timeboxStartTime + timeboxDuration;
  const { ownerId, id, ...initialValues } = props.initTask;
  const firstInputRef = useRef<HTMLInputElement>(null);
  const tasks = useTasks();
  const formik = useFormik({
    initialValues: {
      ...initialValues,
      details: Paragraph.fromStringOrCustomElements(
        initialValues?.details as any
      ),
      startTime: initialValues.startTime ?? undefined,
    },
    onSubmit: async (values) =>
      pipe(
        {
          ...values,
          startTime: values.startTime ?? undefined,
          isLocked: values.isLocked ?? false,
          isRecurring: values.isRecurring ?? false,
          isDeepWork: values.isDeepWork ?? false,
          isSuperLocked: values.isSuperLocked ?? false,
          details: values?.details ?? [
            { type: "paragraph", children: [{ text: "" }] },
          ],
        },
        draftUpdateTaskDtoFromValues(id, ownerId),
        TE.of,
        TE.chain(
          TE.tryCatchK(
            (task) => updateTaskMutation.mutateAsync(task),
            (reason) => new Error(String(reason))
          )
        ),
        TE.chainFirst(() => {
          props?.onClose?.();
          return TE.right(true);
        })
      )(),
  });

  useHotkeys(
    "alt+s, cmd+s, ctrl+s",
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      formik?.submitForm();
    },
    {
      enableOnTags: ["INPUT", "SELECT", "TEXTAREA"],
    }
  );

  return (
    <FormikProvider value={formik}>
      <form onSubmit={formik.handleSubmit} className={styles.root}>
        <ModalContent
          content={
            <>
              <TitleDetailsInput
                titleName="title"
                titleRef={firstInputRef}
                detailsName="details"
                emojiName="emojiCode"
                titleRequired
                detailsInitialValue={Paragraph.fromStringOrCustomElements(
                  initialValues.details as any
                )}
                styleApi={{ color: formik.values.color as any }}
              />
              <ColorPicker name="color" />
              <DurationDropdown
                min={10}
                max={120}
                step={10}
                name="duration"
                initialValue={initialValues.duration}
              />
              <Flex column gap={Size.md}>
                <Flex column gap={Size.sm}>
                  <Flex alignItems="center" gap={Size.sm}>
                    <Text size={Size.sm}>Start Time (optional)</Text>
                  </Flex>
                  <TimeScrubber
                    startTime={timeboxStartTime}
                    endTime={timeboxEndTime}
                    value={formik.values.startTime}
                    onChange={(value) =>
                      formik.setFieldValue("startTime", value)
                    }
                    tasks={tasks.data ?? []}
                    selectedTask={props.initTask}
                  />
                </Flex>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: theme.space[3],
                  }}
                >
                  <ToggleWithIcon
                    icon={formik.values.isLocked ? faLock : faUnlock}
                    name="isLocked"
                    label={formik.values.isLocked ? "Locked" : "Unlocked"}
                  />
                  <ToggleWithIcon
                    icon={formik.values.isSuperLocked ? faAnchorLock : faAnchor}
                    name="isSuperLocked"
                    label={"Super Lock"}
                  />
                  <ToggleWithIcon
                    icon={faRecycle}
                    name="isRecurring"
                    label={formik.values.isRecurring ? "Enabled" : "Disabled"}
                  />
                  <ToggleWithIcon
                    icon={faHeadphones}
                    name="isDeepWork"
                    label={
                      formik.values.isDeepWork ? "Focus: Deep" : "Focus: Simple"
                    }
                  />
                </div>
              </Flex>
            </>
          }
          footer={
            <>
              <div className={styles.formActions}>
                <ButtonWithIcon
                  size={Size.md}
                  color="primary"
                  disabled={updateTaskMutation.isLoading}
                  icon={
                    updateTaskMutation.isLoading ? faHourglass : faPenToSquare
                  }
                >
                  {updateTaskMutation.isLoading ? (
                    <span>Submitting...</span>
                  ) : (
                    <Flex gap={Size.sm} alignItems="center">
                      <Text>Update</Text>
                      <HotkeyLabel>Ctrl</HotkeyLabel>
                      <Text>+</Text>
                      <HotkeyLabel>S</HotkeyLabel>
                    </Flex>
                  )}
                </ButtonWithIcon>
              </div>
            </>
          }
        />
      </form>
    </FormikProvider>
  );
};
