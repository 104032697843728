import {
  CSSProperties,
  Fragment,
  ReactNode,
  RefObject,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from "react";
import { useDrop } from "react-dnd";
import { AppContext } from "../../AppContext";
import { useTasks, useTasksMutations } from "../../hooks/useTasks";
import { Task } from "../Task/Task";
import { TaskListFilterContext, TaskListSortBy } from "./FilterContext";
import * as styles from "./TaskList.css";
import { tasksSortingStrategy } from "../../sdk/tasks/Tasks.helpers";
import { sdk } from "../../sdk";
import { FPArray, pipe, TE, Boolean, O } from "@dulce/prelude";
import {
  Task as TaskType,
  UnscheduleTaskDto,
} from "@dulce/models/dist/tasks.models";
import { QuickAddForm } from "./QuickAddForm/QuickAddForm";
import { useValue } from "../../utils/useValue";
import Fuse from "fuse.js";
import { useDebounce, useThrottleFn } from "react-use";
import { Flex, Size, sizeThemeMap, Text } from "@dulce/design-system";
import { openCreateTaskModal } from "../../foundation/Modal/modals/openCreateTaskModal";
import { useVirtualizer } from "@tanstack/react-virtual";
import { AnimatePresence } from "framer-motion";
import classNames from "classnames";
import { SearchAndFilter } from "./SearchAndFilter/SearchAndFilter";
import {
  SearchAndFilterStoreValues,
  searchAndFilterStore,
} from "./SearchAndFilter/SearchAndFilter.state";
import { useStore } from "zustand";

export const sortTaskPredicates = {
  [TaskListSortBy.PREFIX]: tasksSortingStrategy.byPrefix,
  [TaskListSortBy.COLOR]: tasksSortingStrategy.byColor,
  [TaskListSortBy.DURATION]: tasksSortingStrategy.byDuration,
  [TaskListSortBy.NONE]: tasksSortingStrategy.byNone,
  [TaskListSortBy.LOCALITY]: tasksSortingStrategy.byLocality,
};

export function fuzzySearch<T extends object>(list: Array<T>) {
  const fuse = new Fuse(list, {
    keys: [
      {
        name: "title",
        weight: 3,
      },
      {
        name: "details",
        weight: 1,
      },
    ],
    includeScore: true,
    threshold: Math.max(0.4, 1 - list.length / 20),
  });
  return fuse.search.bind(fuse);
}

export type TaskListProps = {};
export const TaskList = ({}: TaskListProps) => {
  const appContext = useContext(AppContext);
  const { unscheduleTaskMutation } = useTasksMutations();
  const tasksQuery = useTasks();
  const searchText = useValue("");
  const isDropActive = useValue<boolean>(false);

  const { title, color, estimate, sortBy } = useContext(TaskListFilterContext);

  const [collectedProps, drop] = useDrop(
    {
      accept: "task",
      drop: async (item: { id: string }, monitor) => {
        // @techdebt: clean this multi-select logic up

        const selectedTaskIds = sdk.selection.queries.listTasks();
        if (selectedTaskIds.length !== 0) {
          const tasks = tasksQuery.data ?? [];

          const foundTasks = pipe(
            selectedTaskIds,
            FPArray.map(sdk.tasks.queries.selectTaskById(tasks)),
            FPArray.filter(sdk.tasks.helpers.isTask)
          );

          const payloads = pipe(
            foundTasks,
            FPArray.map<TaskType, UnscheduleTaskDto>((task) => ({
              id: task.id,
            }))
          );

          payloads.forEach(async (item) => {
            await pipe(
              item,
              TE.of,
              TE.chain(
                TE.tryCatchK(
                  unscheduleTaskMutation.mutateAsync,
                  (reason) => new Error(String(reason))
                )
              )
            )();
          });

          sdk.selection.commands.unselectAllTasks();

          return;
        }

        await pipe(
          item,
          TE.of,
          TE.chain(
            TE.tryCatchK(
              unscheduleTaskMutation.mutateAsync,
              (reason) => new Error(String(reason))
            )
          )
        )();
        appContext.isPushDownEnabled.setValue(false);
      },
      collect: (monitor) => {
        return {
          isOver: monitor.isOver({ shallow: true }),
        };
      },
    },
    []
  );

  useDebounce(
    () => {
      isDropActive.setValue(collectedProps.isOver);
    },
    100,
    [collectedProps.isOver]
  );

  const foundData = tasksQuery.data ?? [];
  const unscheduledTasks = sdk.tasks.queries.getUnscheduledTasks(foundData);
  const searchTasks = useMemo(
    () => fuzzySearch(unscheduledTasks),
    [unscheduledTasks]
  );

  const { searchQuery, colorFilter } = useStore(searchAndFilterStore);

  const filteredAndSortedTasks = useMemo(
    () =>
      pipe(
        unscheduledTasks,
        FPArray.sort(sortTaskPredicates[sortBy.value]),
        (tasks) => {
          const startSearchThreshold = 3;
          return pipe(
            searchQuery.length >= startSearchThreshold,
            Boolean.match(
              () => tasks,
              () =>
                pipe(
                  searchTasks(searchQuery),
                  FPArray.map((v) => v.item)
                )
            )
          );
        },
        FPArray.filter((task) => {
          // if all the colors are false, then show all colors
          const isEveryValueFalse = Object.values(colorFilter).every(
            (value) => value === false
          );
          if (isEveryValueFalse) {
            return true;
          }
          type Color = keyof SearchAndFilterStoreValues["colorFilter"];
          return colorFilter[task.color as Color];
        })
        // FPArray.filter((task) => {
        //   if (estimate.value === 0) {
        //     return true;
        //   }
        //   return task.duration === estimate.value;
        // })
      ),
    [unscheduledTasks, searchQuery, colorFilter]
  );

  if (unscheduledTasks.length === 0)
    return (
      <div
        className={styles.root({ dropActive: isDropActive.value })}
        ref={drop}
      >
        <Text>No unscheduled tasks, yay!</Text>
      </div>
    );

  if (tasksQuery.isLoading) {
    return <div>Loading...</div>;
  }

  if (tasksQuery.isError) {
    const error = tasksQuery.error as Error;
    return <div>Error: {error.message}</div>;
  }

  return (
    <Fragment>
      <div
        className={styles.root({ dropActive: isDropActive.value })}
        ref={drop}
      >
        <SearchAndFilter />
        <div
          style={{
            borderBottom: `thin solid black`,
            width: "100%",
            marginBottom: sizeThemeMap[Size.md],
            marginTop: sizeThemeMap[Size.md],
          }}
        />
        <AnimatePresence initial={false}>
          <VirtualTaskList tasks={filteredAndSortedTasks} />
        </AnimatePresence>
      </div>
    </Fragment>
  );
};

export type VirtualTaskListProps = {
  tasks: Array<TaskType>;
};
export const VirtualTaskList = (props: VirtualTaskListProps) => {
  const { tasks } = props;

  const rootRef = useRef<HTMLDivElement>(null);

  const isScrollBarVisible = () => {
    const element = rootRef.current;
    if (!element) {
      return false;
    }
    return element.scrollHeight > element.clientHeight;
  };

  const rowVirtualizer = useVirtualizer({
    count: tasks.length,
    getScrollElement: () => rootRef.current,
    estimateSize: () => 49 + 4, // this number was obtained by inspecting the computed height of the task in the inbox
    overscan: 5,
  });

  const rootStyle: CSSProperties = {
    height: "100%",
    overflow: "auto",
  };

  const itemsContainerRef = useRef(null);
  const itemsContainerStyle: CSSProperties = {
    height: `${rowVirtualizer.getTotalSize()}px`,
    width: "100%",
    position: "relative",
  };
  const virtualItems = rowVirtualizer.getVirtualItems();

  const items = useMemo(
    () =>
      pipe(
        virtualItems,
        FPArray.map((virtualItem) => {
          const style: CSSProperties = {
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: `${virtualItem.size}px`,
            transform: `translateY(${virtualItem.start}px)`,
          };
          // note: iOS is whack
          const renderFallback = () => {
            return (
              <div style={style} key={virtualItem.key}>
                Row {virtualItem.index}
              </div>
            );
          };
          const renderTaskListItem = (task: TaskType) => {
            return (
              <div style={style} key={task.id}>
                <Task id={task.id} disableMotion />
              </div>
            );
          };
          return pipe(
            tasks ?? [],
            FPArray.lookup(virtualItem.index),
            O.matchW(renderFallback, renderTaskListItem)
          );
        })
      ),
    [virtualItems]
  );

  return (
    <div
      ref={rootRef}
      style={rootStyle}
      className={classNames(
        styles.withStyledScrollbar,
        styles.scrollableDiv({ adjustGutter: isScrollBarVisible() })
      )}
    >
      <div ref={itemsContainerRef} style={itemsContainerStyle}>
        {items}
      </div>
    </div>
  );
};
