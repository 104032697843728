import { createPortal } from "react-dom";
import * as styles from "./EmojiMenu.css";
import { LinealBox } from "../LinealBox/LinealBox";
import { useEffect, useMemo, useRef } from "react";
import { useClickAway, useMeasure, useMouseWheel, useKey } from "react-use";
import { useEmojiMenu, emojiMenuStore } from "./useEmojiMenu";
import { useStore } from "zustand";
import { useValue } from "../../utils/useValue";
// import * as focusTrap from "focus-trap";
import { usePassiveWheel } from "../../utils/usePassiveScroll";
import { theme, Text } from "@dulce/design-system";
import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";

export type EmojiMenuProps = {};

export const EmojiMenu = (props: EmojiMenuProps) => {
  const { isOpen, top, left, close } = useEmojiMenu();
  const { onEmojiSelect } = useStore(emojiMenuStore);
  const ref = useRef<HTMLDivElement>(null);
  useClickAway(ref, () => {
    if (isOpen) {
      close();
    }
  });

  const [outerRef, outerMeasurements] = useMeasure();

  const adjustedTop = useMemo(() => {
    const isOutOfBoundsY = top + outerMeasurements.height > window.innerHeight;
    return isOutOfBoundsY
      ? window.innerHeight - outerMeasurements.height - 16
      : top;
  }, [window.innerHeight, outerMeasurements.height, top]);

  const adjustedLeft = useMemo(() => {
    const isOutOfBoundsX = left + outerMeasurements.width > window.innerWidth;
    return isOutOfBoundsX
      ? window.innerWidth - outerMeasurements.width - 16
      : left;
  }, [window.innerWidth, outerMeasurements.width, left]);

  // const trap = useValue<focusTrap.FocusTrap | null>(null);

  const mouseWheelDelta = useMouseWheel();
  const windowRef = useRef(window);

  // usePassiveWheel({
  //   handler: close,
  //   ref: windowRef as any,
  // });

  useEffect(() => {
    close();
  }, [mouseWheelDelta]);

  // useEffect(() => {
  //   const element = ref.current;
  //   if (element && isOpen) {
  //     const value = focusTrap.createFocusTrap(element, {
  //       clickOutsideDeactivates: true,
  //     });
  //     value.activate();
  //     trap.setValue(value);
  //   }
  //   return () => {
  //     trap?.value?.deactivate();
  //   };
  // }, [ref, isOpen]);

  useKey("Escape", () => {
    close();
  });
  return createPortal(
    <>
      <LinealBox
        outerClassName={styles.menu}
        topLineProps={{
          gap: theme.space[2],
          variant: "lineal-dash",
          position: 75,
        }}
        leftLineProps={{
          gap: theme.space[3],
          variant: "lineal",
          position: 90,
        }}
        outerProps={{
          style: {
            opacity: isOpen ? 1 : 0,
            top: isOpen ? adjustedTop : 0,
            left: isOpen ? adjustedLeft : -9999,
          },
        }}
        ref={ref}
        outerRef={outerRef as any}
        innerProps={{
          ariaOrientation: "vertical",
          role: "menu",
        }}
      >
        <Picker data={data} onEmojiSelect={onEmojiSelect} dynamicWidth />
      </LinealBox>
    </>,
    document.getElementById("portal") as Element
  );
};
