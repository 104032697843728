import { Dropdown, DropdownItem } from "../Dropdown/Dropdown";
import { Minutes } from "@dulce/models/dist/tasks.models";
import { Flex, Size, theme } from "@dulce/design-system";
import { ButtonWithIcon } from "../ButtonWithIcon/ButtonWithIcon";
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { useField } from "formik";
import { pipe } from "@dulce/prelude";
import { add } from "lodash/fp";

export type DurationDropdownProps = {
  min: number; // has to be at min 0
  max: number; // has to be more than min
  step: number; // has to be at min 10
  name: string;
  allowZero?: boolean;
  initialValue?: number;
};
export const DurationDropdown = (props: DurationDropdownProps) => {
  const [field, meta, helpers] = useField(props.name);
  const numberOfItems = Math.floor((props.max - props.min) / props.step);
  let items: Array<DropdownItem<Minutes>> = new Array(numberOfItems)
    .fill("")
    .map((item, itemIndex) => {
      let value = itemIndex * props.step + props.min;
      return {
        label: `${value} min`,
        value,
      };
    });
  const zeroItem: DropdownItem<Minutes> = {
    label: "Unset",
    value: 0,
  };
  if (props.allowZero) {
    items = [zeroItem, ...items];
  }

  const incrementBy10: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();
    const updatedValue = pipe(field.value, Number.parseInt, add(10), (v) =>
      Math.min(v, props.max)
    );
    helpers.setValue(updatedValue);
  };

  const decrementBy10: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();
    const updatedValue = pipe(field.value, Number.parseInt, add(-10), (v) =>
      Math.max(v, props.min)
    );

    helpers.setValue(updatedValue);
  };

  return (
    <Flex gap={Size.sm} alignItems="flex-end">
      <ButtonWithIcon
        icon={faMinus}
        style={{ marginBottom: theme.space[1] }}
        onClick={decrementBy10}
      />
      <Dropdown
        name={props.name}
        label="Estimated time"
        items={items}
        defaultItem={items.find((item) => item.value === props.initialValue)}
      />
      <ButtonWithIcon
        icon={faPlus}
        style={{ marginBottom: theme.space[1] }}
        onClick={incrementBy10}
      />
    </Flex>
  );
};
