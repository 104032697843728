import {
  AddTaskDto,
  UpdateTaskDto,
  ScheduleTaskDto,
  UnscheduleTaskDto,
  DeleteTaskDto,
  Task,
  CompleteTaskDto,
  UncompleteTaskDto,
  DuplicateTaskDto,
  ClearTimeboxDto,
  DisableRecurringDto,
  EnableRecurringDto,
  LockTaskDto,
  SuperLockTaskDto,
  UnlockTaskDto,
} from "@dulce/models/dist/tasks.models";
import { config } from "../../config";
import { authAxios } from "../../utils/authAxios";

const baseUrl = config.api.url;

export const addTask = async (addTaskDto: AddTaskDto) =>
  authAxios
    .request<void>({
      method: "POST",
      url: `${baseUrl}/tasks/add-task`,
      data: addTaskDto,
    })
    .then((res) => res.data);

export const updateTask = async (dto: UpdateTaskDto) =>
  authAxios.request<void>({
    method: "POST",
    url: `${baseUrl}/tasks/update-task`,
    data: dto,
  });

export const scheduleTask = async (dto: ScheduleTaskDto) =>
  authAxios
    .request<Task>({
      method: "POST",
      url: `${baseUrl}/tasks/schedule-task`,
      data: dto,
    })
    .then((res) => res.data);

export const unscheduleTask = async (dto: UnscheduleTaskDto) =>
  authAxios
    .request<void>({
      method: "POST",
      url: `${baseUrl}/tasks/unschedule-task`,
      data: dto,
    })
    .then((res) => res.data);

export const deleteTask = async (dto: DeleteTaskDto) =>
  authAxios
    .request<Task>({
      method: "POST",
      url: `${baseUrl}/tasks/delete-task`,
      data: dto,
    })
    .then((res) => res.data);

export const completeTask = async (dto: CompleteTaskDto) =>
  authAxios
    .request<Task>({
      method: "POST",
      url: `${baseUrl}/tasks/complete-task`,
      data: dto,
    })
    .then((res) => res.data);

export const uncompleteTask = async (dto: UncompleteTaskDto) =>
  authAxios
    .request<Task>({
      method: "POST",
      url: `${baseUrl}/tasks/uncomplete-task`,
      data: dto,
    })
    .then((res) => res.data);

export const deleteCompletedTasks = async () =>
  authAxios
    .request<Array<Task>>({
      method: "POST",
      url: `${baseUrl}/tasks/delete-completed-tasks`,
      data: {},
    })
    .then((res) => res.data);

export const duplicateTask = async (dto: DuplicateTaskDto) =>
  authAxios
    .request<Task>({
      method: "POST",
      url: `${baseUrl}/tasks/duplicate-task`,
      data: dto,
    })
    .then((res) => res.data);

export const clearTimebox = async (dto: ClearTimeboxDto) =>
  authAxios
    .request<Task>({
      method: "POST",
      url: `${baseUrl}/tasks/clear-timebox`,
      data: dto,
    })
    .then((res) => res.data);

export const disableRecurringTask = async (dto: DisableRecurringDto) =>
  authAxios
    .request<Task>({
      method: "POST",
      url: `${baseUrl}/tasks/disable-recurring`,
      data: dto,
    })
    .then((res) => res.data);

export const enableRecurringTask = async (dto: EnableRecurringDto) =>
  authAxios
    .request<Task>({
      method: "POST",
      url: `${baseUrl}/tasks/enable-recurring`,
      data: dto,
    })
    .then((res) => res.data);

export const lockTask = async (dto: LockTaskDto) =>
  authAxios
    .request<Task>({
      method: "POST",
      url: `${baseUrl}/tasks/lock-task`,
      data: dto,
    })
    .then((res) => res.data);

export const superLockTask = async (dto: SuperLockTaskDto) =>
  authAxios
    .request<Task>({
      method: "POST",
      url: `${baseUrl}/tasks/super-lock-task`,
      data: dto,
    })
    .then((res) => res.data);

export const unlockTask = async (dto: UnlockTaskDto) =>
  authAxios
    .request<Task>({
      method: "POST",
      url: `${baseUrl}/tasks/unlock-task`,
      data: dto,
    })
    .then((res) => res.data);
