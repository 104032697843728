
import * as styles from "./HotkeyLabel.css"

export type HotkeyLabelProps = {
  children: React.ReactNode
}

export const HotkeyLabel = (props: HotkeyLabelProps) => {
  return (
    <div className={styles.root}>
      {props.children}
    </div>
  )
}
