import { Flex, Size, Text } from "@dulce/design-system";
import { ThirdPartyCalendarEventId } from "@dulce/models/dist/third-party-calendars.models";
import { FPArray, O, pipe } from "@dulce/prelude";
import { faUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";
import { ButtonWithIcon } from "../../../foundation/ButtonWithIcon/ButtonWithIcon";
import { _DulceEditorPreview } from "../../../foundation/DulceEditor/DulceEditorPreview";
import { Heading } from "../../../foundation/Heading/Heading";
import { useThirdPartyCalendars } from "../../../hooks/useThirdPartyCalendars";
import { sdk } from "../../../sdk";
import { getSortedEventsFromCalendars } from "../../Timebox/Layers/ThirdPartyCalendarsLayer/ThirdPartyCalendarsLayer";
import * as styles from "./ThirdPartyCalendarEventDetails.css";

export type ThirdPartyCalendarEventDetailsProps = {
  id: ThirdPartyCalendarEventId;
};

export const ThirdPartyCalendarEventDetails = (
  props: ThirdPartyCalendarEventDetailsProps
) => {
  const { thirdPartyCalendars } = useThirdPartyCalendars();

  const foundEvent = pipe(
    thirdPartyCalendars.data ?? [],
    getSortedEventsFromCalendars,
    FPArray.findFirst((event) => event.id === props.id)
  );

  return pipe(
    foundEvent,
    O.match(
      () => {
        return (
          <Flex>
            <Text>Not yet found</Text>
          </Flex>
        );
      },
      (event) => (
        <Flex column gap={Size.md} className={styles.root}>
          <Heading>{event.title}</Heading>
          <Text>Duration: {event.duration} min</Text>
          <Text>
            Start time:{" "}
            {pipe(event.startTime, sdk.utils.time.getFormattedTimeFromMinutes)}
          </Text>
          <_DulceEditorPreview details={event.details} />
        </Flex>
      )
    )
  );
};
