import { Text } from "@dulce/design-system";
import { RenderElementProps } from "slate-react";

export type ListItemProps = {
  children: any;
  attributes?: any;
};

export const ListItem = (props: ListItemProps) => {
  return (
    <Text {...props.attributes} as="li">
      {props.children}
    </Text>
  );
};

export const ListItemElement = (props: RenderElementProps) => {
  const { attributes, children, element } = props;
  return <ListItem children={children} attributes={attributes} />;
};
