import { useEffect, useMemo, useRef } from "react";
import { createPortal } from "react-dom";
import { LinealBox } from "../LinealBox/LinealBox";
import { useContextMenu } from "./useContextMenu";
import * as styles from "./ContextMenu.css";
import { theme } from "@dulce/design-system";
import { FPArray, pipe } from "@dulce/prelude";
import { useClickAway, useKey, useMouseWheel, useMeasure } from "react-use";
import { ContextMenuItem } from "./ContextMenuItem/ContextMenuItem";
import * as focusTrap from "focus-trap";
import { useValue } from "../../utils/useValue";
import { usePassiveWheel } from "../../utils/usePassiveScroll";

/**
 * TODO: https://www.w3.org/WAI/ARIA/apg/example-index/menu-button/menu-button-links
 * @returns \
 */
export const ContextMenu = () => {
  const { isOpen, top, left, options, close } = useContextMenu();
  const ref = useRef<HTMLDivElement>(null);
  useClickAway(ref, () => {
    if (isOpen) {
      close();
    }
  });

  const [outerRef, outerMeasurements] = useMeasure();

  const adjustedTop = useMemo(() => {
    const isOutOfBoundsY = top + outerMeasurements.height > window.innerHeight;
    return isOutOfBoundsY
      ? window.innerHeight - outerMeasurements.height - 16
      : top;
  }, [window.innerHeight, outerMeasurements.height, top]);

  const adjustedLeft = useMemo(() => {
    const isOutOfBoundsX = left + outerMeasurements.width > window.innerWidth;
    return isOutOfBoundsX
      ? window.innerWidth - outerMeasurements.width - 16
      : left;
  }, [window.innerWidth, outerMeasurements.width, left]);

  const trap = useValue<focusTrap.FocusTrap | null>(null);

  const mouseWheelDelta = useMouseWheel();
  const windowRef = useRef(window)

  usePassiveWheel({
    handler: close,
    ref: windowRef as any
  })

  useEffect(() => {
    close();
  }, [mouseWheelDelta]);

  useEffect(() => {
    const element = ref.current;
    if (element && isOpen) {
      const value = focusTrap.createFocusTrap(element, {
        clickOutsideDeactivates: true,
      });
      value.activate();
      trap.setValue(value);
    }
    return () => {
      trap?.value?.deactivate();
    };
  }, [ref, isOpen]);

  useKey("ArrowDown", () => {
    const element = ref.current;
    const activeElement = document.activeElement;
    if (element && activeElement) {
      const focusableElements = Array.from(element.querySelectorAll("button"));
      const foundIndex = focusableElements.findIndex(
        (el) =>
          el.dataset["name"] ===
          (activeElement as HTMLButtonElement).dataset["name"]
      );
      if (foundIndex === focusableElements.length - 1) {
        focusableElements.at(0)?.focus();
      } else {
        focusableElements.at(foundIndex + 1)?.focus();
      }
    }
  });

  useKey("ArrowUp", () => {
    const element = ref.current;
    const activeElement = document.activeElement;
    if (element && activeElement) {
      const focusableElements = Array.from(
        element.querySelectorAll("button")
      ).reverse();
      const foundIndex = focusableElements.findIndex(
        (el) =>
          el.dataset["name"] ===
          (activeElement as HTMLButtonElement).dataset["name"]
      );
      if (foundIndex === focusableElements.length - 1) {
        focusableElements.at(0)?.focus();
      } else {
        focusableElements.at(foundIndex + 1)?.focus();
      }
    }
  });

  useKey("Home", () => {
    const element = ref.current;
    const activeElement = document.activeElement;
    if (element && activeElement) {
      const focusableElements = Array.from(element.querySelectorAll("button"));
      focusableElements.at(0)?.focus();
    }
  });

  useKey("End", () => {
    const element = ref.current;
    const activeElement = document.activeElement;
    if (element && activeElement) {
      const focusableElements = Array.from(element.querySelectorAll("button"));
      focusableElements.at(focusableElements.length - 1)?.focus();
    }
  });

  useKey("Escape", () => {
    close();
  });

  return createPortal(
    <>
      <LinealBox
        as="ul"
        outerClassName={styles.menu}
        className={styles.menuInner}
        topLineProps={{
          gap: theme.space[2],
          variant: "lineal-dash",
          position: 75,
        }}
        leftLineProps={{
          gap: theme.space[3],
          variant: "lineal",
          position: 90,
        }}
        outerProps={{
          style: {
            opacity: isOpen ? 1 : 0,
            top: isOpen ? adjustedTop : 0,
            left: isOpen ? adjustedLeft : -9999,
          },
        }}
        ref={ref}
        outerRef={outerRef as any}
        innerProps={{
          ariaOrientation: "vertical",
          role: "menu",
        }}
      >
        {pipe(
          options,
          FPArray.map((option) => (
            <ContextMenuItem {...option} key={option.name} />
          ))
        )}
      </LinealBox>
    </>,
    document.getElementById("portal") as Element
  );
};
