import {
  faHeadphones,
  faCheck,
  faLock,
  faRecycle,
  faRotateLeft,
  faWarning,
  IconDefinition,
  faEllipsis,
  faAnchorLock,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as styles from "./Task.css";
import { useDrag } from "react-dnd";
import { faHourglass } from "@fortawesome/free-regular-svg-icons";
import { useCallback, useContext, useEffect, useRef } from "react";
import { useValue } from "../../utils/useValue";
import { Boolean, O, pipe } from "@dulce/prelude";
import { AppContext } from "../../AppContext";
import { motion, HTMLMotionProps } from "framer-motion";
import classNames from "classnames";
import { TimeboxCommandContext } from "../../context/TimeboxContext";
import { calc } from "@vanilla-extract/css-utils";
import { sdk } from "../../sdk";
import { useTask, useTasksMutations } from "../../hooks/useTasks";
import { useContextMenuOptions } from "./useTaskContextMenuOptions/useTaskContextMenuOptions";
import { Size, Text, theme, Button, LinealBoxLite } from "@dulce/design-system";
import { ButtonWithIcon } from "../../foundation/ButtonWithIcon/ButtonWithIcon";
import { useContextMenu } from "../../foundation/ContextMenu/useContextMenu";
import { useTaskModals } from "./TaskModals";
import { useMultiTaskContextMenuOptions } from "./useTaskContextMenuOptions/useMultiTaskContextMenuOptions";
import { getEmptyImage } from "react-dnd-html5-backend";
import { consoleTap } from "../../utils/consoleTap";

export type TaskProps = {
  id: string;
  variant?: "compact" | "timeline";
  disableMotion?: boolean;
};
export const Task = ({
  id,
  variant = "compact",
  disableMotion = false,
}: TaskProps) => {
  const timeboxCommandContext = useContext(TimeboxCommandContext);
  const appContext = useContext(AppContext);
  const isResizeHandleDragging = useValue<boolean>(false);
  const resizeHeight = useValue<string>("0px");
  const tempDuration = useValue<number>(0);
  const isSelected = useValue<boolean>(false);

  const contextMenuRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    let isTaskSelected = sdk.selection.queries.isTaskSelected(id);
    isSelected.setValue(isTaskSelected);
    sdk.selection.store.subscribe(() => {
      let isTaskSelected = sdk.selection.queries.isTaskSelected(id);
      isSelected.setValue(isTaskSelected);
    });
  }, []);

  const [handleCollectedProps, handleDragRef] = useDrag(
    () => ({
      type: "task-handle",
    }),
    []
  );

  const resizeHandleEl = useValue<HTMLDivElement | null>(null);
  const resizeHandleRef = useCallback((el: HTMLDivElement) => {
    handleDragRef(el);
    resizeHandleEl.setValue(el);
  }, []);

  const innerRef = useRef<HTMLDivElement>(null);

  const [collected, drag, preview] = useDrag(
    () => ({
      type: "task",
      item: {
        id,
        getRect: () => {
          return pipe(
            innerRef.current,
            O.fromNullable,
            O.map((element) => element.getBoundingClientRect())
          );
        },
      },
      collect: (monitor) => {
        return {
          isDragging: monitor.isDragging(),
        };
      },
    }),
    []
  );

  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true });
  }, []);

  useEffect(() => {
    timeboxCommandContext?.setIsTaskDragHoverScrollable(collected.isDragging);
  }, [collected.isDragging]);

  const {
    unscheduleTaskMutation,
    deleteTaskMutation,
    completeTaskMutation,
    uncompleteTaskMutation,
    scheduleTaskMutation,
    updateTaskMutation,
    _upsertTaskIntoQueryData,
  } = useTasksMutations();

  const taskQuery = useTask(id);

  const contextMenu = useContextMenu();

  const isComputingResizeAfterDragEnd = useValue(false);
  const isMutationLoading =
    unscheduleTaskMutation.isLoading ||
    deleteTaskMutation.isLoading ||
    completeTaskMutation.isLoading ||
    uncompleteTaskMutation.isLoading ||
    scheduleTaskMutation.isLoading ||
    updateTaskMutation.isLoading ||
    isComputingResizeAfterDragEnd.value;

  const primaryButtonIcon = (): IconDefinition => {
    if (isMutationLoading) return faHourglass;
    if (taskQuery.data?.status === "COMPLETED") return faRotateLeft;
    return faCheck;
  };

  const taskModals = useTaskModals(id);

  const singleTaskMenuOptions = useContextMenuOptions({
    primaryButtonIcon,
    taskId: id,
  });

  const multiTaskMenuOptions = useMultiTaskContextMenuOptions();

  const contextMenuOptions = sdk.selection.queries.isAnyTaskSelected()
    ? multiTaskMenuOptions
    : singleTaskMenuOptions;

  if (taskQuery.isLoading) {
    return (
      <LinealBoxLite
        radius={4}
        className={styles.root}
        styleApi={{ color: "gray" }}
      >
        <div className={styles.leftGroup({ alignItems: "center" })}>
          <div className={styles.tab({ colors: "gray" })} />
          <Button
            color="ghost"
            size={Size.md}
            rounded
            className={styles.iconRotate}
          >
            <FontAwesomeIcon icon={faHourglass} />
          </Button>
          <Text className={styles.title} size={Size.md}>
            Loading...
          </Text>
        </div>
        <div className={styles.rightGroup}></div>
      </LinealBoxLite>
    );
  }

  const task = taskQuery.data;

  if (taskQuery.isError || task === undefined) {
    return (
      <LinealBoxLite
        radius={4}
        className={styles.root}
        styleApi={{ color: "gray" }}
      >
        <div className={styles.leftGroup({ alignItems: "flexStart" })}>
          <div className={styles.tab({ colors: "gray" })} />
          <Button color="ghost" size={Size.md} rounded>
            <FontAwesomeIcon icon={faWarning} />
          </Button>
          <Text className={styles.title} size={Size.md}>
            Something went wrong...
          </Text>
        </div>
        <div className={styles.rightGroup}></div>
      </LinealBoxLite>
    );
  }

  const toggleComplete = () => {
    if (task.status === "COMPLETED") {
      uncompleteTaskMutation.mutateAsync({ id: task.id });
    } else {
      completeTaskMutation.mutateAsync({ id: task.id });
    }
  };

  // TODO: use a color map to update the look of the task based on its color

  const height = pipe(
    theme.space[3],
    calc,
    (v) => v.multiply(3),
    (v) => (variant === "compact" ? v.toString() : "100%"),
    (h) =>
      isResizeHandleDragging.value
        ? calc(h).add(resizeHeight.value).toString()
        : h
  );

  const isMultiSelectionActive = sdk.selection.queries.isAnyTaskSelected();

  const motionProps: HTMLMotionProps<"div"> = disableMotion
    ? {}
    : {
        initial: {
          x: "-100%",
          opacity: 0,
        },
        exit: {
          x: "100%",
          opacity: 0,
        },
        animate: {
          x: "0%",
          opacity: 1,
        },
      };

  return (
    <>
      <motion.div key={task.id} {...motionProps}>
        <LinealBoxLite
          radius={4}
          className={styles.root}
          styleApi={{
            color: task.color as any,
          }}
          outerRef={drag}
          ref={innerRef}
          innerProps={{
            style: {
              alignItems: variant === "compact" ? "center" : "flex-start",
            },
          }}
          outerProps={{
            title: task.title,
            "data-task-id": task.id,
            style: {
              height,
              minHeight: calc(theme.space[3]).multiply(3).add("1px"),
              maxHeight: calc(theme.space[3])
                .multiply(3)
                .multiply(11)
                .add("1px"),
              opacity: collected.isDragging ? 0.4 : 1,
            },
            onContextMenu: (e: MouseEvent) => {
              e.preventDefault();
              e.stopPropagation();

              contextMenu.open({
                top: e.clientY,
                left: e.clientX,
                options: contextMenuOptions,
              });
            },
            onClick: (e: MouseEvent) => {
              e.preventDefault();
              e.stopPropagation();
              if (e.shiftKey) {
                if (sdk.selection.queries.isTaskSelected(task.id)) {
                  sdk.selection.commands.unselectTask(task.id);
                } else {
                  sdk.selection.commands.selectTask(task.id);
                }
              } else {
                sdk.selection.commands.unselectAllTasks();
              }
            },
            onDoubleClick: (e: any) => {
              e.preventDefault();
              e.stopPropagation();
              taskModals.openTaskDetailsModal();
            },
          }}
        >
          <div
            className={styles.leftGroup({
              alignItems: !!task.startTime ? "flexStart" : "center",
            })}
          >
            <div
              className={styles.tab({
                colors: isSelected.value ? "black" : (task.color as any),
              })}
              onMouseDown={() => {
                appContext.isPushDownEnabled.setValue(true);
              }}
            />
            {appContext.isWide && (
              <Button
                className={classNames({
                  [styles.iconRotate]: isMutationLoading,
                })}
                color="ghost"
                size={Size.md}
                rounded
                onClick={toggleComplete}
                onDoubleClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <FontAwesomeIcon
                  icon={primaryButtonIcon()}
                  style={{ width: "1rem" }}
                />
              </Button>
            )}
            <Text
              className={styles.title}
              size={Size.md}
              style={{
                textDecorationLine:
                  task.status === "COMPLETED" ? "line-through" : "none",
                whiteSpace: variant === "compact" ? "nowrap" : "normal",
              }}
            >
              {task.title} {task.emojiCode}
            </Text>
          </div>
          <div className={styles.rightGroup}>
            {task.isDeepWork && (
              <Text size={Size.sm}>
                <FontAwesomeIcon icon={faHeadphones} />
              </Text>
            )}
            {task.isRecurring && (
              <Text size={Size.sm}>
                <FontAwesomeIcon icon={faRecycle} />
              </Text>
            )}
            {task.isLocked && (
              <Text size={Size.sm}>
                <FontAwesomeIcon icon={faLock} />
              </Text>
            )}
            {task.isSuperLocked && (
              <Text size={Size.sm}>
                <FontAwesomeIcon icon={faAnchorLock} />
              </Text>
            )}
            <Text className={styles.estimate} size={Size.sm}>
              {isResizeHandleDragging.value
                ? tempDuration.value
                : task.duration}{" "}
              min
            </Text>
            <ButtonWithIcon
              color="ghost"
              icon={faEllipsis}
              ref={contextMenuRef}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                contextMenu.handleClick({
                  buttonRef: contextMenuRef,
                  options: contextMenuOptions,
                })();
              }}
            />
          </div>
        </LinealBoxLite>
        {!!task?.startTime && (
          <div
            style={{
              height: theme.space[3],
              width: "100%",
              position: "absolute",
              bottom: 0,
              left: 0,
              cursor: "row-resize",
              zIndex: 999,
            }}
            ref={resizeHandleRef}
            onDrag={(ev) => {
              const rect = resizeHandleEl.value?.getBoundingClientRect();
              const delta = ev.clientY - (rect?.top ?? 0) - 8;
              const scale = pipe(delta / 48, (steps) => Math.floor(steps)) + 1;
              const updatedDuration = pipe(
                scale,
                (scale) => scale * 10,
                (v) => v + task.duration,
                (v) => (v > 10 ? v : 10),
                (v) => (v < 110 ? v : 110)
              );
              // the ev.clientY will always go to zero when letting go of the handle
              // this will prevent tempDuration and resizeHeight from being updated
              if (ev.clientY !== 0) {
                tempDuration.setValue(updatedDuration);
                resizeHeight.setValue(
                  pipe(
                    calc(theme.space[3]).multiply(3).multiply(scale).toString()
                  )
                );
              } else {
                isComputingResizeAfterDragEnd.setValue(true);
              }
              if (isResizeHandleDragging.value === false) {
                isResizeHandleDragging.setValue(true);
              }
            }}
            onDragEnd={async (ev) => {
              const rect = resizeHandleEl.value?.getBoundingClientRect();
              const delta = ev.clientY - (rect?.top ?? 0) - 8;
              const scale = pipe(delta / 48, (steps) => Math.floor(steps)) + 1;
              const updatedDuration = pipe(
                scale,
                (scale) => scale * 10,
                (v) => v + task.duration,
                (v) => (v > 10 ? v : 10),
                (v) => (v < 110 ? v : 110)
              );

              _upsertTaskIntoQueryData({
                ...task,
                duration: updatedDuration,
              });

              // this value has to be set before updateTaskMutation
              isResizeHandleDragging.setValue(false);

              await updateTaskMutation.mutateAsync({
                ...task,
                duration: updatedDuration,
              });
              isComputingResizeAfterDragEnd.setValue(false);
            }}
          />
        )}
      </motion.div>
    </>
  );
};
