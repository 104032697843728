import {
  Minutes,
  ScheduleTaskDto,
  Task,
} from "@dulce/models/dist/tasks.models";
import { FPArray, pipe, TE, O, ID, A, flow } from "@dulce/prelude";
import { CSSProperties, useContext } from "react";
import { useDrop } from "react-dnd";
import { isHotkeyPressed } from "react-hotkeys-hook";
import { AppContext } from "../../../../../AppContext";
import { useTasks, useTasksMutations } from "../../../../../hooks/useTasks";
import { sdk } from "../../../../../sdk";
import { useValue } from "../../../../../utils/useValue";
import * as styles from "./TaskTimeslot.css";

export type TaskTimeslotProps = {
  startTime: Minutes;
  style: CSSProperties;
};
export const TaskTimeslot = (props: TaskTimeslotProps) => {
  const { scheduleTaskMutation, addTaskMutation } = useTasksMutations();
  const appContext = useContext(AppContext);
  const tasks = useTasks();
  const isDropActive = useValue<boolean>(false);
  const drop = useDrop(
    {
      accept: "task",
      drop: async (item: { id: string }, monitor) => {
        const payload: ScheduleTaskDto = {
          id: item.id,
          startTime: props.startTime,
          enablePushDown: appContext.isPushDownEnabled.value,
        };

        // @techdebt: clean this multi-select logic up

        const selectedTaskIds = sdk.selection.queries.listTasks();

        if (selectedTaskIds.length !== 0) {
          const selectedTasks = pipe(
            selectedTaskIds,
            FPArray.map(sdk.tasks.queries.selectTaskById(tasks.data ?? [])),
            FPArray.filter(sdk.tasks.helpers.isTask),
            FPArray.filter(sdk.tasks.helpers.hasStartTime),
            FPArray.sort(sdk.tasks.helpers.tasksSortingStrategy.byStartTime)
          );

          const selectedUnscheduledTasks = pipe(
            selectedTaskIds,
            FPArray.map(sdk.tasks.queries.selectTaskById(tasks.data ?? [])),
            FPArray.filter(sdk.tasks.helpers.isTask),
            FPArray.filter(sdk.tasks.helpers.hasNoStartTime)
          );

          const delta: Minutes = pipe(
            selectedTasks,
            FPArray.head,
            O.map((task) => props.startTime - task.startTime),
            O.match(() => 0, ID.of)
          );

          const payloads = pipe(
            selectedTasks,
            FPArray.map(
              (task): ScheduleTaskDto => ({
                id: task.id,
                startTime: task.startTime + delta,
                enablePushDown: false,
              })
            )
          );

          const unscheduledPayloads = pipe(
            selectedUnscheduledTasks,
            FPArray.reduce<Task, Array<Task>>([], (prev, task) => {
              const lastPayload = pipe(payloads, FPArray.last);

              const lastScheduledPayloadInfo = pipe(
                lastPayload,
                O.map((v) =>
                  pipe(
                    selectedTasks,
                    FPArray.findFirst((task) => v.id === task.id),
                    O.match(
                      () => ({ startTime: props.startTime, duration: 0 }),
                      (task) => ({
                        startTime: v.startTime ?? 0,
                        duration: task.duration,
                      })
                    )
                  )
                ),
                O.match(
                  () => ({ startTime: props.startTime, duration: 0 }),
                  ID.of
                )
              );

              const updatedStartTime = pipe(
                prev,
                FPArray.last,
                O.match(
                  () =>
                    lastScheduledPayloadInfo.startTime +
                    lastScheduledPayloadInfo.duration,
                  (task) => (task.startTime ?? props.startTime) + task.duration
                )
              );

              return [
                ...prev,
                {
                  ...task,
                  startTime: updatedStartTime,
                },
              ];
            }),
            FPArray.map<Task, ScheduleTaskDto>((task) => ({
              id: task.id,
              enablePushDown: false,
              startTime: task.startTime,
            }))
          );

          payloads.forEach((payload) =>
            pipe(
              payload,
              TE.of,
              TE.chain(
                TE.tryCatchK(
                  scheduleTaskMutation.mutateAsync,
                  (reason) => new Error(String(reason))
                )
              )
            )()
          );

          unscheduledPayloads.forEach((payload) =>
            pipe(
              payload,
              TE.of,
              TE.chain(
                TE.tryCatchK(
                  scheduleTaskMutation.mutateAsync,
                  (reason) => new Error(String(reason))
                )
              )
            )()
          );

          sdk.selection.commands.unselectAllTasks();
        } else {
          const ALT_KEY_CODE = 18;
          // TODO: figure out how to make sure to detect ALT key after dragging is starting
          const isAltKeyPressed = isHotkeyPressed(ALT_KEY_CODE);
          // TODO: refine duplicate logic to
          // - use duplicate endpoint
          // - use duplicate modal if minitasks are present
          if (isAltKeyPressed) {
            const foundTasks = tasks.data ?? [];
            const foundTask = sdk.tasks.queries.selectTaskById(foundTasks)(
              payload.id
            );
            if (foundTask) {
              await addTaskMutation.mutateAsync({
                ...foundTask,
                startTime: props.startTime,
              });
            }
          } else {
            await pipe(
              payload as any,
              TE.of,
              TE.chain(
                TE.tryCatchK(
                  scheduleTaskMutation.mutateAsync,
                  (reason) => new Error(String(reason))
                )
              )
            )();
          }
          appContext?.isPushDownEnabled.setValue(false);
          isDropActive.setValue(false);
        }
      },
    },
    [appContext.isPushDownEnabled.value]
  )[1];

  return (
    <div
      className={styles.root({ dropActive: isDropActive.value })}
      ref={drop}
      onDragOver={() => isDropActive.setValue(true)}
      onDragLeave={() => isDropActive.setValue(false)}
      style={props.style}
    ></div>
  );
};
