import * as styles from "./TasksLayer.css";
import { FPArray, pipe } from "@dulce/prelude";
import { sdk } from "../../../../sdk";
import { Task } from "../../../Task/Task";
import { useContext } from "react";
import { TimeboxQueryContext } from "../../../../context/TimeboxContext";
import { calc } from "@vanilla-extract/css-utils";
import { theme } from "../../../../theme.css";
import { AnimatePresence, motion } from "framer-motion";
import { useTasks } from "../../../../hooks/useTasks";

export type TasksLayerProps = {};
export const TasksLayer = (props: TasksLayerProps) => {
  const tasksQuery = useTasks();
  const timeboxQueryContext = useContext(TimeboxQueryContext);
  const trackInfo = timeboxQueryContext?.getTimeboxTrackInfo();
  const topOffset = theme.space[2];
  const scheduledTasks = pipe(
    tasksQuery.data ?? [],
    sdk.tasks.queries.getScheduledTasks,
    FPArray.sort(sdk.tasks.helpers.tasksSortingStrategy.byStartTime),
    FPArray.map((task) => {
      return (
        <motion.div
          key={task.id}
          initial={{
            top: 0,
            y: pipe(
              trackInfo?.startTime ?? 0,
              (startTime) => (task.startTime ?? 0) - startTime,
              (delta) => delta / (trackInfo?.stepSize ?? 10),
              (steps) =>
                calc(theme.space[3])
                  .multiply(3)
                  .multiply(steps)
                  .add(topOffset)
                  .toString()
            ),
          }}
          animate={{
            top: 0,
            y: pipe(
              trackInfo?.startTime ?? 0,
              (startTime) => (task.startTime ?? 0) - startTime,
              (delta) => delta / (trackInfo?.stepSize ?? 10),
              (steps) =>
                calc(theme.space[3])
                  .multiply(3)
                  .multiply(steps)
                  .add(topOffset)
                  .toString()
            ),
          }}
          style={{
            position: "absolute",
            display: "flex",
            justifyContent: "flex-end",
            width: "100%",
            maxWidth: "80%",
            right: 0,
            height: pipe(
              task.duration,
              (v) => v / (trackInfo?.stepSize ?? 10),
              (steps) =>
                calc(theme.space[3])
                  .multiply(3)
                  .multiply(steps)
                  .add("1px")
                  .toString()
            ),
          }}
        >
          <Task id={task.id} variant="timeline" />
        </motion.div>
      );
    })
  );

  return (
    <div className={styles.root}>
      <AnimatePresence>{scheduledTasks}</AnimatePresence>
    </div>
  );
};
