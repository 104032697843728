import { Size, Text, theme } from "@dulce/design-system";
import { useValue } from "../../../utils/useValue";
import * as styles from "./ColorPicker.css";
import {
  ColorRadioSquare,
  ColorRadioSquareProps,
} from "./ColorRadioSquare/ColorRadioSquare";

export type ColorPickerProps = {
  name: string;
  includeBlank?: boolean;
};

export const ColorPicker = ({
  name,
  includeBlank = false,
}: ColorPickerProps) => {
  const isFocused = useValue<boolean>(false);

  let colors: Array<ColorRadioSquareProps> = [
    {
      backgroundColor: theme.colors.gray[1],
      borderColor: theme.colors.gray[7],
      tabColor: theme.colors.gray[4],
      colorName: "gray",
      name,
    },
    {
      backgroundColor: theme.colors.blue[1],
      borderColor: theme.colors.blue[7],
      tabColor: theme.colors.blue[3],
      colorName: "blue",
      name,
    },
    {
      backgroundColor: theme.colors.green[1],
      borderColor: theme.colors.green[7],
      tabColor: theme.colors.green[3],
      colorName: "green",
      name,
    },
    {
      backgroundColor: theme.colors.red[1],
      borderColor: theme.colors.red[7],
      tabColor: theme.colors.red[3],
      colorName: "red",
      name,
    },
    {
      backgroundColor: theme.colors.purple[1],
      borderColor: theme.colors.purple[7],
      tabColor: theme.colors.purple[3],
      colorName: "purple",
      name,
    },
    {
      backgroundColor: theme.colors.yellow[1],
      borderColor: theme.colors.yellow[6],
      tabColor: theme.colors.yellow[4],
      colorName: "yellow",
      name,
    },
  ];

  if (includeBlank) {
    colors = [
      ...colors,
      {
        backgroundColor: theme.colors.white,
        borderColor: theme.colors.gray[4],
        tabColor: theme.colors.gray[2],
        colorName: "",
        name,
      },
    ];
  }
  return (
    <div
      className={styles.root({
        focused: isFocused.value,
      })}
      onFocus={() => isFocused.setValue(true)}
      onBlur={() => isFocused.setValue(false)}
    >
      <Text size={Size.sm}>Pick a color</Text>
      <div role="group" className={styles.colors}>
        {colors.map((props) => (
          <ColorRadioSquare {...props} />
        ))}
      </div>
    </div>
  );
};
