import { Text } from "@dulce/design-system";
import { Task } from "@dulce/models/dist/tasks.models";
import {
  faRightToBracket,
  faAnglesLeft,
  faClockRotateLeft,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ContextMenuOption } from "../../../../foundation/ContextMenu/useContextMenu";
import { useTasksMutations } from "../../../../hooks/useTasks";
import { Size } from "../../../../theme.css";
import { useTaskModals } from "../../TaskModals";

export type GetSchedulingOptionsOpts = {
  task: Task;
  taskModals: ReturnType<typeof useTaskModals>
  unscheduleTaskMutation: ReturnType<
    typeof useTasksMutations
  >["unscheduleTaskMutation"];
};

export const getSchedulingOptions = (
  opts: GetSchedulingOptionsOpts
): Array<ContextMenuOption> => {
  const { task, unscheduleTaskMutation, taskModals } = opts;
  const scheduleOption: ContextMenuOption = {
    content: (
      <Text size={Size.sm}>
        <FontAwesomeIcon icon={faRightToBracket} /> Schedule
      </Text>
    ),
    name: "schedule",
    onClick: taskModals.openSchedulingModal,
  };
  const unscheduleOption = {
    content: (
      <Text size={Size.sm}>
        <FontAwesomeIcon icon={faAnglesLeft} /> Unschedule
      </Text>
    ),
    name: "unschedule",
    onClick: () => unscheduleTaskMutation.mutateAsync({ id: task.id }),
    isDisabled: task.isLocked,
  };
  const rescheduleOption: ContextMenuOption = {
    content: (
      <Text size={Size.sm}>
        <FontAwesomeIcon icon={faClockRotateLeft} /> Reschedule
      </Text>
    ),
    name: "reschedule",
    onClick: taskModals.openSchedulingModal,
    isDisabled: task.isLocked,
  };

  if (task.startTime) {
    return [rescheduleOption, unscheduleOption];
  }

  return [scheduleOption];
};
