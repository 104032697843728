import { Minutes, Task } from "@dulce/models/dist/tasks.models";
import { FPArray, pipe } from "@dulce/prelude";
import React, { ReactNode, useCallback, useMemo } from "react";
import { useQuery } from "@tanstack/react-query";
import { useTasks } from "../hooks/useTasks";
import { sdk } from "../sdk";

export type SuggestedTasksQueryContextValue =
  | {
      getSuggestedTasksByStartTime: (startTime: Minutes) => Array<Task>;
      isTimeSlotFilled: (timeSlot: Minutes) => boolean;
    }
  | undefined;

export const SuggestedTasksQueryContext =
  React.createContext<SuggestedTasksQueryContextValue>(undefined);
export const SuggestedTasksCommandContext = React.createContext(undefined);

export type SuggestedTasksContextProviderProps = {
  children: ReactNode | ReactNode[];
};

const getTimeslotFillMap =
  (timeboxStartTime: Minutes, timeboxDuration: Minutes) =>
  (tasks: Array<Task>): Record<Minutes, boolean> =>
    pipe(
      new Array(timeboxDuration / 10).fill(""),
      FPArray.mapWithIndex((index) => timeboxStartTime + index * 10),
      FPArray.reduce({}, (prev, currentTime) => {
        return {
          ...prev,
          [currentTime]: !pipe(
            tasks,
            FPArray.filter((tasks) => !!tasks.startTime),
            FPArray.every((task) => {
              if (task) {
                if (task.startTime) {
                  const taskStartTime = task.startTime as Minutes;
                  const taskEndTime = taskStartTime + task.duration;
                  if (
                    taskStartTime <= currentTime &&
                    currentTime < taskEndTime
                  ) {
                    return false;
                  }
                  return true;
                } else {
                  console.error("wrong", task);
                }
              }
              return true;
            })
          ),
        };
      })
    );
export const SuggestedTasksContextProvider = (
  props: SuggestedTasksContextProviderProps
) => {
  const queryKey = ["suggested-tasks"];
  const suggestedTasksQuery = useQuery(
    queryKey,
    sdk.tasks.queries.findSuggestedTasks
  );
  const tasksQuery = useTasks();
  const timeboxStartTime: Minutes = sdk.utils.time.hoursToMinutes(6);
  const timeboxDuration: Minutes = sdk.utils.time.hoursToMinutes(18);
  const getFn = useMemo(
    () => getTimeslotFillMap(timeboxStartTime, timeboxDuration),
    [timeboxStartTime, timeboxDuration]
  );
  const suggestedTaskCollisionMap = useMemo(
    () => getFn(tasksQuery.data ?? []),
    [tasksQuery.data, getFn]
  );
  const isTimeSlotFilled = (timeSlot: Minutes) =>
    suggestedTaskCollisionMap[timeSlot];
  const getSuggestedTasksByStartTime = useCallback(
    (startTime: Minutes) => {
      return suggestedTasksQuery.data?.[startTime] ?? [];
    },
    [suggestedTasksQuery.data]
  );

  const queryValue = {
    getSuggestedTasksByStartTime,
    isTimeSlotFilled,
  };

  return (
    <SuggestedTasksQueryContext.Provider value={queryValue}>
      <SuggestedTasksCommandContext.Provider value={undefined}>
        {props.children}
      </SuggestedTasksCommandContext.Provider>
    </SuggestedTasksQueryContext.Provider>
  );
};
