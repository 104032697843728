import { useAnimationFrame } from "framer-motion";
import React, { ReactNode, useEffect, useMemo, useRef } from "react";
import { CreateConfettiEntityOpts, RotationDirection, World } from ".";
import { LogoProps } from "../components/Logo/Logo";
import { getRandomArbitrary, getRandomInt } from "../utils/getRandom";

type StartCompleteAnimationOpts = {
  color: LogoProps["color"];
  origin: {
    x: number;
    y: number;
  };
};

export type ConfettiContextValue = {
  worldCanvasRef?: React.RefObject<HTMLCanvasElement>;
  world?: {
    run: (delta: number) => void;
    setWidth: (v: number) => void;
    setHeight: (v: number) => void;
    setContext: (v: CanvasRenderingContext2D) => void;
    addEntity: (opts: CreateConfettiEntityOpts) => void;
  };
  startCompleteAnimation?: (opts: StartCompleteAnimationOpts) => void;
};
export const ConfettiContext = React.createContext<ConfettiContextValue>({});

/**
 * This method will throttle the animation callback to run at the 30 fps
 */
const withFpsThrottle = (function () {
  const fps = 120; // fps cap
  let interval = 1000 / fps;
  let delta = 0;

  type AnimationCallback = (delta: number) => void;

  return (animationCallback: AnimationCallback) =>
    (timestamp: number, deltaChunk: number) => {
      delta += deltaChunk;
      if (delta < interval) return;
      animationCallback(delta);
      delta = 0;
    };
})();

export type ConfettiContextProviderProps = {
  children: ReactNode[] | ReactNode;
};
export const ConfettiContextProvider = (
  props: ConfettiContextProviderProps
) => {
  const world = useMemo(() => World({}), []);
  const worldCanvasRef = useRef<HTMLCanvasElement>(null);
  useEffect(() => {
    const canvas = worldCanvasRef.current;
    if (canvas) {
      let context = canvas.getContext("2d");
      if (context) {
        world.setContext(context);
      }
      world.setHeight(canvas.height);
      world.setWidth(canvas.width);
    }
  }, [worldCanvasRef]);

  // this will run request animation frame with a throttled world.run
  useAnimationFrame(withFpsThrottle(world.run));

  const startCompleteAnimation = (opts: StartCompleteAnimationOpts) => {
    const getRandomBool = () => !!getRandomInt(0, 2);
    world.addEntity({
      image: opts.color,
      position: {
        initX: opts.origin.x,
        initY: opts.origin.y,
      },
      rotation: {
        direction: RotationDirection.RIGHT,
        position: 0,
        speed: getRandomArbitrary(0, 4) * (getRandomBool() ? -1 : 1),
      },
      velocity: {
        initY: -getRandomInt(7, 12),
        initX: getRandomArbitrary(1, 4),
      },
    });
    world.addEntity({
      image: opts.color,
      position: {
        initX: opts.origin.x,
        initY: opts.origin.y,
      },
      rotation: {
        direction: RotationDirection.RIGHT,
        position: 0,
        speed: getRandomArbitrary(0, 4) * (getRandomBool() ? -1 : 1),
      },
      velocity: {
        initY: -getRandomInt(7, 12),
        initX: getRandomArbitrary(0, 2) * (getRandomBool() ? -1 : 1),
      },
    });
    world.addEntity({
      image: opts.color,
      position: {
        initX: opts.origin.x,
        initY: opts.origin.y,
      },
      rotation: {
        direction: RotationDirection.RIGHT,
        position: 0,
        speed: getRandomArbitrary(0, 4) * (getRandomBool() ? -1 : 1),
      },
      velocity: {
        initY: -getRandomInt(7, 12),
        initX: -getRandomArbitrary(1, 4),
      },
    });
  };

  const value = {
    worldCanvasRef,
    world,
    startCompleteAnimation,
  };

  return (
    <ConfettiContext.Provider value={value}>
      {props.children}
    </ConfettiContext.Provider>
  );
};

