import { UpdateUserPreferencesDto, User } from "@dulce/models/dist/users.models"
import { config } from "../../config"
import { authAxios } from "../../utils/authAxios"

const baseUrl = config.api.url

export const updateMyPreferences = async (dto: UpdateUserPreferencesDto) => authAxios.request<User>({
  method: "POST",
  url: `${baseUrl}/users/update-my-preferences`,
  data: dto
}).then(res => res.data)
