import { close, open } from "../../foundation/Modal/useModal";
import { useUser, useUserMutation } from "../../hooks/useUser";
import * as styles from "./EditTaskDetailsTemplateForm.css";
import { useFormik, FormikProvider } from "formik";
import { Boolean, O, pipe, TE } from "@dulce/prelude";
import { Preferences } from "@dulce/models/dist/users.models";
import { getErrorReason } from "../../utils/getErrorReason";
import { ModalContent } from "../../foundation/Modal/Modal";
import { Flex, Size } from "@dulce/design-system";
import { lazy, Suspense } from "react";
import { LazyLoadingIndicator } from "../../foundation/LazyLoadingIndicator/LazyLoadingIndicator";
import { ButtonWithIcon } from "../../foundation/ButtonWithIcon/ButtonWithIcon";
import { faSave } from "@fortawesome/free-regular-svg-icons";
import { Details } from "@dulce/models/dist/tasks.models";

const LazyDulceEditor = lazy(async () => {
  const { DulceEditor } = await import(
    "../../foundation/DulceEditor/DulceEditor"
  );
  return { default: DulceEditor };
});

const getDefaultDetails = () =>
  [{ type: "paragraph", children: [{ text: "" }] }] as Details;

export type EditTaskDetailsTemplateFormProps = {
  onClose?: Function;
};

type FormValues = {
  details: Details;
};

export const EditTaskDetailsTemplateForm = (
  props: EditTaskDetailsTemplateFormProps
) => {
  const { selfQuery } = useUser();
  const userPreferences = selfQuery.data?.preferences;
  const userMutations = useUserMutation();
  const formik = useFormik<FormValues>({
    initialValues: {
      details: pipe(
        userPreferences?.taskDetailsTemplate?.details as Details | undefined,
        O.fromNullable,
        O.getOrElse(getDefaultDetails),
        (v) => (v.length ? v : getDefaultDetails())
      ),
    },
    onSubmit: async (values) =>
      pipe(
        values,
        TE.of,
        TE.map(
          (v): Preferences => ({
            ...userPreferences,
            taskDetailsTemplate: {
              isEnabled: !!userPreferences?.taskDetailsTemplate?.isEnabled,
              details: v.details,
            },
          })
        ),
        TE.chain(
          TE.tryCatchK(
            userMutations.updateMyPreferences.mutateAsync,
            getErrorReason
          )
        ),
        TE.chainFirst(() => {
          props?.onClose?.();
          return TE.right(true);
        })
      )(),
  });
  return (
    <FormikProvider value={formik}>
      <form onSubmit={formik.handleSubmit} className={styles.root}>
        <ModalContent
          content={
            <>
              <Flex column gap={Size.lg}>
                <Suspense fallback={<LazyLoadingIndicator />}>
                  <LazyDulceEditor
                    onChange={(v) => formik.setFieldValue("details", v)}
                    initalValue={formik.initialValues.details as any} // TODO: figure out why compiler is freaking out, might be the chain of dt.fallbacks in models
                    styleApi={{ color: "gray" }}
                  />
                </Suspense>
              </Flex>
            </>
          }
          footer={
            <>
              <Flex justifyContent="flex-end">
                <ButtonWithIcon
                  color="primary"
                  icon={faSave}
                  disabled={userMutations.updateMyPreferences.isLoading}
                >
                  {pipe(
                    userMutations.updateMyPreferences.isLoading,
                    Boolean.fold(
                      () => "Save",
                      () => "Submitting"
                    )
                  )}
                </ButtonWithIcon>
              </Flex>
            </>
          }
        />
      </form>
    </FormikProvider>
  );
};

export const openEditTaskDetailsTemplateFormModal = () => {
  open({
    title: "Edit Template for Task Details",
    children: (
      <>
        <EditTaskDetailsTemplateForm onClose={close} />
      </>
    ),
  });
};
