import * as tasks from "./tasks";
import * as users from "./users";
import * as utils from "./utils";
import * as selection from "./selection";
import * as oembed from "./oembed";
import * as thirdPartyCalendars from "./third-party-calendars";
import * as integrations from "./integrations";

export const sdk = {
  tasks,
  users,
  utils,
  selection,
  oembed,
  thirdPartyCalendars,
  integrations,
};
(window as any).sdk = sdk;

export type DulceSdk = typeof sdk;
