import { RefObject, useEffect } from "react";

export type UsePassiveScrollOpts = {
  handler: (e: any) => void | Promise<void>;
  ref: RefObject<HTMLElement> | undefined;
};
export const usePassiveScroll = ({ handler, ref }: UsePassiveScrollOpts) => {
  useEffect(() => {
    const scrollContainer = ref?.current;
    if (scrollContainer) {
      scrollContainer.addEventListener("scroll", handler, { passive: true });
      return () => {
        scrollContainer.removeEventListener("scroll", handler);
      };
    }
  }, [ref?.current, handler]);
};

export const usePassiveWheel = ({ handler, ref }: UsePassiveScrollOpts) => {
  useEffect(() => {
    const scrollContainer = ref?.current;
    if (scrollContainer) {
      scrollContainer.addEventListener("wheel", handler, { passive: true });
      return () => {
        scrollContainer.removeEventListener("wheel", handler);
      };
    }
  }, [ref?.current, handler]);
};
