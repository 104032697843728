import * as iots from "io-ts"

interface ValidUrlBrand {
  readonly ValidUrl: unique symbol
}

export const ValidUrl = iots.brand(
  iots.string,
  (value: string): value is iots.Branded<string, ValidUrlBrand> => {
    const urlPattern = new RegExp('^(https?:\\/\\/)?' + // validate protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // validate domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // validate OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // validate port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // validate query string
      '(\\#[-a-z\\d_]*)?$', 'i'); // validate fragment locator
    const fallbackCheck = (v: string) => {
      try {
        return Boolean(new URL(v))
      } catch (error) {
        return false
      }
    }
    return !!urlPattern.test(value) || fallbackCheck(value);
  },
  "ValidUrl"
)