import React, {
  ReactNode,
  RefObject,
  useCallback,
  useEffect,
  useRef,
} from "react";
import { noop } from "../../utils/noop";
import { useValue, UseValueResult } from "../../utils/useValue";
import { captureCanvasFromHtml } from "./utils/captureCanvasFromHtml";
import { captureStreamFromCanvas } from "./utils/captureStreamFromCanvas";
import { useInterval } from "react-use";
import { sleep } from "../../utils/sleep";

export type FocusBoxContextValue = {
  htmlRef: RefObject<HTMLElement | null>;
  canvasRef: RefObject<HTMLCanvasElement | null>;
  videoRef: RefObject<HTMLVideoElement | null>;
  startFocusBox: () => void;
  stopFocusBox: () => void;
  isEnabled: UseValueResult<boolean>;
};

export const initValue: FocusBoxContextValue = {
  htmlRef: React.createRef(),
  canvasRef: React.createRef(),
  videoRef: React.createRef(),
  startFocusBox: noop,
  stopFocusBox: noop,
  isEnabled: {
    value: false,
    setValue: noop,
  },
};

export const FocusBoxContext =
  React.createContext<FocusBoxContextValue>(initValue);

export type FocusBoxProviderProps = {
  children: ReactNode;
};
export const FocusBoxProvider = (props: FocusBoxProviderProps) => {
  const isEnabled = useValue(false);

  let value: FocusBoxContextValue = {
    ...initValue,
    videoRef: useRef<HTMLVideoElement>(null),
    canvasRef: useRef<HTMLCanvasElement>(null),
    htmlRef: useRef<HTMLElement>(null),
    isEnabled,
  };

  const tempCanvas = useRef<HTMLCanvasElement>()

  useInterval(async () => {
    const canvas = value.canvasRef.current;
    const video = value.videoRef.current;
    const html = value.htmlRef.current;
    if (isEnabled.value && canvas && video && html) {
      tempCanvas.current = await captureCanvasFromHtml({ canvas, html, video });
    }
  }, 1000);

  const drawImage = useCallback(async () => {
    requestAnimationFrame(drawImage)
    const canvas = value.canvasRef.current;
    const video = value.videoRef.current;
    const html = value.htmlRef.current;
    if (canvas && video && html && tempCanvas.current) {
      const ctx = canvas.getContext("2d");
      ctx?.drawImage(tempCanvas.current, 0, 0);
    }
  }, [value.canvasRef, value.videoRef, value.htmlRef, tempCanvas.current]);

  const initStream = useCallback(() => {
    const video = value.videoRef.current;
    const canvas = value.canvasRef.current;
    const html = value.htmlRef.current;
    if (video && canvas && html) {
      console.log('initStream:captureStreamFromCanvas')
      captureStreamFromCanvas({
        canvas,
        html,
        video,
        // currently there is no cleanup, so memory weird behavior could happen
        onLoadedMetadata: async () => {
          console.log('onLoadedMetadata')
          video.play();
          await sleep(1000);
          video.requestPictureInPicture();
        },
      });
    } else {
      console.error("Streaming elements are unavailable:", {
        canvas,
        html,
        video,
      });
    }
  }, [value]);

  const startFocusBox = useCallback(() => {
    initStream();
    drawImage();
    isEnabled.setValue(true);
  }, [initStream, drawImage]);

  const stopFocusBox = useCallback(() => {
    isEnabled.setValue(false);
  }, [isEnabled.value]);

  useEffect(() => {
    const video = value.videoRef.current;
    if (isEnabled.value === false && video?.srcObject) {
      video.srcObject = null;
      document.exitPictureInPicture();
    }
  }, [isEnabled.value]);

  useEffect(() => {
    const video = value.videoRef.current;
    if (video) {
      const _leavePipHandler = () => {
        console.log("leaving pip...")
        isEnabled.setValue(false);
      };
      video.addEventListener("leavepictureinpicture", _leavePipHandler);
      return () => {
        video.removeEventListener("leavepictureinpicture", _leavePipHandler);
      };
    }
  }, [value.videoRef]);

  value = {
    ...value,
    startFocusBox,
    stopFocusBox,
  };

  return (
    <FocusBoxContext.Provider value={value}>
      {props.children}
    </FocusBoxContext.Provider>
  );
};
