import { Minutes, Task, TaskId } from "@dulce/models/dist/tasks.models";
import { FPArray, Ord, pipe } from "@dulce/prelude";
import { config } from "../../config";
import { authAxios } from "../../utils/authAxios";
import { tasksSortingStrategy } from "./Tasks.helpers";

const baseUrl = config.api.url;

const hasStartTime = (task: Task) => !!task.startTime;

export const findMyTasks = async () =>
  authAxios
    .request<Array<Task>>({
      method: "GET",
      url: `${baseUrl}/tasks/find-my-tasks`,
    })
    .then((res) => res.data);

export const findMyTaskById = async (id: TaskId) =>
  authAxios
    .request<Task>({
      method: "GET",
      url: `${baseUrl}/tasks/find-my-task/${id}`,
    })
    .then((res) => res.data);

export const findSuggestedTasks = async () =>
  authAxios
    .request<Record<Minutes, Array<Task>>>({
      method: "GET",
      url: `${baseUrl}/tasks/find-suggested-tasks`,
    })
    .then((res) => res.data);

export const getUpcomingTasks = (tasks: Array<Task>, currentTime: Minutes) =>
  pipe(
    tasks,
    FPArray.filter(hasStartTime),
    FPArray.filter((task) => (task.startTime as Minutes) > currentTime),
    FPArray.sortBy([tasksSortingStrategy.byStartTime])
  );

export const getCurrentTask = (tasks: Array<Task>, currentTime: Minutes): Task | null => {
  let foundTasks = pipe(
    tasks,
    FPArray.filter(hasStartTime),
    FPArray.filter((task) => {
      let startTime = task.startTime as Minutes;
      let endTime = startTime + task.duration;
      return currentTime >= startTime && currentTime < endTime;
    })
  );
  return foundTasks?.[0] ?? null;
};

export const getNextTaskByTime = (tasks: Array<Task>, time: Minutes): Task | null => {
  let foundTasks = pipe(
    tasks,
    FPArray.filter(hasStartTime),
    (tasks) =>
      tasks.filter((task) => {
        let startTime = task.startTime ?? 0;
        // let endTime = startTime + task.duration
        const isBelowActiveLine = startTime > time;
        return isBelowActiveLine;
      }),
    (tasks) =>
      tasks.sort((taskA, taskB) => {
        const startTimeA = taskA.startTime ?? 0;
        const startTimeB = taskB.startTime ?? 0;
        if (startTimeA > startTimeB) {
          return 1;
        } else {
          return -1;
        }
      })
  );
  return foundTasks?.[0] ?? null;
};

export const getUnscheduledTasks = (tasks: Array<Task>) =>
  pipe(
    tasks,
    FPArray.filter((task) => !task.startTime)
  );

export const getScheduledTasks = (tasks: Array<Task>) =>
  pipe(tasks, FPArray.filter(hasStartTime));

export const selectNextTask = (startTime: Minutes) => (tasks: Array<Task>) =>
  pipe(
    tasks,
    FPArray.filter(hasStartTime),
    FPArray.filter((task) => (task.startTime as Minutes) > startTime),
    FPArray.sort(
      Ord.fromCompare<Task>((taskA, taskB) =>
        (taskA.startTime as Minutes) > (taskB.startTime as Minutes) ? -1 : 1
      )
    ),
    (tasks) => tasks?.[0]
  );

export const selectMostRecentTask =
  (startTime: Minutes) => (tasks: Array<Task>) =>
    pipe(
      tasks,
      FPArray.filter(hasStartTime),
      FPArray.filter((task) => (task.startTime as Minutes) < startTime),
      FPArray.sort(
        Ord.fromCompare<Task>((taskA, taskB) =>
          (taskA.startTime as Minutes) > (taskB.startTime as Minutes) ? -1 : 1
        )
      ),
      (tasks) => tasks?.[0]
    );

export const selectTaskByStartTime =
  (startTime: Minutes) => (tasks: Array<Task>) =>
    pipe(
      tasks,
      FPArray.findFirst((task) => task.startTime === startTime)
    );

export const selectLastScheduledTask = (tasks: Array<Task>) =>
  pipe(
    tasks,
    FPArray.filter(hasStartTime),
    FPArray.sort(tasksSortingStrategy.byStartTime),
    FPArray.last
  );

export const selectTaskById = (tasks: Array<Task>) => (taskId: TaskId) => tasks.find(task => task.id === taskId)