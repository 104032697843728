import classNames from "classnames";
import * as styles from "./LinealBoxLite.css";

import {
  createElement,
  forwardRef,
  ReactHTML,
  ReactNode,
  RefCallback,
  RefObject,
} from "react";

export type LinealBoxLiteProps = {
  as?: keyof ReactHTML;
  children: ReactNode;
  radius?: number;
  className?: string;
  outerClassName?: string;
  styleApi?: Parameters<typeof styles.root>[0];
  outerProps?: any;
  outerRef?: RefObject<HTMLDivElement> | RefCallback<HTMLDivElement>;
  ref?: RefObject<HTMLDivElement> | RefCallback<HTMLDivElement>;
  innerProps?: any;
};

export const LinealBoxLite = forwardRef(
  (props: Omit<LinealBoxLiteProps, "ref">, ref) => {
    const { outerClassName, outerRef, radius, innerProps } = props;
    const contentClassname = classNames(styles.content, props.className);
    const outerStyles = classNames(
      styles.root({ color: props?.styleApi?.color }),
      outerClassName
    );
    return createElement(
      props.as ?? "div",
      {
        ...props.outerProps,
        className: outerStyles,
        ref: outerRef,
      },
      <>
        <div
          style={{ borderRadius: radius }}
          className={styles.backdropPrimary({
            color: props.styleApi?.color,
            striped: props.styleApi?.striped,
          })}
        ></div>
        <div
          {...innerProps}
          className={contentClassname}
          ref={ref}
          style={{
            ...(innerProps?.style ?? {}),
            borderRadius: radius,
          }}
        >
          {props.children}
        </div>
      </>
    );
  }
);
