import {
  lazy,
  Suspense,
  useCallback,
  useContext,
  useEffect,
  useRef,
} from "react";
import { Routes, Route } from "react-router-dom";
import { Heading } from "./foundation/Heading/Heading";
import { getSuperTokensRoutesForReactRouterDom } from "supertokens-auth-react";
import * as ReactRouterDOM from "react-router-dom";
import { SessionAuth } from "supertokens-auth-react/recipe/session";

import { flow, pipe } from "@dulce/prelude";
import { useTasks, useTasksMutations } from "./hooks/useTasks";
import { AppBar } from "./components/AppBar/AppBar";
import {
  TaskListFilterContext,
  TaskListSortBy,
} from "./components/TaskList/FilterContext";
import { useBrowserNotifications } from "./useBrowserNotifications";
import { useInterval } from "react-use";
import {
  faDeleteLeft,
  faFilter,
  faFilterCircleXmark,
  faLink,
  faPlus,
  faStopwatch,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AppContext } from "./AppContext";
import { toast } from "react-hot-toast";
import { AppLayout } from "./components/AppLayout/AppLayout";
import { ContextBox } from "./components/ContextBox/ContextBox";
import { SingleViewLayout } from "./components/SingleViewLayout/SingleViewLayout";
import { TimeboxStats } from "./components/TimeboxStats/TimeboxStats";
import { SplitViewLayout } from "./components/SplitViewLayout/SplitViewLayout";
import { sdk } from "./sdk";
import {
  TimeboxCommandContext,
  TimeboxQueryContext,
} from "./context/TimeboxContext";
import { useValue } from "./utils/useValue";
import { useHotkeys } from "react-hotkeys-hook";
import { FocusBox } from "./components/FocusBox/FocusBox";
import { ConfettiCanvas } from "./confetti/ConfettiCanvas/ConfettiCanvas";
import { Size, Text, theme, Button, Flex } from "@dulce/design-system";
import { ContextMenuOption } from "./foundation/ContextMenu/useContextMenu";
import { openCreateTaskModal } from "./foundation/Modal/modals/openCreateTaskModal";
import { LazyLoadingIndicator } from "./foundation/LazyLoadingIndicator/LazyLoadingIndicator";
import { openFilterModal } from "./components/TaskList/InboxFilterForm/openFilterModal";
import { HotkeyLabel } from "./components/HotkeyLabel/HotkeyLabel";
import { speak } from "./utils/speak";
import { useUser } from "./hooks/useUser";
import { openThirdPartyCalendarsModal } from "./components/ThirdPartyCalendars/ThirdPartyCalendars";
import { TaskDragLayer } from "./components/TaskDragLayer/TaskDragLayer";
import { ButtonWithIcon } from "./foundation/ButtonWithIcon/ButtonWithIcon";

const LazyTimebox = lazy(async () => {
  const { Timebox } = await import("./components/Timebox/Timebox");
  return { default: Timebox };
});

const LazyTaskList = lazy(async () => {
  const { TaskList } = await import("./components/TaskList/TaskList");
  return { default: TaskList };
});

function App() {
  const rootLayoutRef = useRef<HTMLDivElement>(null);
  const taskListFilter = useContext(TaskListFilterContext);
  const timeboxCommandContext = useContext(TimeboxCommandContext);
  const timeboxQueryContext = useContext(TimeboxQueryContext);
  const appContext = useContext(AppContext);
  const tasksQuery = useTasks();
  const { selfQuery } = useUser();
  const {
    clearTimeboxMutation,
    unscheduleTaskMutation,
    completeTaskMutation,
    deleteTaskMutation,
    uncompleteTaskMutation,
  } = useTasksMutations();
  const isTaskSelected = useValue<boolean>(false);

  useHotkeys(
    "f",
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      timeboxCommandContext?.setIsFollowingActiveCursor(true);
    },
    {
      keydown: true,
      keyup: false,
    }
  );

  useHotkeys(
    "c",
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      openCreateTaskModal();
    },
    {
      keydown: true,
      keyup: false,
    }
  );

  useHotkeys("esc", () => {
    if (sdk.selection.queries.isAnyTaskSelected()) {
      sdk.selection.commands.unselectAllTasks();
    }
  });

  useEffect(() => {
    sdk.selection.store.subscribe(() => {
      const tasksList = sdk.selection.queries.listTasks();
      if (tasksList.length > 0) {
        isTaskSelected.setValue(true);
      } else {
        isTaskSelected.setValue(false);
      }
    });
  }, []);

  const { send } = useBrowserNotifications();

  useInterval(() => {
    const currentTime = sdk.utils.time.getCurrentTimeInMinutes();
    const foundTask = sdk.tasks.queries.getNextTaskByTime(
      tasksQuery.data ?? [],
      currentTime
    );
    const startTime = foundTask?.startTime ?? 0;
    const delta = startTime - currentTime;

    if (delta === 2 && foundTask !== null) {
      const title = "Next task is starting soon!";
      const details = `${foundTask.title} starts in ${delta} min.`;
      send({ title, details });
      toast(title);
      if (selfQuery.data?.preferences?.spokenReminders?.isEnabled) {
        speak({ text: details });
      }
    }

    if (delta === 5 && foundTask !== null) {
      const title = "Next task is starting in 5 minutes.";
      const details = `${foundTask.title} starts in ${delta} min.`;
      send({ title, details });
      toast(title);
      if (selfQuery.data?.preferences?.spokenReminders?.isEnabled) {
        speak({ text: details });
      }
    }
  }, 60000);

  let inboxColumnFilterOptions: Array<ContextMenuOption> = [
    {
      name: "show-filter-modal",
      content: (
        <Text size={Size.sm}>
          <FontAwesomeIcon icon={faFilter} /> Show filters
        </Text>
      ),
      onClick: async () => {
        openFilterModal();
      },
    },
  ];

  const isFilterEnabled = taskListFilter.isFilterEnabled();

  if (isFilterEnabled) {
    inboxColumnFilterOptions = [
      ...inboxColumnFilterOptions,
      {
        name: "reset-filter",
        content: (
          <Text size={Size.sm}>
            <FontAwesomeIcon icon={faFilterCircleXmark} /> Reset filters
          </Text>
        ),
        onClick: flow(
          () => taskListFilter.color.setValue(""),
          () => taskListFilter.title.setValue(""),
          () => taskListFilter.estimate.setValue(0),
          () => taskListFilter.sortBy.setValue(TaskListSortBy.NONE)
        ),
      },
    ];
  }

  const inboxColumnOptions = [
    {
      name: "create-task",
      content: (
        <Text size={Size.sm}>
          <FontAwesomeIcon icon={faPlus} /> Create task
        </Text>
      ),
      onClick: openCreateTaskModal,
    },
    ...inboxColumnFilterOptions,
    // {
    //   name: "delete-completed-tasks",
    //   content: (
    //     <Text size={Size.sm}>
    //       <FontAwesomeIcon icon={faDeleteLeft} /> Delete completed tasks
    //     </Text>
    //   ),
    //   onClick: () => setIsDeleteCompletedTasksModalOpen(true)
    // },
  ];

  const inboxColumnTitle = taskListFilter.isFilterEnabled()
    ? "Inbox (filtered)"
    : "Inbox";

  const inboxColumn = (
    <ContextBox
      title={inboxColumnTitle}
      key="Inbox"
      options={inboxColumnOptions}
      headerRight={
        <>
          <ButtonWithIcon
            icon={faPlus}
            color="secondary"
            size={Size.md}
            onClick={() => {
              openCreateTaskModal();
            }}
          >
            <Text>Create Task</Text>
          </ButtonWithIcon>
        </>
      }
      section={
        <>
          <Suspense fallback={<LazyLoadingIndicator />}>
            <LazyTaskList />
          </Suspense>
        </>
      }
      footer={
        isTaskSelected.value ? (
          <>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: theme.space[1],
                justifyContent: "center",
                height: "100%",
              }}
            >
              <Button
                color="primary"
                onClick={() => {
                  pipe(sdk.selection.queries.listTasks(), (taskIds) =>
                    taskIds.forEach((id) => completeTaskMutation.mutate({ id }))
                  );
                  sdk.selection.commands.unselectAllTasks();
                }}
              >
                Complete
              </Button>
              <Button
                color="secondary"
                onClick={() => {
                  pipe(sdk.selection.queries.listTasks(), (taskIds) =>
                    taskIds.forEach((id) =>
                      uncompleteTaskMutation.mutate({ id })
                    )
                  );
                  sdk.selection.commands.unselectAllTasks();
                }}
              >
                Uncomplete
              </Button>
              <Button
                color="secondary"
                onClick={() => {
                  pipe(sdk.selection.queries.listTasks(), (taskIds) =>
                    taskIds.forEach((id) =>
                      unscheduleTaskMutation.mutate({ id })
                    )
                  );
                  sdk.selection.commands.unselectAllTasks();
                }}
              >
                Unschedule
              </Button>
              <Button
                color="danger"
                onClick={() => {
                  pipe(sdk.selection.queries.listTasks(), (taskIds) =>
                    taskIds.forEach((id) => deleteTaskMutation.mutate({ id }))
                  );
                  sdk.selection.commands.unselectAllTasks();
                }}
              >
                Delete
              </Button>
              <Button
                color="secondary"
                onClick={() => {
                  sdk.selection.commands.unselectAllTasks();
                }}
              >
                Deselect Tasks
              </Button>
            </div>
          </>
        ) : (
          <></>
        )
      }
    />
  );

  const timeboxColumn = (
    <ContextBox
      title="Timebox"
      key="Timebox"
      options={[
        {
          name: "create-task",
          content: (
            <Text size={Size.sm}>
              <FontAwesomeIcon icon={faPlus} /> Create task
            </Text>
          ),
          onClick: openCreateTaskModal,
        },
        {
          name: "clear-timebox",
          content: (
            <Text size={Size.sm}>
              <FontAwesomeIcon icon={faDeleteLeft} /> Clear
            </Text>
          ),
          onClick: async () => {
            await clearTimeboxMutation.mutateAsync();
            sdk.selection.commands.unselectAllTasks();
          },
        },
        {
          name: "follow-scroll",
          content: (
            <Flex alignItems="center" gap={Size.md}>
              <FontAwesomeIcon icon={faStopwatch} />{" "}
              {timeboxQueryContext?.isFollowingActiveCursor ? (
                <Text size={Size.sm}>"Stop following"</Text>
              ) : (
                <Flex as="span" gap={Size.sm}>
                  <Text size={Size.sm}>Follow scroll</Text>
                  <HotkeyLabel>f</HotkeyLabel>
                </Flex>
              )}
            </Flex>
          ),
          onClick: () => {
            timeboxCommandContext?.setIsFollowingActiveCursor((v) => !v);
          },
        },
        {
          name: "link-third-party-calendars",
          content: (
            <Text size={Size.sm}>
              <FontAwesomeIcon icon={faLink} /> Link calendars
            </Text>
          ),
          onClick: () => {
            openThirdPartyCalendarsModal();
          },
        },
      ]}
      section={
        <Suspense fallback={<LazyLoadingIndicator />}>
          <LazyTimebox />
        </Suspense>
      }
      footer={<TimeboxStats />}
    />
  );

  const _getMainView = useCallback(() => {
    if (appContext.viewMode.value === "inbox") {
      return <SingleViewLayout>{inboxColumn}</SingleViewLayout>;
    }

    if (appContext.viewMode.value === "timebox") {
      return <SingleViewLayout>{timeboxColumn}</SingleViewLayout>;
    }

    const leftColumn = inboxColumn;

    return <SplitViewLayout left={leftColumn} right={timeboxColumn} />;
  }, [
    appContext.viewMode.value,
    appContext.isWide,
    inboxColumn,
    timeboxColumn,
  ]);

  return (
    <>
      <FocusBox />
      <ConfettiCanvas />
      <TaskDragLayer />
      <Routes>
        {getSuperTokensRoutesForReactRouterDom(ReactRouterDOM)}
        <Route
          path="/"
          element={
            <SessionAuth requireAuth={true}>
              <>
                <AppLayout
                  header={<AppBar />}
                  main={_getMainView()}
                  ref={rootLayoutRef}
                />
              </>
            </SessionAuth>
          }
        />
      </Routes>
    </>
  );
}

export default App;
