import { useContext, useEffect } from "react"
import { useWindowSize } from "react-use"
import { ConfettiContext } from "../ConfettiContext"
import * as styles from "./ConfettiCanvas.css"

export type ConfettiCanvasProps = {
}
export const ConfettiCanvas = (props: ConfettiCanvasProps) => {
  const confettiContext = useContext(ConfettiContext)
  const windowSize = useWindowSize();
  useEffect(() => {
    confettiContext?.world?.setWidth(windowSize.width);
    confettiContext?.world?.setHeight(windowSize.height);
  }, [windowSize.height, windowSize.width]);
  return (
    <canvas
      ref={confettiContext?.worldCanvasRef}
      height={windowSize.height}
      width={windowSize.width}
      className={styles.root}
    />
  )
}