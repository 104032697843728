import { ScheduleTaskDto, TaskId } from "@dulce/models/dist/tasks.models";
import { FormikProvider, useFormik } from "formik";
import { useContext } from "react";
import { Checkbox } from "../../foundation/Checkbox/Checkbox";
import { pipe, TE, O } from "@dulce/prelude";
import * as styles from "./MoveToTimeboxForm.css";
import { sdk } from "../../sdk";
import { TimeboxQueryContext } from "../../context/TimeboxContext";
import { ButtonWithIcon } from "../../foundation/ButtonWithIcon/ButtonWithIcon";
import { faCalendarCheck } from "@fortawesome/free-regular-svg-icons";
import { useTask, useTasksMutations } from "../../hooks/useTasks";
import { ModalContent } from "../../foundation/Modal/Modal";
import { TimeScrubber } from "../../foundation/TimeScrubber/TimeScrubber";
import { Size, Text, Flex } from "@dulce/design-system";
import { DurationDropdown } from "../../foundation/DurationDropdown/DurationDropdown";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export type MoveToTimeboxFormProps = {
  taskId: TaskId;
  onClose: Function;
};

export const MoveToTimeboxForm = (props: MoveToTimeboxFormProps) => {
  const timebox = useContext(TimeboxQueryContext);
  const timeboxStartTime = timebox?.timeboxStartTime ?? 0;
  const timeboxDuration = timebox?.timeboxDuration ?? 0;
  const timeboxEndTime = timeboxStartTime + timeboxDuration;
  const taskQuery = useTask(props.taskId);
  const { scheduleTaskMutation } = useTasksMutations();

  const task = taskQuery.data;
  const tasks = timebox?.tasksQuery.data ?? [];
  const activeTimeslot = pipe(
    timebox?.getTimeboxTrackInfo().currentTime ?? 0,
    (v) => v + (10 - (v % 10))
  );
  const timeslot = pipe(
    tasks,
    sdk.tasks.queries.selectLastScheduledTask,
    O.fold(
      () => timeboxStartTime,
      (task) => (task.startTime ?? 0) + task.duration
    ),
    (v) => (v > activeTimeslot ? v : activeTimeslot)
  );

  const formik = useFormik({
    initialValues: {
      startTime: task?.startTime ?? timeslot,
      duration: task?.duration ?? 10,
      pushDown: false,
    },
    onSubmit: async (values) =>
      pipe(
        values,
        TE.of,
        TE.map(
          (values): ScheduleTaskDto => ({
            id: props.taskId,
            startTime: values.startTime,
            enablePushDown: values.pushDown,
            duration: values.duration,
          })
        ),
        TE.chain(
          TE.tryCatchK(
            scheduleTaskMutation.mutateAsync,
            (reason) => new Error(String(reason))
          )
        ),
        TE.chainFirst((value) => {
          props.onClose();
          return TE.right(value);
        })
      )(),
  });

  const isSameTime = task?.startTime === formik.values.startTime;
  const isUndefined = formik.values.startTime === undefined;

  return (
    <FormikProvider value={formik}>
      <form onSubmit={formik.handleSubmit} className={styles.root}>
        <ModalContent
          content={
            <>
              <Flex column gap={Size.md}>
                <Text size={Size.sm}>Current start time</Text>
                <Flex gap={Size.md} alignItems="center">
                  <Text size={Size.lg}>
                    {task?.startTime ? (
                      <Flex gap={Size.sm}>
                        <span>
                          {sdk.utils.time.getFormattedTimeFromMinutes(
                            task?.startTime
                          )}
                        </span>
                      </Flex>
                    ) : (
                      "Unset"
                    )}
                  </Text>
                  {!isSameTime && !isUndefined ? (
                    <>
                      <FontAwesomeIcon icon={faArrowRight} />
                      <Text size={Size.lg} className={styles.greenText}>
                        <Flex gap={Size.sm}>
                          <span>
                            {sdk.utils.time.getFormattedTimeFromMinutes(
                              formik.values.startTime
                            )}
                          </span>
                        </Flex>
                      </Text>
                    </>
                  ) : null}
                  {isUndefined ? (
                    <>
                      <FontAwesomeIcon icon={faArrowRight} />
                      <Text size={Size.lg} className={styles.greenText}>
                        Unset
                      </Text>
                    </>
                  ) : null}
                </Flex>
                <TimeScrubber
                  startTime={timeboxStartTime}
                  endTime={timeboxEndTime}
                  value={formik.values.startTime}
                  onChange={(value) => formik.setFieldValue("startTime", value)}
                  tasks={tasks}
                  selectedTask={task}
                />
                <DurationDropdown
                  min={10}
                  max={120}
                  step={10}
                  name="duration"
                  initialValue={formik.initialValues.duration}
                />
              </Flex>
            </>
          }
          footer={
            <Flex justifyContent="space-between" alignItems="center">
              <Checkbox label="Enable push down" name="pushDown" />
              <ButtonWithIcon
                color="primary"
                disabled={scheduleTaskMutation.isLoading}
                gap={Size.md}
                icon={faCalendarCheck}
              >
                {scheduleTaskMutation.isLoading ? "Submitting..." : "Schedule"}
              </ButtonWithIcon>
            </Flex>
          }
        />
      </form>
    </FormikProvider>
  );
};
