import { createElement, HTMLAttributes } from "react"
import * as styles from "./Heading.css"
import classNames from "classnames"

export type HeadingProps = HTMLAttributes<HTMLElement> & Parameters<typeof styles.root>[0] & {
  as?: keyof JSX.IntrinsicElements
}
export const Heading = (props: HeadingProps) => {
  const { as = "h2", className, children, size, bold, ...rest } = props
  const rootStyles = styles.root({
    bold,
    size
  })
  const classnames = classNames(rootStyles, className)
  return createElement(as, {
    ...rest,
    className: classnames
  }, children)
}