import * as styles from "./SplitViewLayout.css"

type Children = React.ReactChild | React.ReactChildren | undefined

export type SplitViewLayoutProps = {
  left?: Children
  right?: Children
}
export const SplitViewLayout = (props: SplitViewLayoutProps) => {
  return (
    <div className={styles.root}>
      <div className={styles.left}>{props.left}</div>
      <div className={styles.right}>{props.right}</div>
    </div>
  )
}