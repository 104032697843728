import { TaskId } from "@dulce/models/dist/tasks.models";
import { StoreApi, createStore } from "zustand/vanilla";

type SelectionStore = {
  selectedTasks: Array<TaskId>;
};

export const store: StoreApi<SelectionStore> = createStore<SelectionStore>(
  () => ({
    selectedTasks: [],
  })
);
