import { IconDefinition } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { CSSProperties } from "react"
import { RecipeExtract } from "../../../../../utils/themeUtils"
import * as styles from "./TimeboxCursor.css"

export type TimeboxCursorProps = {
  icon: IconDefinition,
  top: number | string
} & RecipeExtract<typeof styles.cursorIcon> & RecipeExtract<typeof styles.cursor>

export const TimeboxCursor = (props: TimeboxCursorProps) => {
  const { color, icon, top } = props
  return (
    <div className={styles.cursor({ color })} style={{ top }}>
      <FontAwesomeIcon icon={icon} className={styles.cursorIcon({ color })} />
    </div>
  )
}