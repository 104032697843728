import { Button, Flex, Size } from "@dulce/design-system";
import { Minutes, Task } from "@dulce/models/dist/tasks.models";
import { FPArray, pipe } from "@dulce/prelude";
import { faClock } from "@fortawesome/free-regular-svg-icons";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { lazy, MouseEventHandler, useEffect } from "react";
import { useDebounce } from "react-use";
import { sdk } from "../../sdk";
import { useValue } from "../../utils/useValue";
import { ButtonWithIcon } from "../ButtonWithIcon/ButtonWithIcon";
import * as styles from "./TimeScrubber.css";
import { TimeScrubberReadTrack } from "./TimeScrubberReadTrack/TimeScrubberReadTrack";
import { TimeScrubberWriteTrack } from "./TimeScrubberWriteTrack/TimeScrubberWriteTrack";

export type TimeScrubberProps = {
  startTime: Minutes;
  endTime: Minutes;
  value: Minutes | undefined;
  tasks?: Array<Task>;
  selectedTask?: Task;
  onChange: (value: Minutes | undefined) => any | Promise<any>;
};
export const TimeScrubber = (props: TimeScrubberProps) => {
  const current = useValue(props.value);

  useEffect(() => {
    if (current.value !== props.value) {
      current.setValue(props.value);
    }
  }, [props.value]);

  const applyUpperBound = (bound: Minutes) => (v: Minutes) => {
    if (v > bound) return bound;
    return v;
  };
  const applyLowerBound = (bound: Minutes) => (v: Minutes) => {
    if (v < bound) return bound;
    return v;
  };

  const snapToStepSize = (stepSize: Minutes) => (v: Minutes) =>
    pipe(
      v,
      (v) => v / 10,
      Math.round,
      (v) => v * 10
    );

  useDebounce(
    () => {
      if (!!current.value) {
        const value = pipe(
          current.value,
          snapToStepSize(10),
          applyLowerBound(props.startTime),
          applyUpperBound(props.endTime)
        );
        current.setValue(value);
        props.onChange(value);
      }
    },
    200,
    [current.value]
  );

  const setValueToNow: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const currentTime = pipe(
      sdk.utils.time.getCurrentTimeInMinutes(),
      snapToStepSize(10),
      applyLowerBound(props.startTime),
      applyUpperBound(props.endTime)
    );
    current.setValue(currentTime);
    props.onChange(currentTime);
  };

  const unset: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();
    current.setValue(undefined);
    props.onChange(undefined);
  };

  if (!current.value || !props.value) {
    return (
      <ButtonWithIcon
        icon={faClock}
        onClick={setValueToNow}
        color="secondary"
        size={Size.lg}
      >
        Set time
      </ButtonWithIcon>
    );
  }

  return (
    <div>
      <div className={styles.root}>
        <button
          className={styles.buttonLeft}
          onClick={(e) => {
            e.preventDefault();
            current.setValue((v: any) => v - 10);
          }}
        >
          <FontAwesomeIcon icon={faChevronLeft} />
        </button>
        <label className={styles.center}>
          <TimeScrubberReadTrack
            currentValue={current.value}
            endTime={props.endTime}
            startTime={props.startTime}
            scheduledTasks={pipe(
              props.tasks ?? [],
              FPArray.filter(sdk.tasks.helpers.hasStartTime)
            )}
            selectedTask={props.selectedTask}
          />
          <TimeScrubberWriteTrack
            current={current as any}
            endTime={props.endTime}
            startTime={props.startTime}
          />
        </label>
        <button
          className={styles.buttonRight}
          onClick={(e) => {
            e.preventDefault();
            current.setValue((v: any) => v + 10);
          }}
        >
          <FontAwesomeIcon icon={faChevronRight} />
        </button>
      </div>
      <Flex>
        <Button color="ghost" onClick={setValueToNow}>
          Set to now
        </Button>
        <Button color="ghost" onClick={unset}>
          Unset
        </Button>
      </Flex>
    </div>
  );
};
