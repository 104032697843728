import { Size, Text, Flex, Button } from "@dulce/design-system";
import { Task, TaskId } from "@dulce/models/dist/tasks.models";
import { ModalContent } from "../../foundation/Modal/Modal";
import { close, open } from "../../foundation/Modal/useModal";
import { useTask, useTasksMutations } from "../../hooks/useTasks";
import { UseValueResult } from "../../utils/useValue";
import { EditTaskForm } from "../EditTaskForm/EditTaskForm";
import { MoveToTimeboxForm } from "../MoveToTimeboxForm/MoveToTimeboxForm";
import { TaskDetailsView } from "./TaskDetailsView/TaskDetailsView";

export type TaskModalsProps = {
  isDeleteModalOpen: UseValueResult<boolean>;
  isMoveToTimeboxModalOpen: UseValueResult<boolean>;
  isDuplicateModalOpen: UseValueResult<boolean>;
  isDetailsModalOpen: UseValueResult<boolean>;
  isEditTaskModalOpen: UseValueResult<boolean>;
  task: Task;
};

export const useTaskModals = (taskId: TaskId) => {
  const { deleteTaskMutation, duplicateTaskMutation } = useTasksMutations();
  const taskQuery = useTask(taskId)
  const task = taskQuery.data

  const openDeleteTaskModal = () => {
    open({
      title: "Delete Task",
      maxHeight: Size.sm,
      onRequestClose: close,
      children: <>
        <ModalContent
          content={
            <Flex column justifyContent="space-between" gap={Size.sm}>
              <Text bold>Are you sure you want to delete this task?</Text>
              <Text>You will be unable to undo this action.</Text>
            </Flex>
          }
          footer={
            <>
              <Flex justifyContent="flex-end" gap={Size.md}>
                <Button
                  color="danger"
                  size={Size.md}
                  onClick={async () => {
                    await deleteTaskMutation.mutateAsync({ id: taskId });
                    close()
                  }}
                >
                  Delete
                </Button>
              </Flex>
            </>
          }
        />
      </>
    })
  }

  const openSchedulingModal = () => {
    open({
      title: "Task Scheduling",
      onRequestClose: close,
      children: <>
        <MoveToTimeboxForm
          taskId={taskId}
          onClose={close}
        />
      </>
    })
  }


  const openTaskDetailsModal = () => {
    open({
      title: "Task Details",
      maxHeight: Size.lg,
      onRequestClose: close,
      children: <>
        <TaskDetailsView
          id={taskId}
          onEditRequest={() => {
            openEditTaskModal()
          }}
          onDeleteRequest={() => {
            openDeleteTaskModal()
          }}
          onCompleteTask={() => {
            close()
          }}
        />
      </>
    })

  }

  const openEditTaskModal = () => {
    if (task) {
      open({
        title: "Edit Task",
        maxHeight: Size.lg,
        onRequestClose: close,
        children: <>
          <EditTaskForm
            initTask={task}
            onClose={() => {
              openTaskDetailsModal()
            }}
          />
        </>
      })
    }
  }

  const openDuplcateTaskModal = () => {
    open({
      title: "Duplicate Task",
      onRequestClose: close,
      maxHeight: Size.sm,
      children: <>
        <ModalContent
          content={
            <Flex
              justifyContent="space-between"
              gap={Size.sm}
              column
              style={{ height: "100%" }}
            >
              <Text bold>🧐 This task has mini-tasks in it.</Text>
              <Text>
                You can copy the state of the mini-tasks when you duplicate
                them, or reset them in the new task.
              </Text>
            </Flex>
          }
          footer={
            <Flex justifyContent="flex-end" gap={Size.sm}>
              <Button
                color="primary"
                onClick={async () => {
                  duplicateTaskMutation.mutate({
                    id: taskId,
                    copyMinitaskState: true,
                  });
                  close()
                }}
              >
                Copy state
              </Button>
              <Button
                color="warning"
                onClick={async () => {
                  duplicateTaskMutation.mutate({
                    id: taskId,
                    copyMinitaskState: false,
                  });
                  close()
                }}
              >
                Don't copy
              </Button>
            </Flex>
          }
        />
      </>
    })
  }
  return {
    openDeleteTaskModal,
    openDuplcateTaskModal,
    openEditTaskModal,
    openSchedulingModal,
    openTaskDetailsModal
  }

}
