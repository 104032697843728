import {
  ChangeEventHandler,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useRef,
} from "react";
import { ReactEditor, useReadOnly, useSlate } from "slate-react";
import { Element as SlateElement, Transforms } from "slate";
import * as styles from "./SubTask.css";
import { LinealBox, LinealBoxProps } from "../../../LinealBox/LinealBox";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faUndo } from "@fortawesome/free-solid-svg-icons";
import { ConfettiContext } from "../../../../confetti/ConfettiContext";
import { pipe } from "@dulce/prelude";
import { getRandomInt } from "../../../../utils/getRandom";
import { AppContext } from "../../../../AppContext";
import { faHourglass } from "@fortawesome/free-regular-svg-icons";
import { useValue } from "../../../../utils/useValue";
import { LinealBoxLite } from "@dulce/design-system"

// MVP - Easy-first
// Easier

// TODO: combine visually title and details
// TODO: create a toggle between edit and non-edit mode

// Harder

// TODO: figure out how to update checkbox state when in non-edit mode
// TODO: figure out how to change the data types

export const SubTaskElement = ({
  attributes,
  children,
  element,
  styleApi,
}: any) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const editor = useSlate();
  const readOnly = useReadOnly();
  const { checked } = element;
  const handleChange = useCallback(
    (event) => {
      const path = ReactEditor.findPath(editor as any, element);
      const newProperties: Partial<SlateElement> = {
        type: "subtask",
        checked: event.target.checked,
      };
      Transforms.setNodes(editor, newProperties, { at: path });
    },
    [editor, element, checked, ref]
  );

  return (
    <SubTask
      checked={checked}
      contentEditable={!readOnly}
      onChange={handleChange}
      styleApi={styleApi ?? { color: "gray" }}
    >
      {children}
    </SubTask>
  );
};

export type SubTaskProps = {
  checked: boolean;
  contentEditable?: boolean;
  children: ReactNode;
  onChange: ChangeEventHandler<HTMLInputElement>;
  styleApi?: LinealBoxProps["styleApi"];
};
export const SubTask = (props: SubTaskProps) => {
  const confettiContext = useContext(ConfettiContext);
  const appContext = useContext(AppContext);
  const { checked, contentEditable, children } = props;
  const ref = useRef<HTMLDivElement | null>(null);
  const isLoading = useValue(false);

  const handleChange = useCallback(
    async (event) => {
      const origin = pipe(
        ref?.current,
        (el) => el?.getBoundingClientRect(),
        (rect) => ({
          x: (rect?.x ?? 0) + 16,
          y: rect?.y ?? 0,
        })
      );
      isLoading.setValue(true);
      await props.onChange(event);
      isLoading.setValue(false);
      if (!props.checked) {
        confettiContext?.startCompleteAnimation?.({
          color: props.styleApi?.color as any,
          origin,
        });
        const audioKeyMap: Record<number, string> = {
          0: "low",
          1: "normal",
          2: "high",
          3: "higher",
        };
        const audio = pipe(
          getRandomInt(0, 4),
          (key) => audioKeyMap[key],
          (key) => appContext.audioMap[key]
        );
        audio.play();
      }
    },
    [checked, confettiContext, ref, props.onChange]
  );
  return (
    <LinealBoxLite
      radius={4}
      styleApi={props.styleApi ?? { color: "gray" }}
      className={styles.root}
    >
      <label contentEditable={false}>
        <div className={styles.checkbox({ checked })} ref={ref}>
          {isLoading.value ? (
            <FontAwesomeIcon icon={faHourglass} />
          ) : checked ? (
            <FontAwesomeIcon icon={faUndo} />
          ) : (
            <FontAwesomeIcon icon={faCheck} />
          )}
        </div>
        <input
          type="checkbox"
          checked={checked}
          className={styles.checkboxInput}
          onChange={handleChange}
        />
      </label>
      <span
        className={styles.subtaskChildren({
          strikethru: checked,
        })}
        contentEditable={contentEditable}
        suppressContentEditableWarning
      >
        {children}
      </span>
    </LinealBoxLite>
  );
};
