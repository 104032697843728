import { useDragLayer } from "react-dnd";
import { ActionLayer } from "./ActionLayer/ActionLayer";
import * as styles from "./Layers.css";
import { TaskDropzoneLayer } from "./TaskDropzoneLayer/TaskDropzoneLayer";
import { TasksLayer } from "./TasksLayer/TasksLayer";
import { ThirdPartyCalendarsLayer } from "./ThirdPartyCalendarsLayer/ThirdPartyCalendarsLayer";
import { TimeMarkersLayer } from "./TimeMarkersLayer/TimeMarkersLayer";

import { createStore } from "zustand/vanilla";

export const timeboxDropzoneStore = createStore(() => ({
  isDragging: false,
}));

export type LayersProps = {};
export const Layers = (props: LayersProps) => {
  const collectedProps = useDragLayer((monitor) => {
    return monitor;
  });

  const isDragging = collectedProps?.isDragging();
  return (
    <div
      className={styles.root}
      onDragOver={() => {
        timeboxDropzoneStore.setState({
          isDragging: true,
        });
      }}
      onDragLeave={() => {
        timeboxDropzoneStore.setState({
          isDragging: false,
        });
      }}
    >
      <TimeMarkersLayer />
      <ActionLayer />
      <ThirdPartyCalendarsLayer />
      <TasksLayer />
      {isDragging && <TaskDropzoneLayer />}
    </div>
  );
};
