import { config } from "../../../../config";
import { loadGapiClient } from "./loadGapiClient";

// this is a singleton method that will get the google api client
export const getGoogleApiClient = (function () {
  let gapi: typeof window.gapi;
  // let tokenClient: any;
  return async (): Promise<typeof window.gapi> => {
    if (!gapi) {
      gapi = await loadGapiClient({
        clientId: config.google.clientId,
        apiKey: config.google.apiKey,
        scope: "https://www.googleapis.com/auth/calendar.readonly",
        discoveryDocs: [
          "https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest",
        ],
      });
    }
    return gapi;
  };
})();
