import { TaskId } from "@dulce/models/dist/tasks.models"
import { E, FPArray, pipe } from "@dulce/prelude"
import { store } from "./Selection.state"

const { setState } = store

export const selectTask = (taskId: TaskId) => {
  setState(state => pipe(
    state.selectedTasks,
    FPArray.filter(id => id !== taskId),
    FPArray.append(taskId),
    tasks => ({
      ...state,
      selectedTasks: tasks
    })
  ))
}

export const unselectTask = (taskId: TaskId) => {
  setState(state => pipe(
    state.selectedTasks,
    FPArray.filter(id => taskId !== id),
    tasks => ({
      ...state,
      selectedTasks: tasks
    })
  ))
}

export const unselectAllTasks = () => {
  setState(state => ({
    ...state,
    selectedTasks: []
  }))
}
