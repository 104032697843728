import { Size, Text, Flex } from "@dulce/design-system";
import { Minutes } from "@dulce/models/dist/tasks.models";
import { pipe } from "@dulce/prelude";
import { sdk } from "../../../../sdk";
import { RecipeExtract } from "../../../../utils/themeUtils";
import { useValue } from "../../../../utils/useValue";
import * as styles from "./TaskProgressBar.css";
import { useInterval } from "react-use";

type StyleProps = RecipeExtract<typeof styles.progress>;

export type ProgressBarProps = {
  completed: number;
  total: number;
} & StyleProps;

export const ProgressBar = (props: ProgressBarProps) => {
  const width = pipe(
    props.completed / props.total,
    (v) => v * 100,
    Math.round,
    (v) => `${v}%`
  );

  return (
    <div className={styles.root}>
      <div
        className={styles.progress({ color: props.color })}
        style={{ width }}
      />
    </div>
  );
};

export type TaskProgressBarProps = {
  completed: number;
  total: number;
} & StyleProps;

export const TaskProgressBar = (props: TaskProgressBarProps) => {
  const width = pipe(
    props.completed / props.total,
    (v) => v * 100,
    Math.round,
    (v) => `${v}%`
  );

  return (
    <Flex column gap={Size.sm}>
      <Text size={Size.sm}>{width} Complete</Text>
      <ProgressBar
        completed={props.completed}
        total={props.total}
        color={props.color}
      />
    </Flex>
  );
};

export type TimeRemainingBarProps = {
  startTime: Minutes;
  duration: Minutes;
} & StyleProps;
export const TimeRemainingBar = (props: TimeRemainingBarProps) => {
  const remainingMinutes = useValue(0);
  useInterval(() => {
    const currentTime = sdk.utils.time.getCurrentTimeInMinutes();
    const endTime = props.startTime + props.duration;
    remainingMinutes.setValue(endTime - currentTime);
  }, 1000);
  return (
    <Flex column gap={Size.sm}>
      <Text size={Size.sm}>{remainingMinutes.value} min remaining</Text>
      <ProgressBar
        completed={props.duration - remainingMinutes.value}
        total={props.duration}
        color={props.color}
      />
    </Flex>
  );
};
