import classNames from "classnames"
import React from "react"
import * as styles from "./LinealLine.css"
import { theme } from "../../../theme.css"


export type LinealLineProps = {
  variant: "normal" | "lineal" | "lineal-dash" | "lineal-dot" | "none",
  position: number
  gap: string | number
  orientation: "vertical" | "horizontal",
  alignment: "left" | "right" | "top" | "bottom",
  className?: string
}
const _LinealLine = ({ variant, position, gap, orientation, className, alignment }: LinealLineProps) => {

  const rootClassnames = classNames(
    styles.root({ orientation }),
    className
  )

  if (variant === "none") {
    return <></>
  }

  let lineStyle1 = {}
  let lineStyle2 = {}
  let dotStyle = orientation === "horizontal" ? { width: 1 } : { height: 1 }
  let dashStyle = orientation === "horizontal" ? { width: theme.space[3] } : { height: theme.space[3] }

  if (orientation === "horizontal") {
    lineStyle1 = { width: position + "%" }
    lineStyle2 = { width: `calc(100% - ${position}%` }
  } else {
    lineStyle1 = { height: position + "%" }
    lineStyle2 = { height: `calc(100% - ${position}%` }
  }

  if (variant === "lineal") {
    return (
      <div className={rootClassnames} style={{ gap }} aria-hidden={true}>
        <div className={styles.line({ alignment })} style={lineStyle1}></div>
        <div className={styles.line({ alignment })} style={lineStyle2}></div>
      </div>
    )
  }

  if (variant === "lineal-dash") {
    return (
      <div className={rootClassnames} style={{ gap }} aria-hidden={true}>
        <div className={styles.line({ alignment })} style={lineStyle1}></div>
        <div className={styles.line({ alignment })} style={dashStyle}></div>
        <div className={styles.line({ alignment })} style={lineStyle2}></div>
      </div>
    )
  }

  if (variant === "lineal-dot") {
    return (
      <div className={rootClassnames} style={{ gap }} aria-hidden={true}>
        <div className={styles.line({ alignment })} style={lineStyle1}></div>
        <div className={styles.line({ alignment })} style={dotStyle}></div>
        <div className={styles.line({ alignment })} style={lineStyle2}></div>
      </div>
    )
  }

  return (
    <div className={rootClassnames} style={{ gap }} aria-hidden={true}>
      <div className={styles.line({ alignment })}></div>
    </div>
  )
}

export const LinealLine = React.memo(_LinealLine)

