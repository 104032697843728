import { faCalendarMinus } from "@fortawesome/free-regular-svg-icons";
import {
  faList,
  faSpinner,
  faTableColumns,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext } from "react";
import { AppContext, ViewMode } from "../../AppContext";
import { CurrentTime } from "../CurrentTime/CurrentTime";
import { Logo } from "../Logo/Logo";
import * as styles from "./AppBar.css";
import { AppBarMenu } from "./AppBarMenu/AppBarMenu";
import { useIsFetching, useIsMutating } from "@tanstack/react-query";
import { Size, Text, theme, Flex, Button } from "@dulce/design-system";

export type AppBarProps = {};

export const AppBar = ({}: AppBarProps) => {
  const appContext = useContext(AppContext);
  const isFetching = useIsFetching();
  const isMutating = useIsMutating();
  const isLoading = !!isFetching || !!isMutating;

  return (
    <div className={styles.root}>
      <div className={styles.leftContainer}>
        <Flex
          gap={Size.sm}
          alignItems="center"
          className={styles.logoContainer}
        >
          <Logo color="purple" height={28} />
          <h1 className={styles.logoText}>Dulce</h1>
          {isLoading ? (
            <FontAwesomeIcon icon={faSpinner} className={styles.iconRotate} />
          ) : null}
        </Flex>
      </div>
      <div className={styles.middleContainer}>
        <Flex gap={Size.none} className={styles.buttonGroup}>
          <Button
            onClick={() => appContext.viewMode.setValue(ViewMode.inbox)}
            style={{
              color:
                appContext.viewMode.value === ViewMode.inbox
                  ? theme.colors.black
                  : theme.colors.gray[5],
              backgroundColor:
                appContext.viewMode.value === ViewMode.inbox
                  ? theme.colors.gray[3]
                  : theme.colors.transparent,
              paddingLeft: theme.space[2],
              paddingRight: theme.space[2],
              fontSize: 12,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Text style={{ marginTop: -1 }}>
              <FontAwesomeIcon icon={faList} />
            </Text>
          </Button>
          {appContext.isWide && (
            <Button
              onClick={() => appContext.viewMode.setValue(ViewMode.splitInbox)}
              style={{
                color:
                  appContext.viewMode.value === ViewMode.splitInbox
                    ? theme.colors.black
                    : theme.colors.gray[5],
                backgroundColor:
                  appContext.viewMode.value === ViewMode.splitInbox
                    ? theme.colors.gray[3]
                    : theme.colors.transparent,
                paddingLeft: theme.space[2],
                paddingRight: theme.space[2],
                fontSize: 12,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Text style={{ marginTop: -1 }}>
                <FontAwesomeIcon icon={faTableColumns} />
              </Text>
            </Button>
          )}
          <Button
            onClick={() => appContext.viewMode.setValue(ViewMode.timebox)}
            style={{
              color:
                appContext.viewMode.value === ViewMode.timebox
                  ? theme.colors.black
                  : theme.colors.gray[5],
              backgroundColor:
                appContext.viewMode.value === ViewMode.timebox
                  ? theme.colors.gray[3]
                  : theme.colors.transparent,
              paddingLeft: theme.space[2],
              paddingRight: theme.space[2],
              fontSize: 12,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Text style={{ marginTop: -1 }}>
              <FontAwesomeIcon icon={faCalendarMinus} />
            </Text>
          </Button>
        </Flex>
      </div>
      <div className={styles.rightContainer}>
        <CurrentTime />
        <AppBarMenu />
      </div>
    </div>
  );
};
