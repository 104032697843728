import classNames from "classnames";
import {
  createElement,
  forwardRef,
  HTMLAttributes,
  ReactNode,
  RefObject,
} from "react";
import * as styles from "./Flex.css";

export type FlexProps = {
  className?: string;
  as?: keyof JSX.IntrinsicElements;
  children: ReactNode | ReactNode[];
} & HTMLAttributes<HTMLElement> &
  Parameters<typeof styles.root>[0];

export const Flex = forwardRef<HTMLDivElement, FlexProps>((props, ref) => {
  const {
    as,
    children,
    className,
    column,
    gap,
    justifyContent,
    alignItems,
    ...rest
  } = props;
  const rootStyle = classNames(
    styles.root({
      column,
      gap,
      alignItems,
      justifyContent,
    }),
    props.className
  );
  return createElement(
    as ?? "div",
    {
      ...rest,
      className: rootStyle,
      ref,
    },
    children
  );
});
