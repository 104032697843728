import { Minutes, Task } from "@dulce/models/dist/tasks.models";
import { FPArray, pipe } from "@dulce/prelude";
import React, {
  MouseEventHandler,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { useInterval } from "react-use";
import { useTasks } from "../hooks/useTasks";
import { sdk } from "../sdk";
import { usePassiveWheel } from "../utils/usePassiveScroll";
import { useValue, UseValueResult } from "../utils/useValue";

export type TimeboxQueryContextValue =
  | {
      timeboxStartTime: Minutes;
      timeboxDuration: Minutes;
      timeboxStepSize: Minutes;
      tasksQuery: UseQueryResult<Array<Task>, unknown>;
      timelineTrackRef: React.RefObject<HTMLDivElement>;
      minimapTrackRef: React.RefObject<HTMLDivElement>;
      getMinimapInfo: () => {
        cursorTop: number;
        cursorHeight: number;
        trackHeight: number;
      };
      minimapTasks: Array<Task>;
      selectTasksByStartTime: (minute: Minutes) => Array<Task>;
      isScrollable: boolean;
      getTimeboxTrackInfo: () => {
        trackHeight: number;
        activeTimeTop: number;
        cssCalcActiveTimeTop: string;
        startTime: Minutes;
        duration: Minutes;
        stepSize: Minutes;
        scrollTop: number;
        currentTime: Minutes;
        isBeforeTimeboxStart: boolean;
        isPastTimeboxEnd: boolean;
        isTaskDragHoverScrollable: UseValueResult<boolean>["value"];
      };
      isFollowingActiveCursor: UseValueResult<boolean>["value"];
    }
  | undefined;
export const TimeboxQueryContext =
  React.createContext<TimeboxQueryContextValue>(undefined);
export type TimeboxCommandContextValue =
  | {
      scrollTimelineToNow: () => void;
      handleMinimapTrackMouseDown: MouseEventHandler<HTMLDivElement>;
      handleMinimapTrackMouseLeave: () => void;
      handleMinimapTrackMouseMove: MouseEventHandler<HTMLDivElement>;
      handleMinimapTrackMouseUp: () => void;
      handleMouseDragScroll: MouseEventHandler<HTMLDivElement>;
      setIsScrollable: React.Dispatch<React.SetStateAction<boolean>>;
      setIsTaskDragHoverScrollable: UseValueResult<boolean>["setValue"];
      setIsFollowingActiveCursor: UseValueResult<boolean>["setValue"];
    }
  | undefined;
export const TimeboxCommandContext =
  React.createContext<TimeboxCommandContextValue>(undefined);

export type TimeboxProviderProps = {
  children: ReactNode | ReactNode[];
};

export const TimeboxProvider = (props: TimeboxProviderProps) => {
  const timelineTrackRef = useRef<HTMLDivElement>(null);
  const minimapTrackRef = useRef<HTMLDivElement>(null);

  const [isScrollable, setIsScrollable] = useState<boolean>(false);
  const isTaskDragHoverScrollable = useValue<boolean>(false);
  const isFollowingActiveCursor = useValue<boolean>(true);
  const isNextScrollEventIgnored = useValue<boolean>(false);

  const timeboxStartTime: Minutes = useMemo(
    () => sdk.utils.time.hoursToMinutes(6),
    []
  );
  const timeboxDuration: Minutes = useMemo(
    () => sdk.utils.time.hoursToMinutes(18),
    []
  );
  const timeboxStepSize: Minutes = 10;

  const getTimeboxTrackInfo = useCallback(() => {
    const timelineTrackHeight = timelineTrackRef?.current?.scrollHeight ?? 1000;
    const currentTime: Minutes = sdk.utils.time.getCurrentTimeInMinutes();
    const startTimeToCurrentDelta = currentTime - timeboxStartTime;
    const lineOffset = Math.floor(startTimeToCurrentDelta / 10) * 0.3;
    const activeTimeTop =
      (startTimeToCurrentDelta / timeboxDuration) * timelineTrackHeight +
      lineOffset;
    const cssCalcActiveTimeTop = `calc(${activeTimeTop}px + 1rem)`;
    const timeboxEndTime = timeboxStartTime + timeboxDuration;
    const isBeforeTimeboxStart = timeboxStartTime > currentTime;
    const isPastTimeboxEnd = timeboxEndTime < currentTime;
    return {
      trackHeight: timelineTrackHeight,
      activeTimeTop,
      cssCalcActiveTimeTop,
      startTime: timeboxStartTime,
      duration: timeboxDuration,
      stepSize: timeboxStepSize,
      scrollTop: timelineTrackRef.current?.scrollTop ?? 0,
      currentTime,
      isBeforeTimeboxStart,
      isPastTimeboxEnd,
      isTaskDragHoverScrollable: isTaskDragHoverScrollable.value,
    };
  }, [timelineTrackRef.current]);

  const getMinimapInfo = useCallback(() => {
    const minimapTrackHeight = minimapTrackRef?.current?.clientHeight ?? 1000;
    const timelineTrackHeight = timelineTrackRef.current?.scrollHeight ?? 0;
    const timelineClientHeight = timelineTrackRef.current?.clientHeight ?? 0;
    const timelineTrackY = timelineTrackRef.current?.scrollTop ?? 0;
    const cursorRatio = timelineClientHeight / timelineTrackHeight;
    const scrollYPercent = timelineTrackY / timelineTrackHeight;
    return {
      cursorTop: scrollYPercent * minimapTrackHeight,
      cursorHeight: cursorRatio * minimapTrackHeight,
      trackHeight: minimapTrackHeight,
    };
  }, [timelineTrackRef.current, minimapTrackRef.current]);

  // task queries

  const tasksQuery = useTasks();
  const minimapTasks = useMemo(
    () => tasksQuery.data?.filter((task) => !!task.startTime) ?? [],
    [tasksQuery.data]
  );
  const selectTasksByStartTime = useCallback(
    (minute: Minutes) =>
      pipe(
        tasksQuery.data ?? [],
        FPArray.filter((task) => task.startTime === minute)
      ),
    [tasksQuery]
  );

  // task commands / actions

  const scrollTimelineToNow = useCallback(() => {
    const { activeTimeTop } = getTimeboxTrackInfo();
    timelineTrackRef.current?.scrollTo({
      top: activeTimeTop,
    });
  }, [timelineTrackRef.current, getTimeboxTrackInfo]);

  const handleMouseDragScroll = useCallback<MouseEventHandler<HTMLDivElement>>(
    (e) => {
      const { pageY } = e;
      const { cursorHeight, trackHeight } = getMinimapInfo();
      const { trackHeight: timeboxTrackHeight } = getTimeboxTrackInfo();
      const correctedPageY =
        pageY -
        (minimapTrackRef?.current?.getBoundingClientRect?.().top ?? 0) -
        cursorHeight / 2;
      const percentageFromTop = correctedPageY / trackHeight;
      timelineTrackRef?.current?.scrollTo({
        top: percentageFromTop * timeboxTrackHeight,
      });
    },
    [minimapTrackRef.current, getMinimapInfo, timelineTrackRef.current]
  );

  const handleMinimapTrackMouseDown = useCallback<
    MouseEventHandler<HTMLDivElement>
  >(
    (e) => {
      setIsScrollable(true);
      handleMouseDragScroll(e);
    },
    [handleMouseDragScroll, setIsScrollable]
  );

  const handleMinimapTrackMouseMove = useCallback<
    MouseEventHandler<HTMLDivElement>
  >(
    (e) => {
      if (isScrollable) {
        handleMouseDragScroll(e);
      }
    },
    [isScrollable, handleMouseDragScroll]
  );

  const handleMinimapTrackMouseUp = useCallback(() => {
    setIsScrollable(false);
  }, [setIsScrollable]);

  const handleMinimapTrackMouseLeave = useCallback(() => {
    setIsScrollable(false);
  }, [setIsScrollable]);

  // timebox lifecycle stuff

  const onWheelHandler = (e: WheelEvent) => {
    isFollowingActiveCursor.setValue(false);
    const { activeTimeTop } = getTimeboxTrackInfo();
    timelineTrackRef.current?.scrollTo({ top: activeTimeTop });
  };

  usePassiveWheel({
    handler: onWheelHandler,
    ref: minimapTrackRef,
  });

  useInterval(() => {
    const runEffect = () => {
      if (isFollowingActiveCursor.value === true) {
        isNextScrollEventIgnored.setValue(true);
        const timelineTrackHeight = timelineTrackRef.current?.scrollHeight ?? 0;
        const top = pipe(
          sdk.utils.time.getCurrentTimeInMinutes(),
          (currentTime) => currentTime - timeboxStartTime,
          (delta) => ((delta - 30) / timeboxDuration) * timelineTrackHeight
        );
        timelineTrackRef.current?.scrollTo({ top });
      }
    };
    runEffect();
  }, 200);

  // useInterval(() => {

  //   const getDeadzoneInfo = (element: HTMLElement) => {
  //     const box = element.getBoundingClientRect()
  //     const { top: offsetY, height: boxHeight } = box
  //     const deadzoneSize = boxHeight * 0.85
  //     const deadzoneTop = boxHeight - deadzoneSize
  //     const deadzoneBottom = boxHeight - deadzoneTop
  //     return {
  //       deadzoneSize,
  //       deadzoneTop,
  //       deadzoneBottom,
  //       getRelativeY: (pageY: number) => pageY - offsetY
  //     }
  //   }

  //   if (!!timelineTrackRef?.current) {
  //     const element = timelineTrackRef.current
  //     const {
  //       deadzoneBottom,
  //       deadzoneTop,
  //       getRelativeY
  //     } = getDeadzoneInfo(element)
  //     let isScrollingEnabled = isTaskDragHoverScrollable.value
  //     if (isScrollingEnabled) {
  //       const relativeY = getRelativeY(dragMousePosY.value)
  //       const scrollDown = relativeY > deadzoneBottom
  //       const scrollUp = relativeY < deadzoneTop
  //       if (scrollDown) {
  //         element.scrollBy({
  //           top: 50,
  //         })
  //       }
  //       if (scrollUp) {
  //         element.scrollBy({
  //           top: -50,
  //         })
  //       }
  //     }
  //   }
  // }, 25)

  // TODO: figure out how to make drag + navigation on the timeline performant

  // useEffect(() => {
  //   if (timelineTrackRef.current) {
  //     const element = timelineTrackRef.current
  //     const dragOverHandler = (event: MouseEvent) => {
  //       dragMousePosY.setValue(event.pageY)
  //     }
  //     element.addEventListener("dragover", dragOverHandler)
  //     return () => {
  //       element.removeEventListener("dragover", dragOverHandler)
  //     }
  //   }
  // }, [timelineTrackRef.current, isTaskDragHoverScrollable.value])
  const queryValue: TimeboxQueryContextValue = {
    tasksQuery,
    timelineTrackRef,
    minimapTrackRef,
    minimapTasks,
    getMinimapInfo,
    getTimeboxTrackInfo,
    selectTasksByStartTime,
    isScrollable,
    timeboxStartTime,
    timeboxDuration,
    timeboxStepSize,
    isFollowingActiveCursor: isFollowingActiveCursor.value,
  };
  const commandValue: TimeboxCommandContextValue = {
    scrollTimelineToNow,
    handleMinimapTrackMouseDown,
    handleMinimapTrackMouseLeave,
    handleMinimapTrackMouseMove,
    handleMinimapTrackMouseUp,
    handleMouseDragScroll,
    setIsScrollable,
    setIsTaskDragHoverScrollable: isTaskDragHoverScrollable.setValue,
    setIsFollowingActiveCursor: isFollowingActiveCursor.setValue,
  };
  return (
    <TimeboxQueryContext.Provider value={queryValue}>
      <TimeboxCommandContext.Provider value={commandValue}>
        {props.children}
      </TimeboxCommandContext.Provider>
    </TimeboxQueryContext.Provider>
  );
};
