import { ContextMenuOption, useContextMenu } from "../useContextMenu";
import * as styles from "./ContextMenuItem.css";
import { Flex } from "@dulce/design-system";

type ContextMenuItemProps = ContextMenuOption;
export const ContextMenuItem = (props: ContextMenuItemProps) => {
  const { content, name, onClick, isDisabled } = props;
  const { close } = useContextMenu();

  const handleClick = () => {
    onClick();
    close();
  };

  // TODO: make content standard (iconLeft | label | iconRight)

  return (
    <li role="none">
      <button
        role="menuitem"
        className={styles.menuItem({ disabled: isDisabled })}
        onClick={handleClick}
        data-name={name}
        disabled={isDisabled}
      >
        <Flex>{content as any}</Flex>
      </button>
    </li>
  );
};
