import * as styles from "./TaskDragLayer.css";
import { useDragLayer, XYCoord } from "react-dnd";
import { Flex, LinealBoxLite, Size, Text, theme } from "@dulce/design-system";
import { Boolean, O, pipe } from "@dulce/prelude";
import { TaskId } from "@dulce/models/dist/tasks.models";
import { useTask } from "../../hooks/useTasks";
import { CSSProperties, useEffect } from "react";
import { timeboxDropzoneStore } from "../Timebox/Layers/Layers";
import { useStore } from "zustand";
import { calc } from "@vanilla-extract/css-utils";
import { useValue } from "../../utils/useValue";
import { useDebounce } from "react-use";

function getItemStyles(opts: {
  initialOffset: XYCoord | null;
  currentOffset: XYCoord | null;
}) {
  const { currentOffset, initialOffset } = opts;
  if (initialOffset == null || currentOffset == null) {
    return {
      display: "none",
    };
  }

  let { x, y } = currentOffset;

  const transform = `translate(${x}px, ${y}px)`;
  return {
    transform,
    WebkitTransform: transform,
  };
}

export type TaskPreviewProps = {
  taskId: TaskId;
  initialOffset: XYCoord | null;
  currentOffset: XYCoord | null;
  rect: O.Option<DOMRect>;
};
export const TaskPreview = (props: TaskPreviewProps) => {
  const { isDragging } = useStore(timeboxDropzoneStore);

  const isDraggingOverTimebox = useValue(false);

  useDebounce(
    () => {
      isDraggingOverTimebox.setValue(isDragging);
    },
    200,
    [isDragging]
  );

  const { data: task, error } = useTask(props.taskId, {
    eagerFetch: false,
  });

  useEffect(() => {
    if (!task) return;
    isDraggingOverTimebox.setValue(!!task.startTime);
  }, [task]);

  if (!task) {
    return (
      <LinealBoxLite>
        <Text>Loading...</Text>
      </LinealBoxLite>
    );
  }

  const width = pipe(
    props.rect,
    O.map((rect) => rect.width),
    O.getOrElse(() => 200)
  );
  // const height = pipe(
  //   props.rect,
  //   O.map((rect) => rect.height),
  //   O.getOrElseW(() => "auto")
  // );
  const height = pipe(theme.space[3], calc, (v) => v.multiply(3));

  const adjustedHeight = pipe(
    isDraggingOverTimebox.value,
    Boolean.match(
      () => height.toString(),
      () =>
        pipe(
          task.duration,
          (v) => height.multiply(v / 10),
          (v) => v.toString()
        )
    )
  );

  return (
    <LinealBoxLite
      styleApi={{ color: task.color as any }}
      radius={4}
      innerProps={{
        style: {
          padding: theme.space[2],
          height: "100%",
        } as CSSProperties,
      }}
      outerProps={{
        style: {
          maxWidth: width,
          width,
          height: adjustedHeight,
          ...getItemStyles({
            currentOffset: props.currentOffset,
            initialOffset: props.initialOffset,
          }),
        } as CSSProperties,
      }}
    >
      <Flex justifyContent="space-between" alignItems="center">
        <Text>{task.title}</Text>
        <Text size={Size.sm}>{task.duration} min</Text>
      </Flex>
    </LinealBoxLite>
  );
};

export type TaskDragLayerProps = {};
export const TaskDragLayer = (props: TaskDragLayerProps) => {
  const dragLayer = useDragLayer((monitor) => {
    return {
      item: monitor.getItem(),
      itemType: monitor.getItemType(),
      initialOffset: monitor.getInitialSourceClientOffset(),
      currentOffset: monitor.getSourceClientOffset(),
      isDragging: monitor.isDragging(),
    };
  });

  // TODO: if dragging more than one item, change to MultiTaskPreview

  return (
    <div className={styles.root}>
      {pipe(
        dragLayer.itemType,
        (v) => v === "task",
        Boolean.match(
          () => <></>,
          () => (
            <TaskPreview
              taskId={dragLayer.item.id}
              currentOffset={dragLayer.currentOffset}
              initialOffset={dragLayer.initialOffset}
              rect={dragLayer.item.getRect()}
            />
          )
        )
      )}
    </div>
  );
};
