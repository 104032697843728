import { createElement, forwardRef, HTMLAttributes, RefObject } from "react";
import * as styles from "./Text.css";
import classNames from "classnames";
// import { createPolymorphicComponent } from "./create-polymorphic-component";

export type TextProps = HTMLAttributes<HTMLElement> &
  Parameters<typeof styles.root>[0] & {
    as?: keyof JSX.IntrinsicElements;
  };

// TODO: create a variant of Text that inherits the following:
// - [ ] font size
// - [ ] font weight

export const Text = forwardRef((props: TextProps, ref) => {
  const {
    as = "span",
    className,
    children,
    size = "inherit",
    bold,
    italic,
    ...rest
  } = props;
  const rootStyles = styles.root({
    bold,
    size,
    italic,
  });

  const classnames = classNames(rootStyles, className);
  return createElement(
    as,
    {
      ...rest,
      className: classnames,
      ref,
    },
    children
  );
});

Text.displayName = "@dulce/design-system/Text";

// export const Text = createPolymorphicComponent<"span", TextProps>(_Text);
