import * as styles from "./ToggleWithIcon.css";
import { useField } from "formik";
import { RefObject } from "react";
import { useValue } from "../../utils/useValue";
import { Size, Text } from "@dulce/design-system";
import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from "@fortawesome/react-fontawesome";
import { faCheck, faCheckSquare } from "@fortawesome/free-solid-svg-icons";

type CustomHTMLInputProps = Omit<
  React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >,
  "type"
>;

export type ToggleWithIconProps = CustomHTMLInputProps & {
  label: string;
  icon: FontAwesomeIconProps["icon"];
  inputRef?: RefObject<HTMLInputElement>;
};

export const ToggleWithIcon = (props: ToggleWithIconProps) => {
  const { label, icon, ...rest } = props;
  const [field, meta] = useField({
    ...(rest as any),
    type: "checkbox",
  });
  const isFocused = useValue<boolean>(false);
  return (
    <label
      className={styles.root({
        focused: isFocused.value,
      })}
      onFocus={() => isFocused.setValue(true)}
      onBlur={() => isFocused.setValue(false)}
    >
      {field.checked && (
        <div className={styles.checkedIcon}>
          <FontAwesomeIcon icon={faCheck} />
        </div>
      )}
      <Text size={Size.xl}>
        <FontAwesomeIcon icon={icon} />
      </Text>
      <Text size={Size.md}>{label}</Text>
      <input
        className={styles.hiddenInput}
        {...field}
        {...rest}
        type="checkbox"
      />
    </label>
  );
};
