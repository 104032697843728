import { theme } from "../../theme.css";
import * as styles from "./Logo.css";
import { renderToString } from "react-dom/server";
import { pipe } from "@dulce/prelude";

const colorVariants = {
  gray: {
    primary: theme.colors.gray[3],
    secondary: theme.colors.gray[4],
  },
  blue: {
    primary: theme.colors.blue[3],
    secondary: theme.colors.blue[4],
  },
  green: {
    primary: theme.colors.green[3],
    secondary: theme.colors.green[4],
  },
  red: {
    primary: theme.colors.red[3],
    secondary: theme.colors.red[4],
  },
  yellow: {
    primary: theme.colors.yellow[3],
    secondary: theme.colors.yellow[4],
  },
  purple: {
    primary: theme.colors.purple[3],
    secondary: theme.colors.purple[4],
  },
};
type ColorVariants = typeof colorVariants;

export type LogoProps = {
  color: keyof ColorVariants;
  height?: number;
  excludeRoot?: boolean;
};
export const Logo = ({
  color,
  excludeRoot = false,
  height = 42,
}: LogoProps) => {
  const selected = colorVariants[color];

  const widthHeightRatio = 40 / 42;

  const computed = {
    primary: pipe(
      selected.primary.slice(4, selected.primary.length - 1),
      (value) =>
        getComputedStyle(document.documentElement).getPropertyValue(value)
    ),
    secondary: pipe(
      selected.secondary.slice(4, selected.secondary.length - 1),
      (value) =>
        getComputedStyle(document.documentElement).getPropertyValue(value)
    ),
  };

  return excludeRoot ? (
    <svg
      width={widthHeightRatio * height}
      height={height}
      viewBox="0 0 40 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.9933 9.84367C18.5552 9.28114 19.4662 9.28114 20.0281 9.84367L28.1674 17.9921C28.7293 18.5547 28.7293 19.4667 28.1674 20.0293L20.0281 28.1777C19.4662 28.7402 18.5552 28.7402 17.9933 28.1777L9.85396 20.0293C9.29206 19.4667 9.29206 18.5547 9.85396 17.9921L17.9933 9.84367Z"
        fill={computed.primary}
      />
      <path
        d="M11.7494 24.4732C12.8193 24.1862 13.7983 25.1663 13.5116 26.2374L10.6493 36.9315C10.3627 38.0026 9.02532 38.3613 8.24212 37.5772L0.422311 29.7486C-0.360894 28.9645 -0.00255192 27.6257 1.06733 27.3387L11.7494 24.4732Z"
        fill={computed.primary}
      />
      <path
        d="M26.2506 13.5268C25.1807 13.8138 24.2017 12.8337 24.4884 11.7626L27.3507 1.06853C27.6373 -0.00255651 28.9747 -0.361299 29.7579 0.422787L37.5777 8.2514C38.3609 9.03549 38.0026 10.3743 36.9327 10.6613L26.2506 13.5268Z"
        fill={computed.primary}
      />
      <path
        d="M19.9933 13.8437C20.5552 13.2811 21.4662 13.2811 22.0281 13.8437L30.1674 21.9921C30.7293 22.5547 30.7293 23.4667 30.1674 24.0293L22.0281 32.1777C21.4662 32.7402 20.5552 32.7402 19.9933 32.1777L11.854 24.0293C11.2921 23.4667 11.2921 22.5547 11.854 21.9921L19.9933 13.8437Z"
        fill={computed.secondary}
      />
      <path
        d="M13.7494 28.4732C14.8193 28.1862 15.7983 29.1663 15.5116 30.2374L12.6493 40.9315C12.3627 42.0026 11.0253 42.3613 10.2421 41.5772L2.42231 33.7486C1.63911 32.9645 1.99745 31.6257 3.06733 31.3387L13.7494 28.4732Z"
        fill={computed.secondary}
      />
      <path
        d="M28.2506 17.5268C27.1807 17.8138 26.2017 16.8337 26.4884 15.7626L29.3507 5.06853C29.6373 3.99744 30.9747 3.6387 31.7579 4.42279L39.5777 12.2514C40.3609 13.0355 40.0026 14.3743 38.9327 14.6613L28.2506 17.5268Z"
        fill={computed.secondary}
      />
      <path
        d="M19.347 12.197C19.7136 11.83 20.3077 11.83 20.6743 12.197L28.8136 20.3455C29.1805 20.7128 29.1805 21.3086 28.8136 21.6759L20.6743 29.8244C20.3077 30.1914 19.7136 30.1914 19.347 29.8244L11.2077 21.6759C10.8408 21.3086 10.8408 20.7128 11.2077 20.3455L19.347 12.197ZM12.8789 26.9561C13.576 26.7691 14.216 27.4078 14.0286 28.1081L11.1663 38.8022C10.9791 39.5018 10.1065 39.7351 9.59587 39.2239L1.77606 31.3952C1.26434 30.8829 1.49911 30.0088 2.19687 29.8216L12.8789 26.9561ZM27.1211 15.0439C26.424 15.2309 25.784 14.5922 25.9714 13.8919L28.8337 3.1978C29.0209 2.49817 29.8935 2.2649 30.4041 2.77614L38.2239 10.6048C38.7357 11.1171 38.5009 11.9912 37.8031 12.1784L27.1211 15.0439Z"
        stroke="black"
      />
      <path
        d="M19.3775 24.1175L17.1025 21.8425C16.9658 21.7058 16.9658 21.4842 17.1025 21.3475L17.5975 20.8525C17.7341 20.7158 17.9558 20.7158 18.0924 20.8525L19.625 22.385L22.9076 19.1025C23.0442 18.9658 23.2659 18.9658 23.4025 19.1025L23.8975 19.5975C24.0342 19.7342 24.0342 19.9558 23.8975 20.0925L19.8725 24.1175C19.7358 24.2542 19.5142 24.2542 19.3775 24.1175Z"
        fill="black"
      />
    </svg>
  ) : (
    <div className={styles.root}>
      <svg
        width={widthHeightRatio * height}
        height={height}
        viewBox="0 0 40 42"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M17.9933 9.84367C18.5552 9.28114 19.4662 9.28114 20.0281 9.84367L28.1674 17.9921C28.7293 18.5547 28.7293 19.4667 28.1674 20.0293L20.0281 28.1777C19.4662 28.7402 18.5552 28.7402 17.9933 28.1777L9.85396 20.0293C9.29206 19.4667 9.29206 18.5547 9.85396 17.9921L17.9933 9.84367Z"
          fill={selected.primary}
        />
        <path
          d="M11.7494 24.4732C12.8193 24.1862 13.7983 25.1663 13.5116 26.2374L10.6493 36.9315C10.3627 38.0026 9.02532 38.3613 8.24212 37.5772L0.422311 29.7486C-0.360894 28.9645 -0.00255192 27.6257 1.06733 27.3387L11.7494 24.4732Z"
          fill={selected.primary}
        />
        <path
          d="M26.2506 13.5268C25.1807 13.8138 24.2017 12.8337 24.4884 11.7626L27.3507 1.06853C27.6373 -0.00255651 28.9747 -0.361299 29.7579 0.422787L37.5777 8.2514C38.3609 9.03549 38.0026 10.3743 36.9327 10.6613L26.2506 13.5268Z"
          fill={selected.primary}
        />
        <path
          d="M19.9933 13.8437C20.5552 13.2811 21.4662 13.2811 22.0281 13.8437L30.1674 21.9921C30.7293 22.5547 30.7293 23.4667 30.1674 24.0293L22.0281 32.1777C21.4662 32.7402 20.5552 32.7402 19.9933 32.1777L11.854 24.0293C11.2921 23.4667 11.2921 22.5547 11.854 21.9921L19.9933 13.8437Z"
          fill={selected.secondary}
        />
        <path
          d="M13.7494 28.4732C14.8193 28.1862 15.7983 29.1663 15.5116 30.2374L12.6493 40.9315C12.3627 42.0026 11.0253 42.3613 10.2421 41.5772L2.42231 33.7486C1.63911 32.9645 1.99745 31.6257 3.06733 31.3387L13.7494 28.4732Z"
          fill={selected.secondary}
        />
        <path
          d="M28.2506 17.5268C27.1807 17.8138 26.2017 16.8337 26.4884 15.7626L29.3507 5.06853C29.6373 3.99744 30.9747 3.6387 31.7579 4.42279L39.5777 12.2514C40.3609 13.0355 40.0026 14.3743 38.9327 14.6613L28.2506 17.5268Z"
          fill={selected.secondary}
        />
        <path
          d="M19.347 12.197C19.7136 11.83 20.3077 11.83 20.6743 12.197L28.8136 20.3455C29.1805 20.7128 29.1805 21.3086 28.8136 21.6759L20.6743 29.8244C20.3077 30.1914 19.7136 30.1914 19.347 29.8244L11.2077 21.6759C10.8408 21.3086 10.8408 20.7128 11.2077 20.3455L19.347 12.197ZM12.8789 26.9561C13.576 26.7691 14.216 27.4078 14.0286 28.1081L11.1663 38.8022C10.9791 39.5018 10.1065 39.7351 9.59587 39.2239L1.77606 31.3952C1.26434 30.8829 1.49911 30.0088 2.19687 29.8216L12.8789 26.9561ZM27.1211 15.0439C26.424 15.2309 25.784 14.5922 25.9714 13.8919L28.8337 3.1978C29.0209 2.49817 29.8935 2.2649 30.4041 2.77614L38.2239 10.6048C38.7357 11.1171 38.5009 11.9912 37.8031 12.1784L27.1211 15.0439Z"
          stroke="black"
        />
        <path
          d="M19.3775 24.1175L17.1025 21.8425C16.9658 21.7058 16.9658 21.4842 17.1025 21.3475L17.5975 20.8525C17.7341 20.7158 17.9558 20.7158 18.0924 20.8525L19.625 22.385L22.9076 19.1025C23.0442 18.9658 23.2659 18.9658 23.4025 19.1025L23.8975 19.5975C24.0342 19.7342 24.0342 19.9558 23.8975 20.0925L19.8725 24.1175C19.7358 24.2542 19.5142 24.2542 19.3775 24.1175Z"
          fill="black"
        />
      </svg>
    </div>
  );
};

export const getLogoAsHtmlString = (props: LogoProps) =>
  renderToString(<Logo {...props} excludeRoot={true} />);

