import { FormikProvider, useFormik } from "formik";
import { DurationDropdown } from "../../foundation/DurationDropdown/DurationDropdown";
import { ColorPicker } from "./ColorPicker/ColorPicker";
import * as styles from "./CreateTaskForm.css";
import { AddTaskDto, Details, Minutes } from "@dulce/models/dist/tasks.models";
import { FPArray, O, pipe, TE } from "@dulce/prelude";
import { useContext, useEffect, useRef } from "react";
import { Toggle } from "../../foundation/Toggle/Toggle";
import { AppContext } from "../../AppContext";
import { useTasks, useTasksMutations } from "../../hooks/useTasks";
import {
  faAnchor,
  faAnchorLock,
  faHeadphones,
  faLock,
  faPlus,
  faRecycle,
  faUnlock,
} from "@fortawesome/free-solid-svg-icons";
import { faClock, faHourglass } from "@fortawesome/free-regular-svg-icons";
import { TimeboxQueryContext } from "../../context/TimeboxContext";
import { ToggleWithIcon } from "../../foundation/ToggleWithIcon/ToggleWithIcon";
import { useHotkeys } from "react-hotkeys-hook";
import { TitleDetailsInput } from "./TitleDetailsInput/TitleDetailsInput";
import { ModalContent } from "../../foundation/Modal/Modal";
import { TimeScrubber } from "../../foundation/TimeScrubber/TimeScrubber";
import { Paragraph } from "../EditTaskForm/EditTaskForm";
import { CustomElements } from "@dulce/models/dist/slate.primatives";
import { HotkeyLabel } from "../HotkeyLabel/HotkeyLabel";
import { ButtonWithIcon } from "../../foundation/ButtonWithIcon/ButtonWithIcon";
import _ from "lodash";
import { clamp } from "../../utils/clamp";
import { Size, Text, theme, Flex } from "@dulce/design-system";
import { close } from "../../foundation/Modal/useModal";
import { useUser } from "../../hooks/useUser";
import { useStore } from "zustand";
import { searchAndFilterStore } from "../TaskList/SearchAndFilter/SearchAndFilter.state";

// TODO: move to sdk::utils::time::duration
const duration = {
  clampToBoundries: clamp({ min: 10, max: 120 }),
};

const draftCreateTaskDtoFromValues = (
  values: Record<string, any>
): Omit<AddTaskDto, "ownerId"> => ({
  color: values.color,
  duration: parseInt(values.estimate),
  startTime: values?.startTime ? parseInt(values?.startTime) : undefined,
  title: values.title,
  details: values.details,
  locality: values.locality,
  isLocked: values.isLocked,
  isRecurring: values.isRecurring,
  isDeepWork: values.isDeepWork,
  isSuperLocked: values.isSuperLocked,
  emojiCode: values.emoji,
});

const getDefaultDetails = () =>
  [{ type: "paragraph", children: [{ text: "" }] }] as Details;

export type CreateTaskFormProps = {
  onClose: Function;
};
export const CreateTaskForm = ({ onClose }: CreateTaskFormProps) => {
  const { selfQuery } = useUser();
  const userPreferences = selfQuery.data?.preferences ?? {};
  const { addTaskMutation } = useTasksMutations();
  const firstInputRef = useRef<HTMLInputElement>(null);
  const { presetStartTime, initTaskTitle, presetDuration } =
    useContext(AppContext);
  const timebox = useContext(TimeboxQueryContext);
  const { searchQuery } = useStore(searchAndFilterStore);

  const timeboxStartTime = timebox?.timeboxStartTime ?? 0;
  const timeboxDuration = timebox?.timeboxDuration ?? 0;
  const timeboxEndTime = timeboxStartTime + timeboxDuration;

  const formik = useFormik({
    initialValues: {
      title: searchQuery,
      details: userPreferences.taskDetailsTemplate?.isEnabled
        ? pipe(
            userPreferences.taskDetailsTemplate?.details,
            O.fromNullable,
            O.getOrElse(getDefaultDetails),
            (v) => (v.length ? v : getDefaultDetails())
          )
        : getDefaultDetails(),
      color: "gray",
      estimate: presetDuration.value ?? 10,
      startTime: presetStartTime.value,
      isLocked: false,
      isRecurring: false,
      locality: "",
      isSuperLocked: false,
      isDeepWork: false,
      emoji: "",
      // custom options
      stay: false,
      break: false,
    },
    onSubmit: async (values) =>
      pipe(
        values,
        TE.of,
        TE.map(({ stay, ...values }) => values),
        TE.map(draftCreateTaskDtoFromValues),
        TE.chain(
          TE.tryCatchK(
            addTaskMutation.mutateAsync,
            (reason) => new Error(String(reason))
          )
        ),
        TE.chainFirst(() => {
          if (values.break && values.startTime) {
            addTaskMutation.mutate({
              color: "yellow",
              duration: 10,
              title: "Break (Ultradium Recovery)",
              details: Paragraph.fromStringOrCustomElements(""),
              startTime: values.startTime + (values?.estimate ?? 0),
            });
          }
          if (values.stay) {
            firstInputRef.current?.focus();
            if (values.startTime) {
              const updatedStartTime = pipe(
                values.startTime + (values?.estimate ?? 0),
                (value) => (values.break ? value + 20 : value)
              );
              presetStartTime.setValue(updatedStartTime);
            }
          } else {
            close();
            presetStartTime.setValue(undefined);
          }
          return TE.right(null);
        })
      )(),
  });

  useEffect(() => {
    formik?.setFieldValue("startTime", presetStartTime.value);
  }, [presetStartTime.value]);

  useHotkeys(
    "alt+s, cmd+s, ctrl+s",
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      formik?.submitForm();
    },
    {
      enableOnTags: ["INPUT", "SELECT", "TEXTAREA"],
    }
  );

  const tasks = useTasks();

  const snapToStepSize = (stepSize: Minutes) => (v: Minutes) =>
    pipe(
      v,
      (v) => v / 10,
      Math.round,
      (v) => v * 10
    );

  const details: Array<CustomElements> =
    (formik.values?.details as Array<CustomElements>) ?? [];

  const minitasks: Array<CustomElements> = pipe(
    details,
    FPArray.filter((v: CustomElements) => v.type === "subtask")
  );

  const recommendedDuration: Minutes = pipe(
    minitasks.length * 10,
    (v) => (formik.values.isDeepWork ? v + 10 : v),
    duration.clampToBoundries
  );

  return (
    <FormikProvider value={formik}>
      <form onSubmit={formik.handleSubmit} className={styles.root}>
        <ModalContent
          content={
            <>
              <Flex column gap={Size.md}>
                <TitleDetailsInput
                  titleName="title"
                  titleRef={firstInputRef}
                  detailsName="details"
                  detailsInitialValue={formik.initialValues.details as any} // TODO: figure out why compiler is freaking out
                  emojiName="emoji"
                  titleRequired
                  styleApi={{ color: formik.values.color as any }}
                />
                <ColorPicker name="color" />
                <Flex column gap={Size.md}>
                  <Flex column gap={Size.sm}>
                    <Flex alignItems="center" gap={Size.sm}>
                      <Text size={Size.sm}>Start Time (optional)</Text>
                    </Flex>
                    <TimeScrubber
                      startTime={timeboxStartTime}
                      endTime={timeboxEndTime}
                      value={formik.values.startTime}
                      tasks={tasks.data ?? []}
                      onChange={(value) =>
                        formik.setFieldValue("startTime", value)
                      }
                    />
                  </Flex>
                  <Flex
                    column
                    justifyContent="flex-start"
                    alignItems="flex-start"
                  >
                    <ButtonWithIcon
                      color="ghost"
                      size={Size.sm}
                      icon={faClock}
                      onClick={(e) => {
                        e.preventDefault();
                        formik
                          .getFieldHelpers("estimate")
                          .setValue(recommendedDuration);
                      }}
                    >
                      Recommended Duration: {recommendedDuration} min
                    </ButtonWithIcon>
                    <DurationDropdown
                      min={10}
                      max={120}
                      step={10}
                      name="estimate"
                      initialValue={formik.values.estimate}
                    />
                  </Flex>

                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: theme.space[3],
                    }}
                  >
                    <ToggleWithIcon
                      icon={formik.values.isLocked ? faLock : faUnlock}
                      name="isLocked"
                      label={formik.values.isLocked ? "Locked" : "Unlocked"}
                    />
                    <ToggleWithIcon
                      icon={
                        formik.values.isSuperLocked ? faAnchorLock : faAnchor
                      }
                      name="isSuperLocked"
                      label={"Super Lock"}
                    />
                    <ToggleWithIcon
                      icon={faRecycle}
                      name="isRecurring"
                      label={formik.values.isRecurring ? "Enabled" : "Disabled"}
                    />
                    <ToggleWithIcon
                      icon={faHeadphones}
                      name="isDeepWork"
                      label={
                        formik.values.isDeepWork
                          ? "Focus: Deep"
                          : "Focus: Simple"
                      }
                    />
                  </div>
                </Flex>
              </Flex>
            </>
          }
          footer={
            <>
              <div className={styles.formActions}>
                <Toggle name="stay" label="Create Another" />
                {formik.values.startTime && (
                  <Toggle name="break" label="Include Break" />
                )}
                <ButtonWithIcon
                  color="primary"
                  icon={addTaskMutation.isLoading ? faHourglass : faPlus}
                  size={Size.md}
                  disabled={addTaskMutation.isLoading}
                >
                  {addTaskMutation.isLoading ? (
                    <span>Submitting...</span>
                  ) : (
                    <Flex gap={Size.sm} alignItems="center">
                      <Text>Add</Text>
                      <HotkeyLabel>Ctrl</HotkeyLabel>
                      <Text>+</Text>
                      <HotkeyLabel>S</HotkeyLabel>
                    </Flex>
                  )}
                </ButtonWithIcon>
              </div>
            </>
          }
        />
      </form>
    </FormikProvider>
  );
};
