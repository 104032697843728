export const config = {
  google: {
    apiKey: (process.env.REACT_APP_GOOGLE_API_KEY as string) ?? "",
    clientId: (process.env.REACT_APP_GOOGLE_CLIENT_ID as string) ?? "",
  },
  supertokens: {
    appName: (process.env.REACT_APP_SUPERTOKENS_APP_NAME as string) ?? "",
    apiDomain: (process.env.REACT_APP_SUPERTOKENS_API_DOMAIN as string) ?? "",
    apiBasePath: "/auth",
    websiteDomain:
      (process.env.REACT_APP_SUPERTOKENS_WEBSITE_DOMAIN as string) ?? "",
    websiteBasePath: "/auth",
  },
  api: {
    url: process.env.REACT_APP_API_URL ?? "",
  },
};
