import React, { useCallback, useContext, useRef, useState } from "react";
import ReactDOM from "react-dom";
import { Heading } from "../../foundation/Heading/Heading";
import { LinealBox } from "../../foundation/LinealBox/LinealBox";
import { CurrentTime } from "../CurrentTime/CurrentTime";
import * as styles from "./FocusBox.css";
import { FocusBoxContext } from "./FocusBoxContext";
import { useTasks } from "../../hooks/useTasks";
import { sdk } from "../../sdk";
import { pipe } from "@dulce/prelude";
import { Task } from "@dulce/models/dist/tasks.models";
import { Size, Text, Flex, LinealBoxLite } from "@dulce/design-system";

export const useForceUpdate = () => {
  const [, updateState] = useState({});
  const forceUpdate = useCallback(() => updateState({}), []);
  return forceUpdate;
};

export type FocusBoxProps = {};
export const FocusBox = React.forwardRef<HTMLDivElement, FocusBoxProps>(
  (props, ref) => {
    const focusBoxContext = useContext(FocusBoxContext);
    const { data, isLoading } = useTasks();
    let content = <></>;
    if (isLoading) {
      content = (
        <>
          <Heading>Loading...</Heading>
        </>
      );
    }

    let currentTask: Task | null = null;
    let nextTask: Task | null = null;
    if (data) {
      currentTask = sdk.tasks.queries.getCurrentTask(
        data,
        sdk.utils.time.getCurrentTimeInMinutes()
      );
      nextTask = sdk.tasks.queries.getNextTaskByTime(
        data,
        sdk.utils.time.getCurrentTimeInMinutes()
      );
    }
    content = (
      <Flex column gap={Size.md}>
        <CurrentTime preventBlink />
        {currentTask !== null && (
          <Flex column gap={Size.sm}>
            <Text size={Size.sm}>
              Current Task -{" "}
              {pipe(
                currentTask.startTime ?? (0 as number),
                (v) => v + (currentTask as Task).duration,
                (v) => v - sdk.utils.time.getCurrentTimeInMinutes()
              )}{" "}
              min left
            </Text>
            <LinealBoxLite
              className={styles.taskBlock}
              styleApi={{ color: currentTask.color as any }}
              radius={4}
            >
              <Flex column gap={Size.none}>
                <Flex justifyContent="space-between">
                  <Text size={Size.sm}>
                    {sdk.utils.time.getFormattedTimeFromMinutes(
                      currentTask.startTime ?? 0
                    )}
                    {" - "}
                    {sdk.utils.time.getFormattedTimeFromMinutes(
                      (currentTask.startTime ?? 0) + currentTask.duration
                    )}{" "}
                  </Text>
                  <Text size={Size.sm}>{currentTask.duration} min</Text>
                </Flex>
                <Text size={Size.lg}>{currentTask.title}</Text>
              </Flex>
            </LinealBoxLite>
          </Flex>
        )}
        {nextTask !== null && (
          <Flex column gap={Size.sm}>
            <Text size={Size.sm}>
              Next Task - starts in{" "}
              {pipe(
                nextTask.startTime ?? (0 as number),
                (v) => v - sdk.utils.time.getCurrentTimeInMinutes()
              )}{" "}
              min
            </Text>
            <LinealBoxLite
              className={styles.taskBlock}
              styleApi={{ color: nextTask.color as any }}
              radius={4}
            >
              <Flex column gap={Size.none}>
                <Flex justifyContent="space-between">
                  <Text size={Size.sm}>
                    {sdk.utils.time.getFormattedTimeFromMinutes(
                      nextTask.startTime ?? 0
                    )}
                    {" - "}
                    {sdk.utils.time.getFormattedTimeFromMinutes(
                      (nextTask.startTime ?? 0) + nextTask.duration
                    )}{" "}
                  </Text>
                  <Text size={Size.sm}>{nextTask.duration} min</Text>
                </Flex>
                <Text size={Size.lg}>{nextTask.title}</Text>
              </Flex>
            </LinealBoxLite>
          </Flex>
        )}
        {!nextTask && !currentTask && (
          <Flex column justifyContent="center" alignItems="center">
            <Text size={Size.lg}>Timebox Zero</Text>
            <Text size={Size.xl}>🏖️</Text>
            <Text size={Size.sm} italic>
              ️Click to line up your next awesome goal.
            </Text>
          </Flex>
        )}
      </Flex>
    );

    return ReactDOM.createPortal(
      <div className={styles.offscreenContainer}>
        <canvas
          ref={focusBoxContext.canvasRef as any}
          className={styles.canvas}
        />
        <video ref={focusBoxContext.videoRef as any} className={styles.video} />
        <div
          className={styles.root}
          ref={focusBoxContext.htmlRef as any}
          id="focus-box"
        >
          <LinealBoxLite
            outerClassName={styles.outerContent}
            className={styles.innerContent}
          >
            {content}
          </LinealBoxLite>
        </div>
      </div>,
      document.getElementById("focus-box-portal") as Element
    );
  }
);
