import create from "zustand";
import { RefObject, ReactElement, ReactNode } from "react";

export type ContextMenuOption = {
  content: ReactNode | ReactNode[];
  onClick: Function;
  name: string;
  isDisabled?: boolean;
};

export type UpdateOpts = Pick<
  UseContextMenuStoreValues,
  "options" | "left" | "top" | "isOpen"
>;

export type UseContextMenuStoreValues = {
  top: number;
  left: number;
  isOpen: boolean;
  options: Array<ContextMenuOption>;
  update: (opts: UpdateOpts) => void;
};

export const useContextMenuStore = create<UseContextMenuStoreValues>((set) => {
  return {
    top: 0,
    left: 0,
    isOpen: false,
    options: [],
    update: (opts: UpdateOpts) => {
      set(opts);
    },
  };
});

const remToPx = (value: number) => value * 16;

export const useContextMenu = () => {
  const { top, left, options, update, isOpen } = useContextMenuStore();

  const open = (opts: Pick<UpdateOpts, "options" | "left" | "top">) => {
    update({
      ...opts,
      isOpen: true,
    });
  };

  const close = () => {
    update({
      top: 0,
      left: 0,
      options: [],
      isOpen: false,
    });
  };

  type HandleClickOpts = {
    buttonRef: RefObject<HTMLButtonElement>;
    options: Array<ContextMenuOption>;
  };

  const handleClick = (opts: HandleClickOpts) => () => {
    if (isOpen) {
      close();
    }
    const rect = opts.buttonRef.current?.getBoundingClientRect();
    const rectTop = rect?.top ?? 0;
    const rectHeight = rect?.height ?? 1;
    const rectLeft = rect?.left ?? 0;
    const rectWidth = rect?.width ?? 0;

    open({
      top: rectTop + rectHeight,
      left: rectLeft - remToPx(11) + rectWidth,
      options: opts.options ?? [],
    });
  };

  return {
    // queries
    top,
    left,
    isOpen,
    options,
    // commands
    open,
    close,
    handleClick,
  };
};
