import { createStore } from "zustand/vanilla";

export type SearchAndFilterStoreValues = {
  searchQuery: string;
  colorFilter: {
    gray: boolean;
    purple: boolean;
    green: boolean;
    blue: boolean;
    red: boolean;
    yellow: boolean;
  };
};

export const searchAndFilterStore = createStore<SearchAndFilterStoreValues>(
  () => {
    return {
      searchQuery: "",
      colorFilter: {
        blue: false,
        purple: false,
        green: false,
        red: false,
        yellow: false,
        gray: false,
      },
    };
  }
);
