import { Size } from '@dulce/design-system'
import { O } from '@dulce/prelude'
import _ from 'lodash'
import { Props as ReactModalProps } from 'react-modal'
import create from 'zustand'

export type UseModalState = {
  title: string
  maxHeight?: Size
} & ReactModalProps

const initState: UseModalState = {
  title: '',
  maxHeight: Size.md,
  isOpen: false
}

export const useModalState = create<UseModalState>(set => {
  return initState
})

// commands

export const open = (opts: Omit<UseModalState, "isOpen">) => {
  useModalState.setState({
    ...opts,
    isOpen: true
  })
}

export const close = () => {
  useModalState.setState(initState)
}

// queries

export const getProps = () => _.omit<UseModalState>(useModalState.getState(), ["title", "maxHeight", "content", "footer"]) as ReactModalProps
export const getTitle = () => useModalState.getState().title
export const getMaxHeight = () => useModalState.getState().maxHeight
