import { Dispatch, SetStateAction, useState } from "react";

export { Text } from "./Text/Text";
export type { TextProps } from "./Text/Text";

export { Flex } from "./Flex/Flex";
export type { FlexProps } from "./Flex/Flex";

export { Button } from "./Button/Button";
export type { ButtonProps } from "./Button/Button";

export { LinealBoxLite } from "./LinealBoxLite/LinealBoxLite";
export type { LinealBoxLiteProps } from "./LinealBoxLite/LinealBoxLite";

export type UseValueResult<T> = {
  value: T;
  setValue: Dispatch<SetStateAction<T>>;
};

export function useValue<T>(initValue: T): UseValueResult<T> {
  const [value, setValue] = useState<T>(initValue);
  return {
    value,
    setValue,
  };
}

export {
  fontSizeThemeMap,
  sizeThemeMap,
  theme,
  Size,
  rawColors,
} from "./theme.css";
