import * as styles from "./Modal.css";
import ReactModal, { Props as ReactModalProps } from "react-modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { Heading } from "../Heading/Heading";
import { LinealBox } from "../LinealBox/LinealBox";
import { LinealLine } from "../LinealBox/LinealLine/LinealLine";
import { motion } from "framer-motion";
import React, { ReactNode } from "react";
import { useModalState } from "./useModal";
import { Button, theme, Size } from "@dulce/design-system"

const MotionLinealBox = motion(
  React.forwardRef((props: any, ref: any) => {
    return <LinealBox {...props} outerRef={ref} />;
  })
);

export type ModalProps = ReactModalProps & {
  title: string;
  maxHeight?: Size;
};
export const Modal = () => {

  const { title, maxHeight, ...props } = useModalState()

  return (
    <ReactModal
      {...props}
      contentLabel={title}
      className={styles.modalContent({
        maxHeight
      })}
      overlayClassName={styles.modalOverlay}
    >
      <MotionLinealBox
        radius={4}
        outerClassName={styles.contentOuter}
        className={styles.contentInner}
        initial={{
          y: "-5%",
          opacity: 0,
        }}
        animate={{
          y: "0%",
          opacity: 1,
        }}
        exit={{
          y: "5%",
          opacity: 0,
        }}
        outerProps={{
          onDoubleClick: (e: any) => e.stopPropagation(),
        }}
      >
        <>
          <header className={styles.header}>
            <Heading>{title}</Heading>
            <Button onClick={props.onRequestClose}>
              <FontAwesomeIcon icon={faTimes} />
            </Button>
            <LinealLine
              alignment="bottom"
              gap={theme.space[2]}
              orientation="horizontal"
              position={75}
              variant="lineal"
              className={styles.headerLine}
            />
          </header>
          <section className={styles.section}>
            {props.children}
          </section>
        </>
      </MotionLinealBox>
    </ReactModal>
  );
};

export type ModalContentProps = {
  content: ReactNode;
  footer?: ReactNode;
};

export const ModalContent = (props: ModalContentProps) => {
  return (
    <div className={styles.contentContainer}>
      <section className={styles.content}>{props.content}</section>
      {props.footer && (
        <footer className={styles.footer}>
          <LinealLine
            alignment="bottom"
            gap={theme.space[2]}
            orientation="horizontal"
            position={30}
            variant="lineal"
            className={styles.footerLine}
          />
          {props.footer}
        </footer>
      )}
    </div>
  );
};
