import { Size, Text, theme } from "@dulce/design-system";
import { Minutes, Task } from "@dulce/models/dist/tasks.models";
import { Boolean, flow, FPArray, pipe } from "@dulce/prelude";
import { useEffect, useRef, CSSProperties } from "react";
import { sdk } from "../../../sdk";
import * as styles from "./TimeScrubberReadTrack.css";

type Colors = keyof typeof theme.colors;

export type TimeScrubberReadTrackProps = {
  currentValue: Minutes;
  startTime: Minutes;
  endTime: Minutes;
  scheduledTasks: Array<Task>;
  selectedTask?: Task;
};

export const TimeScrubberReadTrack = (props: TimeScrubberReadTrackProps) => {
  const overflowRef = useRef<HTMLDivElement>(null);
  const timeMarkerContainerRef = useRef<HTMLDivElement>(null);

  const timeMarkers = pipe(
    props.endTime - props.startTime,
    (v) => v + 10,
    (v) => v / 10,
    (v) => new Array(v).fill(""),
    FPArray.mapWithIndex((index) => {
      const time = props.startTime + 10 * index;
      const isTopOfHour = time % 60 === 0;
      return (
        <div
          className={styles.timeMarker({ tall: isTopOfHour })}
          data-time={time}
        >
          {isTopOfHour && (
            <Text className={styles.topOfHour} size={Size.sm}>
              {sdk.utils.time.getFormattedTimeFromMinutes(time)}
            </Text>
          )}
        </div>
      );
    })
  );

  const scheduledTaskMarkers = pipe(
    props.scheduledTasks,
    FPArray.filter(sdk.tasks.helpers.hasStartTime),
    FPArray.map((task) => {
      const { startTime: taskStartTime, duration } = task;
      const gap = 16;
      const delta = (taskStartTime - props.startTime) / 10;
      const isSelectedTask = props?.selectedTask?.id === task.id;
      const pulseColorMap = {
        gray: styles.grayPulse,
        yellow: styles.yellowPulse,
        purple: styles.purplePulse,
        green: styles.greenPulse,
        blue: styles.bluePulse,
        red: styles.redPulse,
      } as any;
      const selectedTaskStyles = pipe(
        isSelectedTask,
        Boolean.match(
          () => ({} as CSSProperties),
          () =>
            ({
              animationName: pulseColorMap[task.color],
              animationDuration: "1000ms",
              animationIterationCount: "infinite",
              animationDirection: "forwards",
            } as CSSProperties)
        )
      );
      return (
        <div
          style={{
            height: 8,
            width: gap * (duration / 10) + 2 * (duration / 10),
            // backgroundColor: theme.colors[task.color][4],
            backgroundColor: theme.colors[task.color as Colors][4],
            position: "absolute",
            left: gap * delta + 2 * delta,
            opacity: 0.7,
            border: `thin ${theme.colors.black} solid`,
            borderBottom: "none",
            borderRight: "none",
            ...selectedTaskStyles,
          }}
        ></div>
      );
    })
  );

  useEffect(() => {
    if (overflowRef.current && timeMarkerContainerRef.current) {
      const index = (props.currentValue - props.startTime) / 10;
      overflowRef.current.scrollTo({
        left: pipe(index, (v) => v * 18),
      });
    }
  }, [props.currentValue, overflowRef.current]);

  return (
    <div style={{ position: "relative", height: "100%" }}>
      <div className={styles.root} ref={overflowRef}>
        <div style={{ minWidth: "50%" }} />
        <div
          className={styles.timeMarkerContainer}
          ref={timeMarkerContainerRef}
        >
          {timeMarkers}
          {scheduledTaskMarkers}
        </div>
        <div style={{ minWidth: "50%" }} />
      </div>
      <div className={styles.currentMarker} />
      <Text size={Size.sm} className={styles.timePreview}>
        {sdk.utils.time.getFormattedTimeFromMinutes(props.currentValue)}
      </Text>
    </div>
  );
};
