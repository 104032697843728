import { authAxios } from "../../utils/authAxios";
import { FindOembedByUrlDto, Metadata } from "@dulce/models/dist/oembed.models";
import { config } from "../../config";

const baseUrl = config.api.url;

export const findByUrl = async (dto: FindOembedByUrlDto) =>
  authAxios
    .request<Metadata>({
      method: "POST",
      url: `${baseUrl}/oembed/find-by-url`,
      data: dto,
    })
    .then((res) => res.data);
