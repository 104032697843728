import { ThirdPartyCalendarEvent } from "@dulce/models/dist/third-party-calendars.models";
import { pipe, O } from "@dulce/prelude";
import { parseISO } from "date-fns/fp";
import { Paragraph } from "../../../../components/EditTaskForm/EditTaskForm";
import { sdk } from "../../../../sdk";

export type GoogleCalendarEvent = gapi.client.calendar.Event;
export type EventWithStartAndEndDateTime = GoogleCalendarEvent & {
  start: GoogleCalendarEvent["start"] & { dateTime: string };
  end: GoogleCalendarEvent["end"] & { dateTime: string };
};

const hasStartAndEndDateTimes = (
  event: GoogleCalendarEvent
): O.Option<EventWithStartAndEndDateTime> => {
  const isValid = !!event?.start?.dateTime && !!event?.end?.dateTime;
  return isValid ? O.some(event as EventWithStartAndEndDateTime) : O.none;
};

export const GoogleCalendarEventUtils = {
  hasStartAndEndDateTimes,
};

const fromGoogleCalendarEvent = (
  event: EventWithStartAndEndDateTime
): ThirdPartyCalendarEvent => {
  const startTime = pipe(
    event.start.dateTime,
    parseISO,
    sdk.utils.time.timeToMinutes
  );
  const duration = pipe(
    event.end.dateTime,
    parseISO,
    sdk.utils.time.timeToMinutes,
    (v) => v - startTime
  );
  const thirdPartyCalendarEvent: ThirdPartyCalendarEvent = {
    raw: event,
    details: Paragraph.fromStringOrCustomElements(event.description), // TODO: create a better string to Details parser
    id: event.id,
    startTime,
    duration,
    title: event.summary,
  };
  return thirdPartyCalendarEvent;
};

export const ThirdPartyCalendarEventUtils = {
  fromGoogleCalendarEvent,
};
